// import './App.css';
import React, { useContext, useEffect, useState } from 'react'
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import { WatchPage } from './Pages/WatchPage';
import { HomePage } from "./Pages/HomePage";
import { AuthContext } from "./Context/AuthProvider";
import { Login } from './Components/Login';
import { PrivateRoutes } from './Subcomponents/PrivateRoutes';
import Cookies from 'js-cookie';
import { PublicRoutes } from './Subcomponents/PublicRoutes';
import _404NotFound from './Components/_404NotFound';
import ReactGA from 'react-ga';
import { RsvpPage } from './Pages/RsvpPage';
import { AdminPage } from './Pages/AdminPage';


function App() {
  const { auth, setAuth } = useContext(AuthContext);
  const [loggedIn, setLoggedIn] = useState(false);
  const UAP = 'UA-199809452-2';
  const [rsvp, setRsvp] = useState({});
  
  useEffect(() => {
    ReactGA.initialize(UAP);

    const authCookies = Cookies.get('NE_AUTH');
    let authCookie = false;
    let levelCookie = 0;
    if(authCookies) {
      authCookie = authCookies.split("+")[1];
      levelCookie = authCookies.split("+")[2];
      setLoggedIn(true)
    } else {
      setLoggedIn(false)
    }
    
    if(authCookie === "true") {
      if(levelCookie === "1") {
        setAuth({authenticated: true, level: 1});
      } else if(levelCookie === "2") {
        setAuth({authenticated: true, level: 2});
      } else if(levelCookie === "3") {
        setAuth({authenticated: true, level: 3});
      }
    };
  }, [])

  // Auth Level
  const renderAuthLevelSwitch = (authlvl) => {
    switch(authlvl) {
      case 1: //Level 1
        return <Route element={<PrivateRoutes auth={auth}/>}>
                <Route element={<Navigate to="/watch" replace />} exact path="/login" />
                <Route element={<WatchPage phTime />} exact path="/" />
                <Route element={<Navigate to="/watch" replace />} exact path="/home" />
                <Route element={<WatchPage phTime />} exact path="/watch" />
                <Route element={<_404NotFound />} path="*" />
              </Route>
      case 2: //Level 2
        return <Route element={<PrivateRoutes auth={auth}/>}>
                <Route element={<Navigate to="/home" replace />} exact path="/login" />
                <Route element={<HomePage />} exact path="/" />
                <Route element={<HomePage />} exact path="/home" />
                <Route element={<WatchPage />} exact path="/watch" />
                <Route element={<RsvpPage rsvp={rsvp} setRsvp={setRsvp} />} exact path="/rsvp" />
                <Route element={<_404NotFound />} path="*" />
              </Route>
      case 3: //Level 3
        return <Route element={<PrivateRoutes auth={auth}/>}>
                <Route element={<Navigate to="/home" replace />} exact path="/login" />
                <Route element={<HomePage />} exact path="/" />
                <Route element={<HomePage />} exact path="/home" />
                <Route element={<WatchPage admin={true} />} exact path="/watch" />
                <Route element={<RsvpPage rsvp={rsvp} setRsvp={setRsvp} />} exact path="/rsvp" />
                <Route element={<AdminPage rsvp={rsvp} setRsvp={setRsvp} />} exact path="/admin" />
                <Route element={<_404NotFound />} path="*" />
              </Route>
      default: //Level 1
        return <Route element={<PrivateRoutes auth={auth}/>}>
                <Route element={<Navigate to="/watch" replace />} exact path="/login" />
                <Route element={<WatchPage />} exact path="/" />
                <Route element={<Navigate to="/watch" replace />} exact path="/home" />
                <Route element={<WatchPage />} exact path="/watch" />
                <Route element={<_404NotFound />} path="*" />
              </Route>
    }
  }

  return (
    <>
      <BrowserRouter>
        <Routes>

          {(auth.authenticated)
            ?
              renderAuthLevelSwitch(auth.level)

            :
              <Route element={<PublicRoutes auth={auth}/>}>
                <Route element={<Login />} exact path="/login" />
                <Route element={<Navigate to="/login" replace />} exact path="/" />
                <Route element={<Navigate to="/login" replace />} exact path="/home" />
                {/* using /watch here breaks /watch route when logged in */}
                {/* { !loggedIn && <Route element={<Navigate to="/login" replace />} path="/watch" /> } */}
                {/* <Route element={<Login />} path="*" /> */}
                <Route element={<_404NotFound />} path="*" />
              </Route>
          }

        </Routes>
      </BrowserRouter>
    </>
  );
}

export default App;
