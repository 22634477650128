import { Input } from '@mui/icons-material';
import axios from 'axios';
const axiosInstance = axios.create({baseURL: process.env.REACT_APP_API_URL});

/******************** GET REQUESTS ********************/
export const getPosts = async () => {
    try {
        let res = {};
        res = await axiosInstance.get('posts');
        return res.data;
    } catch (err) {
        console.log(err);
        return [];
    }
}

/******************** POST REQUESTS ********************/
export const addPost = async (values) => {
    let _id = '';

    try {
        await axiosInstance.post('posts/add', {
            author: values.author,
            content: values.content,
            like: false,
            heart: false,
            happy: false,
        }).then(res => _id = JSON.stringify(res.data));
    } catch (err) {
        console.log(err);
    }
} 

/******************** PUT REQUESTS ********************/
export const updatePostLike = async (id, booleanInput) => {
    try {
        await axiosInstance.put(`/posts/update/like/${id}`, {
            like: booleanInput,
        });
    } catch (err) {
        console.log(err);
        return [];
    }
};

export const updatePostHeart = async (id, booleanInput) => {
    try {
        await axiosInstance.put(`/posts/update/heart/${id}`, {
            heart: booleanInput,
        });
    } catch (err) {
        console.log(err);
        return [];
    }
};

export const updatePostHappy = async (id, booleanInput) => {
    try {
        await axiosInstance.put(`/posts/update/happy/${id}`, {
            happy: booleanInput,
        });
    } catch (err) {
        console.log(err);
        return [];
    }
};
