import axios from 'axios';
const axiosInstance = axios.create({baseURL: process.env.REACT_APP_API_URL});

/******************** GET REQUESTS ********************/
export const getEntreeCount = async (entree) => {
    try {
        let res = {};
        res = await axiosInstance.get(`rsvps/entree/${entree}`);
        return res.data;
    } catch (err) {
        console.log(err);
        return [];
    }
}

export const getRsvpsAnswerCount = async (answer) => {
    try {
        let res = {};
        res = await axiosInstance.get(`rsvps/count/answer/${answer}`);
        return res.data;
    } catch (err) {
        console.log(err);
        return [];
    }
}

export const getRsvpsNoAnswerCount = async () => {
    try {
        let res = {};
        res = await axiosInstance.get(`rsvps/count/noanswer`);
        return res.data;
    } catch (err) {
        console.log(err);
        return [];
    }
}

/******************** POST REQUESTS ********************/


/******************** PUT REQUESTS ********************/
// Send Email Invitation
// export const sendEmailInvitation = async (rsvp) => {
//     try {
//         // console.log("axiosvalues: "+JSON.stringify(values));
//         // console.log("axiosrsvp: "+JSON.stringify(rsvp));
//         // console.log("axioscurrentGuest: "+JSON.stringify(currentGuest));
//         // console.log("axioscurrentGuestID: "+JSON.stringify(currentGuest._id));

//         await axiosInstance.put(`/rsvps/sendemail/guestid/${rsvp._id}`, {
//             firstname: rsvp.firstname,
//             lastname: rsvp.lastname,
//             email: rsvp.email // to send email to
//         });
//     } catch (err) {
//         console.log(err);
//         return [];
//     }
// };