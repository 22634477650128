import React, { useState } from 'react'
import MainSection from '../Subcomponents/MainSection'
import GalleryImage from '../Subcomponents/GalleryImage'
import { galleryImages } from '../galleryImages'
import { Button, IconButton } from '@mui/material';
import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material';
import { ChevronLeft, ChevronRight, KeyboardArrowLeft, KeyboardArrowRight } from '@mui/icons-material';
import { TransitionGroup, CSSTransition } from "react-transition-group";

export const Gallery = () => {
  const [open, setOpen] = useState(false);
  const [currINDEX, setCurrINDEX] = useState(0);
  const [imagesLoaded, setImagesLoaded] = useState(false);

  const handleClickOpen = (index, src, alt) => {
    setCurrINDEX(index)
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const prevSlide = () => {
    if(currINDEX !== 0) {
      setCurrINDEX(currINDEX - 1);
    } else if(currINDEX === 0) {
      setCurrINDEX(15)
    }
  }

  const nextSlide = () => {
    if(currINDEX !== 15) {
      setCurrINDEX(currINDEX + 1);
    } else if(currINDEX === 15) {
      setCurrINDEX(0)
    }
  }

  const handleArrowKeys = (e) => {
    if (e.key === "ArrowRight") {
      nextSlide();
    }
    if (e.key === "ArrowLeft") {
      prevSlide();
    }
	}

  return (
    <div id="Gallery">
      <MainSection name="Gallery" title="GALLERY" titleHeader lineAbove>

        {/* Gallery Images Popup */}
        <Dialog
          open={open}
          onClose={handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          onKeyDown={handleArrowKeys}
        >

          <div class="overflow-hidden 2xs:pb-4 2xs:pt-2 2xs:px-2 md:pt-6 md:px-6 content-center justify-center sm:w-[20em] md:w-[20em] lg:w-[24em] xl:w-[27em] 2xl:w-[30em]">
            <img
              class="block object-cover object-center rounded-sm shadow-sm"
              alt={`${process.env.PUBLIC_URL}/assets/images/${galleryImages[currINDEX].alt}`} 
              src={`${process.env.PUBLIC_URL}/assets/images/${galleryImages[currINDEX].src}`} 
              referrerPolicy="no-referrer"
              disableRipple
              // onClick={nextSlide}
            />
          </div>

          <DialogActions class="flex items-center justify-center pb-4">
            <IconButton onClick={prevSlide} class="ml-1 mr-2.5 hover:bg-gray-100 duration-300 rounded-sm" disableRipple><ChevronLeft style={{fontSize: '22px', color: "grey", borderRadius: '8px'}}/></IconButton>
            <p class="text-center text-xs text-gray-700 inline w-16"> {currINDEX+1} / 16 </p>
            <IconButton onClick={nextSlide} class="mx-1 hover:bg-gray-100 hover:after:bg-red-300 duration-300 rounded-sm" disableRipple><ChevronRight style={{fontSize: '22px', color: "grey", borderRadius: '8px'}}/></IconButton>
          </DialogActions>
        </Dialog>

        {/* Gallery */}
        <section class="overflow-hidden text-gray-700 animate-fadeInSlow">
          <div class="mx-auto 2xs:px-2 xs:px-4 md:px-5 lg:px-2 p-2">
            <div class="flex flex-wrap -m-1 md:-m-2">

              { galleryImages.map((img, index) => (<GalleryImage key={index} alt={img.alt} src={img.src} onClick={() => handleClickOpen(index, img.src, img.alt)}/>)) }

            </div>
          </div>
        </section>
      </MainSection>
    </div>
  )
}
