import React, { useState, useContext } from 'react'
import { Field, Form, Formik } from 'formik';
import * as yup from 'yup';
import { Paper, Typography, TextField, Button } from '@mui/material';
// import { Guestbook_AddButton } from '../Subcomponents/Guestbook_AddButton';
import { guestPasswordsL1, guestPasswordsL2, guestPasswordsL3 } from '../guestPasswords';
import Cookies from 'js-cookie';
import { AuthContext } from "../Context/AuthProvider";
import { LOGIN_BTN_TEXTCOLOR, LOGIN_BTN_BGCOLOR, LOGIN_BTN_BGCOLOR_HOVER, LOGIN_PWLABEL_TEXTCOLOR, LOGIN_PWBOX_BORDERCOLOR, LOGIN_PWBOX_BORDERCOLOR_HOVER, LOGIN_PWBOX_BORDERCOLOR_FOCUSED, LOGIN_PWLABEL_FONTFAMILY, LOGIN_BTN_FONTFAMILY, LOGIN_BTN_TEXTCOLOR_HOVER, LOGIN_BGCOLOR } from '../constants';
import useSound from 'use-sound';
import upBeat from '../Sounds/upBeat.mp3'

export const Login = ({ setPosts, setRequestsLoaded }) => {
  const { auth, setAuth } = useContext(AuthContext);
  const [errorMsg, setErrorMsg] = useState(false);
  const [playUpBeat] = useSound(upBeat, {volume: 0.1})

  const _initialValues = {
    password: '',
  };

  const _validationSchema = yup.object({
    password: yup
        .string('Enter your password')
        .required(''),
  });

  const trimSpaces = (e) => {
    let str = e.currentTarget.value.trim();
    return str;
  }

  const validatePassword = (pass) => {
    if( guestPasswordsL1.includes(pass) ) {
      setAuth({authenticated: true, level: 1});
      Cookies.set('NE_AUTH', "NARUTO+true+1+10302022+CRANBERRY", { expires: 30 });
      setErrorMsg(false);
      playUpBeat();
    } else if( guestPasswordsL2.includes(pass) ) {
      setAuth({authenticated: true, level: 2});
      Cookies.set('NE_AUTH', "NARUTO+true+2+10302022+CRANBERRY", { expires: 120 });
      setErrorMsg(false);
      playUpBeat();
    } else if( guestPasswordsL3.includes(pass) ) {
      setAuth({authenticated: true, level: 3});
      Cookies.set('NE_AUTH', "NARUTO+true+3+10302022+CRANBERRY", { expires: 120 });
      setErrorMsg(false);
      playUpBeat();
    } else {
      setErrorMsg(true);
    }
  }

  return (
    <div class="h-screen animate-fadeInSemiSlow">
      <div class="flex justify-center items-center h-full">
        <Formik
          initialValues = {_initialValues}
          validationSchema = {_validationSchema}
          onSubmit = {(values, onSubmit) => {
            validatePassword(values.password);
            onSubmit.resetForm();
            setTimeout(function() { setErrorMsg(false); }, 4000);
            // window.location.reload();
          }}
        >
          {formik => (
            <Form>
                {/********************* LOGIN *********************/}
                {/* Title */}
                <p class="2xs:text-[4.4rem] 2xs-360:text-[4.9rem] 2xs-375:text-[5.3rem] 2xs-390:text-[5.5rem] sm:text-[6.5rem] 2xl:text-[7rem] 2xs:mb-1 sm:-mb-2 2xl:ml-2 text-center font-rainbowBeauty select-none">
                  Erin + Norris
                </p>

                <div class="flex">

                  <div class="flex-1 min-w-[25px]"></div>

                  {/* Login Form */}
                  <div class="flex-auto">
                    <Paper elevation={8} 
                      sx={{ 
                        padding: 2, 
                        marginLeft: 0,
                        bgcolor: LOGIN_BGCOLOR
                        // ['@media (max-width:575px)']: { // eslint-disable-line no-useless-computed-key
                        //   bgcolor: 'red'
                        // }
                      }}>

                      <div class="pt-5 pb-1 px-5">
                        <Field
                          autoFocus
                          fullWidth
                          label="Password"
                          // placeholder="password"
                          name="password"
                          type="password"
                          onBlur={e => {
                            formik.handleBlur(e)
                            formik.values.password = trimSpaces(e)
                          }}
                          as={TextField}
                          error={formik.touched.password && Boolean(formik.errors.password)}
                          helperText={formik.touched.password && formik.errors.password}
                          // inputProps={{ className: classes.form_input }}
                          sx={{
                            '& .MuiFormLabel-root': {fontFamily: LOGIN_PWLABEL_FONTFAMILY},
                            '& label.Mui-focused': {color: LOGIN_PWLABEL_TEXTCOLOR},
                            '& .MuiInput-underline:after': {borderBottomColor: LOGIN_PWLABEL_TEXTCOLOR},
                            '& .MuiOutlinedInput-root': {
                              '& fieldset': {
                                borderColor: LOGIN_PWBOX_BORDERCOLOR,
                              },
                              '&:hover fieldset': {
                                borderColor: LOGIN_PWBOX_BORDERCOLOR_HOVER,
                              },
                              '&.Mui-focused fieldset': {
                                borderColor: LOGIN_PWBOX_BORDERCOLOR_FOCUSED,
                              }
                            },
                            '& .MuiInputBase-input': {
                              ['@media (max-width:575px)']: { // eslint-disable-line no-useless-computed-key
                                padding: '15.5px 13px'
                              }
                            },
                            marginBottom: 0,
                          }}
                        />
                      </div>

                      {/* Error Message */}
                      {(errorMsg && !formik.dirty)
                        ?
                          <div class=" text-red-400 mx-5">
                            <li class="text-left text-xs">Incorrect password.</li>
                          </div>
                        :
                          <div class="2xs:p-2 2xs:mx-5"></div>
                      }
                      
                      {/* Login Button */}
                      <Button sx={{ fontSize: '14px',color: LOGIN_BTN_TEXTCOLOR, bgcolor: LOGIN_BTN_BGCOLOR, '&:hover': {bgcolor: LOGIN_BTN_BGCOLOR_HOVER, color: LOGIN_BTN_TEXTCOLOR_HOVER}, marginTop: 2, padding: 0.7, fontFamily: LOGIN_BTN_FONTFAMILY, marginTop: '12px', ['@media (max-width:575px)']: { margin: '10px 0 0 0'} }} disabled={!formik.isValid || !formik.dirty} color="primary" variant="contained" fullWidth type="submit" >
                          Login
                      </Button>

                    </Paper>
                  </div>

                  <div class="flex-1 min-w-[25px]"></div>

                </div>

                {/* {(!formik.isValid) && <Typography className={classes.submitButtonDescriptionBelow}>You must complete the entire form to post a message</Typography>} */}
            </Form>
          )}
        </Formik>
      </div>
    </div>
  )
}
