import React, { useContext, useEffect, useState } from 'react'
import MainSection from '../Subcomponents/MainSection'
import Countdown from 'react-countdown';
import { CountdownTimer } from '../Subcomponents/CountdownTimer';
import Cookies from 'js-cookie';
import { AuthContext } from "../Context/AuthProvider";
import { animateScroll as scroll } from "react-scroll";
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { IconButton, Tooltip } from '@mui/material';
import { Mail, MailTwoTone } from '@mui/icons-material';


export const Footer = () => {
  const { auth, setAuth } = useContext(AuthContext);
  const [authCookies, setAuthCookies] = useState('');
  let d = new Date();

  useEffect(() => {
    setAuthCookies(Cookies.get('NE_AUTH'));
  }, [])

  const logout = () => {
    if(authCookies.includes("true")) {
      Cookies.remove('NE_AUTH')
      setAuth({authenticated: false, level: 0});
    }
  }

  const scrollToTheTop = () => {
    scroll.scrollToTop({
      delay: 100,
      duration: 600
    })
  }

  return (
    <MainSection name="Footer" lineAbove={true} >
        <div class='flex flex-col animate-fadeInSlow'>
            {/* Row 1 */}
            <div class='flex justify-center gap-x-5 -mt-8 text-xs text-center text-gray-500 font-[300]'>
              {/* Logout button */}
              {/* <button onClick={logout}>logout</button> */}

              {/* Arrow go to top icon button */}
              <Tooltip title="Back to top" placement="top" arrow>
                <IconButton onClick={scrollToTheTop} sx={{ padding: '2px' }}>
                    <KeyboardArrowUpIcon />
                </IconButton>
              </Tooltip>

              {/* Go to top button */}
              {/* <button onClick={scrollToTheTop}>go to top</button> */}
            </div>



            {/* Row 2 */}
            <p class='flex justify-center gap-x-2 mt-4 text-xs text-center text-gray-500 font-[300]'>
              {/* Footer */}
              &copy; 2023 - Erin Oh &amp; Norris Co
            </p>

            {/* Row 3 */}
            <div class='flex justify-center gap-x-5 mt-1 text-xs text-center text-gray-500 font-[300]'>
              {/* Countdown */}
              {/* <div class='flex justify-center gap-x-2 mt-4 text-xs text-center text-gray-500'>
                <CountdownTimer fromWatchPage premiereStarted={premiereStarted} setPremiereStarted={setPremiereStarted}/>
              </div> */}
              
              {/* Mail button */}
              {/* <Tooltip title="Send us an email" placement="bottom" arrow>
                <IconButton sx={{ padding: '6px' }} href={`mailto:erinandnorris@gmail.com`} onClick={() => {navigator.clipboard.writeText('erinandnorris@gmail.com')}}>
                    <MailTwoTone sx={{ fontSize: 14}}/>
                </IconButton>
              </Tooltip> */}

              {/* Email address */}
              <a class="text-[0.5rem] hover:text-gray-300 duration-300" href={`mailto:erinandnorris@gmail.com`}>erinandnorris@gmail.com</a>
              {/* <a class="text-[0.5rem] hover:text-gray-400" href={`mailto:erinandnorris@gmail.com`} onClick={() => {navigator.clipboard.writeText('erinandnorris@gmail.com')}}>erinandnorris@gmail.com</a> */}
            </div>
            

        </div>
    </MainSection>
  )
}
