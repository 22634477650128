import React, { useState, useEffect } from 'react'
import { Paper, Container, Table, TableBody, TableHead, TableRow, IconButton, Typography, TextField, Hidden, InputLabel, Select, MenuItem, InputAdornment, TableCell, Button, ToggleButton, Tooltip } from '@mui/material';
import { Close, Delete, InsertInvitation, Search, Info, Sort, PersonAddDisabled, PersonOff } from '@mui/icons-material';
import { SIGNGUESTBOOK_BTN_BGCOLOR, SIGNGUESTBOOK_BTN_BGCOLOR_HOVER, SIGNGUESTBOOK_BTN_TEXTCOLOR } from '../constants';
import { Field, Form, Formik } from 'formik';
// import { sendEmailInvitation } from '../Services/AdminService'

const GuestList = ({ allRsvps, setAllRsvps, notRsvpd }) => {
    const [searchedRsvpsArray, setSearchedRsvpsArray] = useState([]);
    const [extraDetails, setExtraDetails] = useState(false)

    // const handleSendInvite = (rsvp) => {
    //     sendEmailInvitation(rsvp).then(() => {
    //         console.log('email sent')
    //     })
    // }

    // const handleSortArray = (arr) => {
    //     arr.sort(function(a,b){
    //         // Turn your strings into dates, and then subtract them
    //         // to get a value that is either negative, positive, or zero.
    //         return new Date(b.date) - new Date(a.date);
    //     });
    // }

    const handleToggleExtraDetails = () => {
        setExtraDetails(!extraDetails)
    }

    // New Way
    const searchAllRsvpsHandler = (searchTerm) => {
        let newRsvpsArr = [];
        
        allRsvps.forEach(rsvp => {
            // if(rsvp.email.toLowerCase().includes(searchTerm.toLowerCase())){
            //     newRsvpsArr.push(rsvp)
            //     setSearchedRsvpsArray(newRsvpsArr);
            // }

            rsvp.guestList.forEach(guest => {
                if(
                    guest.firstname.toLowerCase().includes(searchTerm.toLowerCase())
                    || guest.lastname.toLowerCase().includes(searchTerm.toLowerCase())
                ) {
                    newRsvpsArr.push(rsvp)
                    setSearchedRsvpsArray(newRsvpsArr);
                    // console.log(searchTerm)
                    // console.log(newRsvpsArr)
                }
            })
        })
    }

    // const onSearchBarEnterKey = (event) => {
    //     if(event.key === 'Enter'){
    //         event.target.blur();
    //     }
    // }

    const rendercomponent_SEARCH = (
        <>
        <Container maxWidth="md">
            {/* <InputLabel>Search</InputLabel> */}
            <Formik 
                initialValues = {{search: ""}}
                onSubmit = {(values, onSubmit) => {
                    console.log('submitted')
                    searchAllRsvpsHandler(values.search)
                }}
            >
            {formik => (
                <Form>
                    <div class="flex justify-center items-center space-x-1 my-8">
                        <Field
                            label="Search by first or last name"
                            name="search"
                            type="input"
                            variant="outlined"
                            // value={searchTerm ? searchTerm : ''}
                            // onChange={e => setSearchTerm(e.target.value)}
                            as={TextField}
                            // className={classes.search}
                            sx={{ width: '70%' }}
                            // inputProps={{style: {height: '0.1vh'}}}
                            // InputProps={{
                            //     endAdornment: (
                            //         (searchTerm.length >= 1) &&
                            //         <InputAdornment position='end'>
                            //             <IconButton onClick={e => setSearchTerm('')}>
                            //                 <Close />
                            //             </IconButton>
                            //         </InputAdornment>
                            // )}}
                            // onKeyPress={() => searchAllRsvpsHandler(searchTerm)}
                            // onSubmit={() => searchAllRsvpsHandler(searchTerm)}
                        />

                        <IconButton sx={{ fontSize: 13, fontFamily:'Inter', color: SIGNGUESTBOOK_BTN_TEXTCOLOR, bgcolor: SIGNGUESTBOOK_BTN_BGCOLOR, '&:hover': {bgcolor: SIGNGUESTBOOK_BTN_BGCOLOR_HOVER, scale: '1.01'}, transition: '400ms', paddingX: 2, paddingY: 1.8, width: '3.2rem' }} color="primary" variant="contained" type="submit">
                            <Search />
                        </IconButton>
                        <Tooltip title="More/Less Details" placement="top-end" arrow>
                            <IconButton sx={{ fontSize: 13, fontFamily:'Inter', color: SIGNGUESTBOOK_BTN_TEXTCOLOR, bgcolor: SIGNGUESTBOOK_BTN_BGCOLOR, '&:hover': {bgcolor: SIGNGUESTBOOK_BTN_BGCOLOR_HOVER, scale: '1.01'}, transition: '400ms', paddingX: 2, paddingY: 1.8, width: '3.2rem' }} color="primary" variant="contained" onClick={handleToggleExtraDetails}>
                                <Info />
                            </IconButton>
                        </Tooltip>
                        {/* <IconButton sx={{ fontSize: 13, fontFamily:'Inter', color: SIGNGUESTBOOK_BTN_TEXTCOLOR, bgcolor: SIGNGUESTBOOK_BTN_BGCOLOR, '&:hover': {bgcolor: SIGNGUESTBOOK_BTN_BGCOLOR_HOVER, scale: '1.01'}, transition: '400ms', paddingX: 2, paddingY: 1.8, width: '3.2rem' }} color="primary" variant="contained" onClick={() => handleSortArray(searchedRsvpsArray)}>
                            <Sort />
                        </IconButton> */}
                        <Tooltip title="Guests who have not RSVP'd" placement="top-end" arrow>
                            <IconButton sx={{ fontSize: 13, fontFamily:'Inter', color: SIGNGUESTBOOK_BTN_TEXTCOLOR, bgcolor: SIGNGUESTBOOK_BTN_BGCOLOR, '&:hover': {bgcolor: SIGNGUESTBOOK_BTN_BGCOLOR_HOVER, scale: '1.01'}, transition: '400ms', paddingX: 2, paddingY: 1.8, width: '3.2rem' }} color="primary" variant="contained" onClick={() => setAllRsvps(notRsvpd)}>
                                <PersonOff />
                            </IconButton>
                        </Tooltip>
                    </div>
                    <div class="text-center text-xs text-gray-500">{(searchedRsvpsArray.length===0 ? allRsvps : searchedRsvpsArray).length} search result{(searchedRsvpsArray.length===0 ? allRsvps : searchedRsvpsArray).length===1 ? "" : "s"} found.</div>
                </Form>
            )}
            </Formik>
        </Container>
        
        </>
    )

    // const rendercomponent_FILTER = (
    //     <Select
    //         // fullWidth
    //         // value={selectedFilter}
    //         // onChange={onFilter}
    //         // defaultValue={bookingWeek}
    //         displayEmpty
    //         // variant='outlined'
    //         // inputProps={{ 'aria-label': 'Without label' }}
    //         >                       
    //         <MenuItem value='All'>All Dates</MenuItem>
    //         <MenuItem value={bookingWeek-0} className={classes.currentWeekMenuItem}><Typography className={classes.currentWeekMenuItem}>{churchWeek[bookingWeek]?.date} - {service==="chinese" ? churchWeek[bookingWeek-0]?.chinese_service : churchWeek[bookingWeek-0]?.service} ({seatsForWeek_n0})</Typography></MenuItem>
    //         <MenuItem value={bookingWeek-1} className={classes.pastWeeksMenuItem}><Typography className={classes.pastWeeksMenuItem}>{churchWeek[bookingWeek-1]?.date} - {service==="chinese" ? churchWeek[bookingWeek-1]?.chinese_service : churchWeek[bookingWeek-1]?.service} ({seatsForWeek_n1})</Typography></MenuItem>
    //         <MenuItem value={bookingWeek-2} className={classes.pastWeeksMenuItem}><Typography className={classes.pastWeeksMenuItem}>{churchWeek[bookingWeek-2]?.date} - {service==="chinese" ? churchWeek[bookingWeek-2]?.chinese_service : churchWeek[bookingWeek-2]?.service} ({seatsForWeek_n2})</Typography></MenuItem>
    //     </Select>
    // )

    let rendertable_MOBILE = (
        (searchedRsvpsArray.length===0 ? allRsvps : searchedRsvpsArray).map((rsvp, index) => (
            <Paper elevation={8} sx={{marginX: 1, marginY: 1.5}}>
                <Table>
                    <colgroup>
                        <col style={{width:'5%'}}/>
                        <col style={{width:'95%'}}/>
                        {/* <col style={{width:'40%'}}/> */}
                        {/* <col style={{width:'22.5%'}}/> */}
                    </colgroup>
                    <TableHead>
                        <TableRow>
                            <TableCell align="center">{rsvp.email}</TableCell>
                            <TableCell align="center"></TableCell>
                            {/* <TableCell align="center">Extra Details */}
                                {/* {info
                                ? <IconButton onClick={toggleInfo}><Info style={{fontSize: '28px', color: THEME_BLACK, backgroundColor: 'white', borderRadius: '16px'}}/></IconButton>
                                : <IconButton onClick={toggleInfo}><InfoTwoTone style={{fontSize: '28px', color: THEME_BLACK, backgroundColor: 'white', borderRadius: '16px'}}/></IconButton>
                                } */}
                            {/* </TableCell> */}
                            {/* <StyledTableCell align="center" className={classes.tableHeader}>Other Attendees</StyledTableCell> */}
                        </TableRow>
                    </TableHead>

                    
                    {/* <div class="text-center w-full">{rsvp.email}</div> */}

                    {rsvp.guestList.map((guest) => (
                        <TableBody>
                            <TableRow key={index}>
                                {/* <TableCell align="center">
                                    <IconButton onClick={() => { (window.confirm('Are you sure you want to delete this booking?')) && handleDeleteUser(user) }}>
                                        <Delete />
                                    </IconButton>
                                </TableCell> */}

                                {/* 1 */}
                                <TableCell align="center">
                                    <Typography>{guest.firstname} {guest.lastname}</Typography>
                                </TableCell>

                                {/* 2 */}
                                <TableCell align="center">
                                    <Typography sx={{ marginBottom:2 }}>Attending: <span class="font-bold">{guest?.attending==="" ? "( no answer )" : guest.attending}</span></Typography>
                                    <Typography sx={{ marginBottom:2 }}>Entree: <span class="font-bold">{(!guest?.entree||guest?.entree==="") ? "( no answer )" : guest.entree}</span></Typography>
                                    <div hidden={extraDetails}>
                                        <Typography sx={{ marginBottom:2 }}>Allergy/Restrictions: <span class="font-bold">{(!guest?.allergyandrestriction||guest?.allergyandrestriction==="") ? "n/a" : guest.allergyandrestriction}</span></Typography>
                                        <Typography sx={{ marginBottom:2 }}>Q/C: <span class="font-bold">{(!guest?.questionorcomment||guest?.allergyandrestriction==="") ? "n/a" : guest.questionorcomment}</span></Typography>
                                        <Typography sx={{ marginBottom:2 }}>Song Request: <span class="font-bold">{(!guest?.songrequest||guest?.songrequest==="") ? "n/a" : guest.songrequest}</span></Typography>
                                        <Typography>Email Sent: <span class="font-bold">{guest?.emailsent==="No" ? "✖": "✔"}</span></Typography>
                                    </div>
                                    <p>{guest.lastUpdated 
                                            ? "as of " + new Date(guest.lastUpdated).toLocaleString([], {timeZone:'UTC', month:'2-digit', day:'2-digit', year:'2-digit', hour: '2-digit', minute:'2-digit'})
                                            : "-"
                                        }
                                    </p>
                                </TableCell>

                                {/* <StyledTableCell align="center" className={classes.tableText_mobile}>{user.otherNames}</StyledTableCell> */}
                            </TableRow>
                        </TableBody>
                        ))}
                </Table>
            </Paper>
        ))
    )
    const rendertable_BROWSER = (
        (searchedRsvpsArray.length===0 ? allRsvps : searchedRsvpsArray).map((rsvp, index) => (
        <Paper elevation={8} sx={{ marginX: 8, marginY: 1.5 }}>
            <Table>
            <colgroup>
                <col style={{width:'8%'}}/>
                <col style={{width:'6%'}}/>
                <col style={{width:'86%'}}/>
            </colgroup>
                <TableHead>
                    <TableRow>
                        {/* <TableCell align="center" ></TableCell> */}
                        <TableCell align="center"><div class="text-center">Email</div></TableCell>
                        <TableCell align="center"><div class="text-center"># of Guests</div></TableCell>
                        <TableCell align="center"><div class="text-center">Guests</div></TableCell>
                    </TableRow>
                </TableHead>
    
                <TableBody>
                    
                    <TableRow key={index}>
                        <TableCell align="center" >
                            <div class="text-center">
                                {rsvp.email}
                                {/* <Button onClick={() => handleSendInvite(rsvp)} startIcon={<InsertInvitation />} sx={{ fontSize: 12, fontFamily:'Inter', color: SIGNGUESTBOOK_BTN_TEXTCOLOR, bgcolor: SIGNGUESTBOOK_BTN_BGCOLOR, '&:hover': {bgcolor: SIGNGUESTBOOK_BTN_BGCOLOR_HOVER, scale: '1.01'}, transition: '400ms', padding: 1, marginTop: 0.75, width: '100%' }} color="primary" variant="contained" fullWidth type="submit">
                                    Send Invite
                                </Button> */}
                            </div>
                        </TableCell>
                        <TableCell align="center" >{rsvp.numberOfGuests}</TableCell>
                            <Table>
                                <TableHead>
                                    <TableRow>
                                        <TableCell align="center"><div class="text-center">Name</div></TableCell>
                                        <TableCell align="center"><div class="text-center">Attending</div></TableCell>
                                        <TableCell align="center"><div class="text-center">Entree</div></TableCell>
                                        <TableCell align="center"><div class="text-center">Allergies and Restrictions</div></TableCell>
                                        <TableCell align="center"><div class="text-center"><div hidden={extraDetails}>Questions or Comments</div></div></TableCell>
                                        <TableCell align="center"><div class="text-center"><div hidden={extraDetails}>Song Requests</div></div></TableCell>
                                        <TableCell align="center"><div class="text-center"><div hidden={extraDetails}>Email Sent</div></div></TableCell>
                                        <TableCell align="center"><div class="text-center">Last Updated</div></TableCell>
                                    </TableRow>
                                </TableHead>

                                {rsvp.guestList.map((guest) => (
                                    <TableBody>
                                        <TableRow>
                                            <TableCell>
                                                <div class="text-center"><b>{guest.firstname} {guest.lastname}</b></div>
                                            </TableCell>
                                            <TableCell>
                                                <div class="text-center">{guest?.attending==="" ? "( no answer )" : guest.attending}</div>
                                            </TableCell>
                                            <TableCell>
                                                <div class="text-center">{(!guest?.entree||guest?.entree==="") ? "( no answer )" : guest.entree}</div>
                                            </TableCell>
                                            <TableCell>
                                                <div class="text-center">{(!guest?.allergyandrestriction||guest?.allergyandrestriction==="") ? "n/a" : guest.allergyandrestriction}</div>
                                            </TableCell>
                                            <TableCell>
                                                <div class="text-center"><div hidden={extraDetails}>{(!guest?.questionorcomment||guest?.allergyandrestriction==="") ? "n/a" : guest.questionorcomment}</div></div>
                                            </TableCell>
                                            <TableCell>
                                                <div class="text-center"><div hidden={extraDetails}>{(!guest?.songrequest||guest?.songrequest==="") ? "n/a" : guest.songrequest}</div></div>
                                            </TableCell>
                                            <TableCell>
                                                <div class="text-center"><div hidden={extraDetails}>{guest?.emailsent==="No" ? "✖": "✔"}</div></div>
                                            </TableCell>
                                            
                                            <TableCell>
                                                <div class="text-center">
                                                    {guest.lastUpdated 
                                                        ? "as of " + new Date(guest.lastUpdated).toLocaleString([], {timeZone:'UTC', month:'2-digit', day:'2-digit', year:'2-digit', hour: '2-digit', minute:'2-digit'})
                                                        : "-"
                                                    }
                                                </div>
                                            </TableCell>
                                        </TableRow>
                                    </TableBody>
                                ))}
                            </Table>
                            
                        {/* <TableCell align="center" >{user.seats}</TableCell> */}
                        {/* <TableCell align="center" >{user.firstName} {user.lastName}<p >{"as of " + new Date(user.createdOn).toLocaleString([], {timeZone:'UTC', month:'2-digit', day:'2-digit', year:'2-digit', hour: '2-digit', minute:'2-digit'})}</p></TableCell> */}
                        {/* <TableCell align="center" >{user.bookedDate}</TableCell> */}
                        {/* <TableCell align="center" >{user.phoneNumber}</TableCell> */}
                        {/* <TableCell align="center" >{user.otherNames}</TableCell> */}
                    </TableRow>
                </TableBody>
            </Table>
        </Paper>
        ))
    )

  return (
    <>
        {rendercomponent_SEARCH}
        
        {/* Browser Table */}
        <div class="2xs:hidden sm:inline-block">
            {rendertable_BROWSER}
        </div>

        {/* Mobile Table */}
        <div class="sm:hidden">
            {rendertable_MOBILE}
        </div>

        
    </>
  )
}

export default GuestList