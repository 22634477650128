import React from 'react'
import { Paper } from '@mui/material';
import { RSVP_BGCOLOR, SIGNGUESTBOOK_BGCOLOR } from '../constants';

export const RsvpPaperContainer = ({ children, mainForm }) => {
  return (
    mainForm
      ? <Paper elevation={16} sx={{ paddingY: 1, paddingX: 2, bgcolor: SIGNGUESTBOOK_BGCOLOR }}>
          <div class="2xs:mx-[0rem] xs:mx-8 sm:mx-10 md:mx-[0.5rem] lg:mx-[1rem] xl:mx-[2rem] 2xl:mx-[3rem] 3xl:mx-[8rem]
                      2xs:mt-8 sm:mt-8 md:mt-6 xl:mt-7 2xl:mt-8 3xl:mt-9
                      2xs:mb-1 sm:mb-1 md:mb-0 xl:mb-4 2xl:mb-5 3xl:mb-6
                      ">
            { children }
          </div>
        </Paper>
      : <Paper elevation={16} sx={{ paddingY: 1, paddingX: 1, bgcolor: RSVP_BGCOLOR }}>
          <div class="2xs:mx-[0rem] xs:mx-[2rem] sm:mx-[2.5rem] md:mx-[3.5rem] lg:mx-[5rem] xl:mx-[9rem] 2xl:mx-[10rem] 3xl:mx-[14rem]
                      2xs:mt-8 sm:mt-8 md:mt-6 xl:mt-7 2xl:mt-8 3xl:mt-9
                      2xs:mb-3 sm:mb-3 md:mb-3 xl:mb-4 2xl:mb-5 3xl:mb-6
                      ">
            { children }
          </div>
        </Paper>
    
  )
}