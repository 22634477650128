import React from 'react'
import { TransitionGroup, CSSTransition } from "react-transition-group";
import { Card, CardHeader, CardContent, CardActions, Avatar, Button, Typography, IconButton } from '@mui/material';
import '../index.css';
import { Create } from '@mui/icons-material';
import { SIGNGUESTBOOK_BTN_TEXTCOLOR, SIGNGUESTBOOK_BTN_BGCOLOR, SIGNGUESTBOOK_BTN_BGCOLOR_HOVER } from '../constants';


const Button_SignGuestbook = ({ handleOpen }) => {

  return (
    // <TransitionGroup class="flex flex-wrap w-full transition duration-200 ease-in-out pb-4">
    //     <CSSTransition in={true} unmountOnExit timeout={500} classNames="menu-primary">
    //         <div class="w-full 2xs:p-1 md:p-2 2xs:-mb-1 lg:-mb-0 flex flex-row justify-center">

    //           <Button onClick={handleOpen} startIcon={<Create />} class="flex flex-row justify-center items-center px-2 py-4 border-1 border-gray-200 bg-[#b2b1b0] text-[#ECEDEF] font-[300] object-cover object-center 2xs:w-5/6 lg:w-4/6 2xl:w-3/6 2xs:h-[85%] lg:h-full rounded-sm shadow-lg opacity-100 hover:opacity-80 hover:scale-[1.02] transition duration-200 ease-in-out">
    //             <b>SIGN GUESTBOOK</b>
    //           </Button>

    //         </div>
    //   </CSSTransition>
    // </TransitionGroup>



    <TransitionGroup class="flex flex-wrap w-full transition duration-200 ease-in-out pb-4">
        <CSSTransition in={true} unmountOnExit timeout={500} classNames="menu-primary">
        <div class="w-full 2xs:p-1 md:p-2 2xs:mb-1 lg:mb-2 flex flex-row justify-center">
            <button type="button" onClick={handleOpen} class="flex flex-row justify-center items-center px-6 2xs:pt-2.5 sm:pt-3 pb-2 border-1 border-gray-200 bg-button_bg text-[#ECEDEF] font-[400] 2xs:text-sm lg:text-md leading-normal uppercase rounded-sm shadow-lg 2xs:w-5/6 lg:w-4/6 2xl:w-3/6 2xs:h-[110%] lg:h-[120%] opacity-100 hover:opacity-80 hover:scale-[1.01] hover:bg-[#B0B0AF] hover:shadow-lg focus:bg-[#808080] focus:shadow-lg focus:outline-none focus:ring-0 transition duration-400 ease-in-out">
              <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="pen"
                class="2xs:w-3 md:w-4 mr-4" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                <path fill="currentColor"
                  d="M290.74 93.24l128.02 128.02-277.99 277.99-114.14 12.6C11.35 513.54-1.56 500.62.14 485.34l12.7-114.22 277.9-277.88zm207.2-19.06l-60.11-60.11c-18.75-18.75-49.16-18.75-67.91 0l-56.55 56.55 128.02 128.02 56.55-56.55c18.75-18.76 18.75-49.16 0-67.91z">
                </path>
              </svg>
              SIGN GUESTBOOK
            </button>
        </div>
      </CSSTransition>
    </TransitionGroup>


  )
}

export default Button_SignGuestbook