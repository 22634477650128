import React, { useEffect } from 'react'
import { Navbar } from '../Components/Navbar';
import { Introduction } from '../Components/Introduction';
import { QandA } from '../Components/QandA';
import { About } from '../Components/About';
import { Gallery } from '../Components/Gallery';
import { Footer } from '../Components/Footer';
import { Title } from '../Components/Title';
import { useLocation } from "react-router-dom";
import ReactGA from 'react-ga';


export const HomePage = () => {
  const location = useLocation()

  useEffect(()=> {
    ReactGA.pageview(window.location.pathname);
    
    if (location.hash) {

        let elem = document.getElementById(location.hash.slice(1))
        
        if (elem) {
            elem.scrollIntoView({behavior: "smooth"})
        }
    } else {
    window.scrollTo({top:0,left:0, behavior: "smooth"})
    }
  }, [location])

  return (
    <div class="animate-fadeIn">

        <Navbar mobile homePage />

        <Title />

        <Navbar browser homePage />
        
        <Introduction />

        <QandA />

        <About />

        <Gallery />

        <Footer />

    </div>
  )
}