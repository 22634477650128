import React, { useState, useEffect } from 'react'
import { Field, Form, Formik } from 'formik';
import * as yup from 'yup';
import { updateRsvp, updateRsvpByID, getRsvpByName, getRsvpByEmail } from '../Services/RsvpService';
import { Paper, Typography, TextField, Button, IconButton, InputLabel, Tooltip, Snackbar, Alert } from '@mui/material';
// import { Guestbook_AddButton } from '../Subcomponents/Guestbook_AddButton';
import { TransitionGroup, CSSTransition } from "react-transition-group";
import { SIGNGUESTBOOK_BTN_TEXTCOLOR, SIGNGUESTBOOK_BTN_BGCOLOR, SIGNGUESTBOOK_BTN_BGCOLOR_HOVER, SIGNGUESTBOOK_PWLABEL_TEXTCOLOR, SIGNGUESTBOOK_PWBOX_BORDERCOLOR, SIGNGUESTBOOK_PWBOX_BORDERCOLOR_HOVER, SIGNGUESTBOOK_PWBOX_BORDERCOLOR_FOCUSED, SIGNGUESTBOOK_TEXTCOLOR, RSVP_GUESTLIST_BTN_BGCOLOR, RSVP_GUESTLIST_BGCOLOR_HOVER, RSVP_GUESTLIST_BTN_WIDTH, RSVP_BTNBACK_BGCOLOR, RSVP_BTNBACK_BGCOLOR_HOVER, RSVP_BTNBACK_WIDTH_PG2 } from '../constants';
import { Close, KeyboardBackspace, KeyboardReturn, Check, ArrowRight, ArrowLeft, Person, PersonOff, PersonRemove, PersonAddAlt1, InsertInvitation } from '@mui/icons-material';
import useSound from 'use-sound';
import upBeat from '../Sounds/upBeat.mp3'
import { RsvpDivContainer } from './RsvpDivContainer';
import { RsvpPaperContainer } from './RsvpPaperContainer';
import { RsvpTitle } from './RsvpTitle';
import { Link as RouterLink } from "react-router-dom";



export const Rsvp_Form = ({ rsvp, setRsvp, setRequestsLoaded, handleClose, postState, setPostState, handleThanksOpen }) => {
  const [playUpBeat] = useSound(upBeat, {volume: 0.1})
  const [rsvpPage, setRsvpPage] = useState(1);
  const [initialNameSearch, setInitialNameSearch] = useState({firstname: "", lastname: ""});
  const [currentGuest, setCurrentGuest] = useState({});
  const [attendanceRF, setAttendanceRF] = useState(false);
  const [isVerified, setIsVerified] = useState("");
  const [findInvitationByEmail, setFindInvitationByEmail] = useState(false);
  const [successAlert, setSuccessAlert] = useState(false);
  const [errorAlert, setErrorAlert] = useState(false);


  // Not used anymore
  // useEffect(() => {
  //   if(isVerified==="No"){
  //       setTimeout(() => {
  //       setIsVerified("fadeOut");
  //     }, 5000)
  //   }
  //   // console.log(isVerified)
  // }, [isVerified])

  const _initialValuesIVFName = {
    firstname: '',
    lastname: ''
  };

  const _initialValuesIVFEmail = {
    email: ''
  };

  const _initialValues = {
    attending: '',
    entree: '',
    allergyandrestriction: '',
    songrequest: '',
    questionorcomment: ''
  };

  const _validationSchemaIVFName = yup.object({
    firstname: yup
        .string('*Please enter your first name')
        .required('*Please enter your first name'),
    lastname: yup
        .string('*Please enter your last name')
        .required('*Please enter your last name')
  });

  const _validationSchemaIVFEmail = yup.object({
    email: yup
        .string('*Please enter your email address')
        .required('*Please enter a valid email address')
  });

  const _validationSchemaYes = yup.object({
    firstname: yup
        .string('*Please enter your first name')
        .required('*Please enter your first name'),
    lastname: yup
        .string('*Please enter your last name')
        .required('*Please enter your last name'),
    attending: yup
        .string('*Please make your selection')
        .required('*Please indicate whether you are attending or not'),
    entree: yup
        .string('*Please make your selection')
        .required('*Please select an entrée'),
    allergyandrestriction: yup
        .string(''),
    songrequest: yup
        .string(''),
    questionorcomment: yup
        .string('')
  });

  // not required firstname, lastname, entree
  const _validationSchemaNo = yup.object({
    firstname: yup
        .string('*Please enter your first name'),
    lastname: yup
        .string('*Please enter your last name'),
    attending: yup
        .string('*Please make your selection')
        .required('*Please indicate whether you are attending or not'),
    entree: yup
        .string('*Please make your selection'),
    allergyandrestriction: yup
        .string(''),
    songrequest: yup
        .string(''),
    questionorcomment: yup
        .string('')
  });

  const handleSuccessAlertClose = () => { setSuccessAlert(false) };

  const handleErrorAlertClose = () => { setErrorAlert(false) };

  const handleSuccessAlertOpen = () => {
      setSuccessAlert(true);
  }

  const handleErrorAlertOpen = () => {
      setErrorAlert(true);
  }

  const trimSpaces = (e) => {
    let str = e.currentTarget.value.trim();
    return str;
  }

  const capitalizeFirstLetter = (e) => {
    let str = e.currentTarget.value.toLowerCase().trim();
    let strArr = str.split(' ')
    let name;
    if(strArr.length === 1) {
        name = strArr[0].charAt(0).toUpperCase() + strArr[0].slice(1)
    } else if(strArr.length === 2) {
        name = strArr[0].charAt(0).toUpperCase() + strArr[0].slice(1) + ' ' + strArr[1].charAt(0).toUpperCase() + strArr[1].slice(1)
    } else if(strArr.length === 3) {
        name = strArr[0].charAt(0).toUpperCase() + strArr[0].slice(1) + ' ' + strArr[1] + ' ' + strArr[2].charAt(0).toUpperCase() + strArr[2].slice(1)
    } else {
      name = str[0].toUpperCase() + str.slice(1, str.length);
    }

    return name;
  }

  const formatNameString = (str) => {
    let strArr = str.split(' ')
    let name;
    if(strArr.length === 1) {
        name = strArr[0].charAt(0).toUpperCase() + strArr[0].slice(1).toLowerCase()
    } else if(strArr.length === 2) {
        name = strArr[0].charAt(0).toUpperCase() + strArr[0].slice(1).toLowerCase() + ' ' + strArr[1].charAt(0).toUpperCase() + strArr[1].slice(1)
    } else if(strArr.length === 3) {
        name = strArr[0].charAt(0).toUpperCase() + strArr[0].slice(1).toLowerCase() + ' ' + strArr[1] + ' ' + strArr[2].charAt(0).toUpperCase() + strArr[2].slice(1)
    } else {
      name = str[0].toUpperCase() + str.slice(1, str.length);
    }

    return name;
  }

  const handleSelectGuest = (guest) => {
    console.log('CurrentGuest: ' + JSON.stringify(guest))
    setCurrentGuest(guest);
    setRsvpPage(3);
  }
  
  const handlePageBack = () => {
    setRsvpPage(rsvpPage-1);
  }

  const handleRsvpForAnotherGuest = () => {
    // getRsvpByName(currentGuest).then(res => {
    //   if(res.data.length !== 0) { 
    //     setRsvp(res.data[0]) 
    //     console.log('setting rsvp: '+ JSON.stringify(res.data[0]))
    //   }
    // });
    setRsvpPage(2);
  }

  const handleAttendanceRequiredFields = (selection) => {
    if(selection) {
      setAttendanceRF(true)
    } else {
      setAttendanceRF(false)
    }
  }

  const handleFindInvitationByEmail = (values, errors) => {
    // setIsVerified("No");

    if(errors?.firstname) { errors.firstname = '' }
    if(errors?.lastname) { errors.lastname = '' }
    if(errors?.email) { errors.email = '' }

    if(values?.firstname) { values.firstname = '' }
    if(values?.lastname) { values.lastname = '' }
    if(values?.email) { values.email = '' }

    setFindInvitationByEmail(!findInvitationByEmail);
  }

  const renderPage = (page) => {
    switch(page) {
      case 1:
          setRsvpPage(1);
          break;
      case 2:
          setRsvpPage(2);
          break;
      case 3:
          setRsvpPage(3);
          break;
      case 4:
          setRsvpPage(4);
          break;
      default:
          setRsvpPage(1);
    }
  }

  return (
    <>
    {/***** PAGE 1 *****/}
    { (rsvpPage === 1) &&
      <RsvpDivContainer>
        <CSSTransition timeout={0}>

        {findInvitationByEmail
          ?
            <>
            {/***** INVITATION VERIFICATION FORM EMAIL *****/}
            <Formik
              initialValues = {_initialValuesIVFEmail}
              validationSchema = {_validationSchemaIVFEmail}
              onSubmit = {(values, onSubmit) => {
                values.email = values.email.toLowerCase().trim();

                // setInitialNameSearch({firstname: values.firstname, lastname: values.lastname});

                getRsvpByEmail(values).then(res => {
                  if(res?.data?.length !== 0) { 
                    playUpBeat();
                    setRsvp(res?.data[0]);
                    // handleSuccessAlertOpen();
                    setIsVerified("Yes");
                    renderPage(2);
                  } else {
                    handleErrorAlertOpen();
                    setIsVerified("No");
                    onSubmit.resetForm();
                  }
                })

              }}
            >
              {formik => (
                <Form>
                  <RsvpPaperContainer>

                    {/* 0E - Title */}
                    <RsvpTitle />

                        {/* Spacer */}
                        <div class="2xs:p-2 sm:p-4"></div> 

                        {/* 1E - Email Name Text Field */}
                        <div class="2xs:p-3 sm:p-5 flex 2xs:space-x-4 sm:space-x-6 animate-fadeIn">
                          <Field
                              // autoFocus
                              fullWidth
                              label="Email Address"
                              name="email"
                              type="input"
                              onBlur={e => {
                                  formik.handleBlur(e)
                                  formik.values.email = formik.values.email.toLowerCase().trim();
                              }}
                              as={TextField}
                              error={formik.touched.email && Boolean(formik.errors.email)}
                              helperText={formik.touched.email && formik.errors.email}
                              variant="outlined"
                              sx={{
                                '& .MuiFormLabel-root': {fontFamily: "Inter", fontSize: 15},
                                '& label.Mui-focused': {color: SIGNGUESTBOOK_PWLABEL_TEXTCOLOR},
                                '& .MuiInput-underline:after': {borderBottomColor: SIGNGUESTBOOK_PWLABEL_TEXTCOLOR},
                                '& .MuiOutlinedInput-root': {
                                  '& fieldset': {
                                    borderColor: SIGNGUESTBOOK_PWBOX_BORDERCOLOR,
                                  },
                                  '&:hover fieldset': {
                                    borderColor: SIGNGUESTBOOK_PWBOX_BORDERCOLOR_HOVER,
                                  },
                                  '&.Mui-focused fieldset': {
                                    borderColor: SIGNGUESTBOOK_PWBOX_BORDERCOLOR_FOCUSED,
                                  },
                                },
                                '& .MuiInputBase-input': {
                                  color: SIGNGUESTBOOK_TEXTCOLOR,
                                  fontSize: 14,
                                  fontFamily: "Inter"
                                },
                                marginBottom: 0,
                                
                              }}
                          />
                        </div>

                    <br/>

                    {/* Spacer */}
                    <div class="2xs:p-2 sm:p-4"></div> 
                    
                    {/* 3E - Find Invitation Button */}
                    <div class="flex justify-center items-center -mt-2">
                      <Button startIcon={<InsertInvitation />} sx={{ fontSize: 13, fontFamily:'Inter', color: SIGNGUESTBOOK_BTN_TEXTCOLOR, bgcolor: SIGNGUESTBOOK_BTN_BGCOLOR, '&:hover': {bgcolor: SIGNGUESTBOOK_BTN_BGCOLOR_HOVER, scale: '1.01'}, transition: '400ms', padding: 1.5, width: '94%' }} color="primary" variant="contained" fullWidth type="submit">
                        Find Invitation
                      </Button>
                    </div>

                    {/* 4E - Swap to Find Invitation Method */}
                    <div class="text-center text-gray-400 hover:text-gray-300 duration-300 text-xs mt-[1.5rem] underline cursor-pointer animate-fadeIn">
                      <span onClick={() => handleFindInvitationByEmail(formik.values, formik.errors)}>
                        {findInvitationByEmail 
                          ? <span class="">Find invitation using your name</span> 
                          : <span class="">Find invitation using your email address</span>
                        }
                      </span>
                    </div>

                    {/* 4 - Invitation Error Message - NOT USED ANYMORE */}
                    {/* <div class="flex justify-center items-center mt-3">
                      <Typography>
                        {(isVerified==="No")
                          ? <span class="animate-fadeInSemiSlow text-red-600">Sorry, your invitation was not found.</span>
                          : (isVerified==="fadeOut") 
                            ? <span class="animate-fadeOutSemiSlow text-red-600 opacity-0">Sorry, your invitation was not found.</span>
                            : <span></span>
                        }
                      </Typography>
                    </div> */}

                  </RsvpPaperContainer>

                </Form>
              )}
            </Formik>
          </>
        :
          <>
          {/***** INVITATION VERIFICATION FORM NAME *****/}
            <Formik
              initialValues = {!findInvitationByEmail && _initialValuesIVFName}
              validationSchema = {!findInvitationByEmail && _validationSchemaIVFName}
              onSubmit = {(values, onSubmit) => {
                values.firstname = formatNameString(values.firstname)
                values.lastname = formatNameString(values.lastname)

                setInitialNameSearch({firstname: values.firstname, lastname: values.lastname});

                getRsvpByName(values).then(res => {
                  if(res?.data?.length !== 0) { 
                    playUpBeat();
                    setRsvp(res?.data[0]);

                    // console.log('res data:')
                    // console.log(res?.data[0])

                    // handleSuccessAlertOpen();
                    setIsVerified("Yes");
                    renderPage(2);
                  } else {
                    handleErrorAlertOpen();
                    setIsVerified("No");
                    onSubmit.resetForm();
                  }
                })
                
              }}
            >
              {formik => (
                <Form>
                  <RsvpPaperContainer>

                    {/* 0N - Title */}
                    <RsvpTitle />

                    <div>
                      {/* 1N - First Name Text Field */}
                      <div class="2xs:p-3 sm:p-5 flex 2xs:space-x-4 sm:space-x-6 animate-fadeIn">
                        <Field
                            // autoFocus
                            fullWidth
                            label="First Name"
                            name="firstname"
                            type="input"
                            onBlur={e => {
                                formik.handleBlur(e)
                                formik.values.firstname = capitalizeFirstLetter(e)
                            }}
                            as={TextField}
                            error={formik.touched.firstname && Boolean(formik.errors.firstname)}
                            helperText={formik.touched.firstname && formik.errors.firstname}
                            variant="outlined"
                            sx={{
                              '& .MuiFormLabel-root': {fontFamily: "Inter", fontSize: 15},
                              '& label.Mui-focused': {color: SIGNGUESTBOOK_PWLABEL_TEXTCOLOR},
                              '& .MuiInput-underline:after': {borderBottomColor: SIGNGUESTBOOK_PWLABEL_TEXTCOLOR},
                              '& .MuiOutlinedInput-root': {
                                '& fieldset': {
                                  borderColor: SIGNGUESTBOOK_PWBOX_BORDERCOLOR,
                                },
                                '&:hover fieldset': {
                                  borderColor: SIGNGUESTBOOK_PWBOX_BORDERCOLOR_HOVER,
                                },
                                '&.Mui-focused fieldset': {
                                  borderColor: SIGNGUESTBOOK_PWBOX_BORDERCOLOR_FOCUSED,
                                },
                              },
                              '& .MuiInputBase-input': {
                                color: SIGNGUESTBOOK_TEXTCOLOR,
                                fontSize: 14,
                                fontFamily: "Inter"
                              },
                              marginBottom: 0,
                            }}
                        />
                    </div>

                    {/* 2N - Last Name Text Field */}
                    <div class="2xs:p-3 sm:p-5 flex 2xs:space-x-4 sm:space-x-6 animate-fadeIn">
                      <Field
                          fullWidth
                          label="Last Name"
                          name="lastname"
                          type="input"
                          onBlur={e => {
                              formik.handleBlur(e)
                              formik.values.lastname = capitalizeFirstLetter(e)
                          }}
                          as={TextField}
                          error={formik.touched.lastname && Boolean(formik.errors.lastname)}
                          helperText={formik.touched.lastname && formik.errors.lastname}
                          variant="outlined"
                          sx={{
                            '& .MuiFormLabel-root': {fontFamily: "Inter", fontSize: 15},
                            '& label.Mui-focused': {color: SIGNGUESTBOOK_PWLABEL_TEXTCOLOR},
                            '& .MuiInput-underline:after': {borderBottomColor: SIGNGUESTBOOK_PWLABEL_TEXTCOLOR},
                            '& .MuiOutlinedInput-root': {
                              '& fieldset': {
                                borderColor: SIGNGUESTBOOK_PWBOX_BORDERCOLOR,
                              },
                              '&:hover fieldset': {
                                borderColor: SIGNGUESTBOOK_PWBOX_BORDERCOLOR_HOVER,
                              },
                              '&.Mui-focused fieldset': {
                                borderColor: SIGNGUESTBOOK_PWBOX_BORDERCOLOR_FOCUSED,
                              },
                            },
                            '& .MuiInputBase-input': {
                              color: SIGNGUESTBOOK_TEXTCOLOR,
                              fontSize: 14,
                              fontFamily: "Inter"
                            },
                            marginBottom: 0,
                          }}
                        />
                      </div>
                    </div>

                    <br/>
                    
                    {/* 3N - Find Invitation Button */}
                    <div class="flex justify-center items-center -mt-2">
                      <Button startIcon={<InsertInvitation />} sx={{ fontSize: 13, fontFamily:'Inter', color: SIGNGUESTBOOK_BTN_TEXTCOLOR, bgcolor: SIGNGUESTBOOK_BTN_BGCOLOR, '&:hover': {bgcolor: SIGNGUESTBOOK_BTN_BGCOLOR_HOVER, scale: '1.01'}, transition: '400ms', padding: 1.5, width: '94%' }} color="primary" variant="contained" fullWidth type="submit">
                        Find Invitation
                      </Button>
                    </div>

                    {/* 4N - Swap to Find Invitation Method */}
                    <div class="text-center text-gray-400 hover:text-gray-300 duration-300 text-xs mt-[1.5rem] underline cursor-pointer animate-fadeIn">
                      <span onClick={() => handleFindInvitationByEmail(formik.values, formik.errors)}>
                        {findInvitationByEmail 
                          ? <span class="">Find invitation using your name</span> 
                          : <span class="">Find invitation using your email address</span>
                        }
                      </span>
                    </div>

                    {/* 4 - Invitation Error Message - NOT USED ANYMORE */}
                    {/* <div class="flex justify-center items-center mt-3">
                      <Typography>
                        {(isVerified==="No")
                          ? <span class="animate-fadeInSemiSlow text-red-600">Sorry, your invitation was not found.</span>
                          : (isVerified==="fadeOut") 
                            ? <span class="animate-fadeOutSemiSlow text-red-600 opacity-0">Sorry, your invitation was not found.</span>
                            : <span></span>
                        }
                      </Typography>
                    </div> */}

                  </RsvpPaperContainer>

                </Form>
              )}
            </Formik>
          </>
      }
      </CSSTransition>
    </RsvpDivContainer>
    }

    {/***** PAGE 2 *****/}
    { rsvpPage === 2 &&
      <>
      <RsvpDivContainer>
         {/* <CSSTransition timeout={0}> */}

         <RsvpPaperContainer handlePageBack={handlePageBack}>

          {/* 0 - Back Button */}
          {/* <div class="flex justify-end items-center 2xs:-mt-4 sm:-mt-1 2xs:-mr-0 sm:-mr-1">
            <div class="2xs:-mr-2 sm:mr-3">
              <IconButton onClick={handlePageBack} sx={{ padding: 0.75 }}>
                <KeyboardBackspace sx={{ fontSize: 20 }} />
              </IconButton>
            </div>
          </div> */}

          {/* 0 - Title */}
          <RsvpTitle />

          {/* 1 - Guest List */}
          {rsvp.guestList.map((guest, index) => (
              <div class="flex justify-center items-center my-4">
                {(guest.attending === "Yes" || guest.attending === "")
                  ?
                  <>
                    {
                    (guest.attending === "Yes") 
                    ?
                      <Tooltip title="Attending" placement="top-end" arrow>
                        <Button startIcon={<PersonAddAlt1 />} sx={{ fontSize: 13, fontFamily:'Inter', color: SIGNGUESTBOOK_BTN_TEXTCOLOR, bgcolor: RSVP_GUESTLIST_BTN_BGCOLOR, '&:hover': {bgcolor: RSVP_GUESTLIST_BGCOLOR_HOVER, scale: '1.01'}, transition: '400ms', padding: 1.5, width: RSVP_GUESTLIST_BTN_WIDTH }} color="primary" variant="contained" fullWidth onClick={() => handleSelectGuest(guest)} >
                          {!guest?.firstname && !guest?.lastname ? "GUEST " : <>{guest.firstname} {guest.lastname} </>}
                        </Button>
                      </Tooltip>
                    :
                      <Tooltip title="No Answer" placement="top-end" arrow>
                        <Button startIcon={<Person sx={{ color: "lightgray"}} />} sx={{ fontSize: 13, fontFamily:'Inter', color: SIGNGUESTBOOK_BTN_TEXTCOLOR, bgcolor: RSVP_GUESTLIST_BTN_BGCOLOR, '&:hover': {bgcolor: RSVP_GUESTLIST_BGCOLOR_HOVER, scale: '1.01'}, transition: '400ms', padding: 1.5, width: RSVP_GUESTLIST_BTN_WIDTH }} color="primary" variant="contained" fullWidth onClick={() => handleSelectGuest(guest)} >
                          {!guest?.firstname && !guest?.lastname ? "GUEST INVITE" : <>{guest.firstname} {guest.lastname} </>}
                        </Button>
                      </Tooltip>
                    }
                  </>

                  :
                  <>
                    <Tooltip title="Not attending" placement="top-end" arrow>
                      <Button startIcon={<PersonRemove />}sx={{ fontSize: 13, fontFamily:'Inter', color: SIGNGUESTBOOK_BTN_TEXTCOLOR, bgcolor: RSVP_GUESTLIST_BTN_BGCOLOR, '&:hover': {bgcolor: RSVP_GUESTLIST_BGCOLOR_HOVER, scale: '1.01'}, transition: '400ms', padding: 1.5, width: RSVP_GUESTLIST_BTN_WIDTH }} color="primary" variant="contained" fullWidth onClick={() => handleSelectGuest(guest)} >
                        {!guest?.firstname || !guest?.lastname ? "GUEST " : <>{guest.firstname} {guest.lastname} </>}
                      </Button>
                    </Tooltip>
                  </>
                }
              </div>
            ))}

          {/* 3 - Back Button */}
          <div class="2xs:mt-2 flex justify-center items-center -mt-2">
            <Button startIcon={<KeyboardReturn />} sx={{ fontSize: 12, fontFamily:'Inter', color: SIGNGUESTBOOK_BTN_TEXTCOLOR, bgcolor: RSVP_BTNBACK_BGCOLOR, '&:hover': {bgcolor: RSVP_BTNBACK_BGCOLOR_HOVER, scale: '1.01'}, transition: '400ms', padding: 1.5, width: RSVP_BTNBACK_WIDTH_PG2 }} color="primary" variant="contained" fullWidth onClick={handlePageBack} >
              Back
            </Button>
          </div>

          <br/>

          </RsvpPaperContainer>
        {/* </CSSTransition> */}
      </RsvpDivContainer>
      </>
    }

    {/***** PAGE 3 *****/}
    { rsvpPage === 3 &&
    <RsvpDivContainer>
      <CSSTransition timeout={0}>

        {/***** RSVP FORM *****/}
        <Formik
            initialValues = {currentGuest}
            validationSchema = {attendanceRF ? _validationSchemaYes : _validationSchemaNo}
            onSubmit = {(values, onSubmit) => {
              playUpBeat();

              if(currentGuest.firstname==="" || currentGuest.lastname==="") {
                updateRsvpByID(currentGuest, values, rsvp).then(() => {
                  rsvp.guestList.forEach((el, index) => {
                    if((el.firstname===currentGuest.firstname) && (el.lastname===currentGuest.lastname)) {
                      rsvp.guestList.splice(index, 1, values);
                    }
                  })
                  setCurrentGuest(values);
                  setRsvpPage(4);
                });
              } else {
                updateRsvp(currentGuest, values, rsvp).then(() => {
                  rsvp.guestList.forEach((el, index) => {
                    if((el.firstname===currentGuest.firstname) && (el.lastname===currentGuest.lastname)) {
                      rsvp.guestList.splice(index, 1, values);
                    }
                  })
                  setCurrentGuest(values);
                  setRsvpPage(4);
                });
              }
              
              window.scrollTo(0,0);
            }}
          >
            {formik => (
              <Form>
                  {/***** RSVP FORM *****/}
                  <RsvpPaperContainer mainForm>

                    {/* 0 - Back Button */}
                    {/* <div class="flex justify-end items-center 2xs:-mt-4 sm:-mt-1 2xs:-mr-0 sm:-mr-1">
                      <div class="2xs:-mr-2 sm:mr-3">
                        <IconButton onClick={handlePageBack} sx={{ padding: 0.75 }}>
                          <KeyboardBackspace sx={{ fontSize: 20 }} />
                        </IconButton>
                      </div>
                    </div> */}

                    {/* 0 - Title */}
                    <RsvpTitle />

                    {/* 1 - Attendance */}
                    <div class="2xs:pl-3 sm:pl-5 2xs:pt-6 2xs:pb-2 flex 2xs:space-x-4 sm:space-x-6">
                      {(formik.values.firstname === initialNameSearch.firstname && formik.values.lastname === initialNameSearch.lastname)
                        ? <Typography>Will you be able to attend our wedding reception on April 30, 2023?</Typography>
                        : (formik.values.firstname==="" && formik.values.lastname==="")
                          ? <Typography>Will your guest be able to attend our wedding reception on April 30, 2023?</Typography>
                          : <Typography>Will {formik.values.firstname} be able to attend our wedding reception on April 30, 2023?</Typography>
                      }
                    </div>
                      
                    <div class="2xs:px-3 sm:px-5">
                      <div class="flex justify-left items-center" >
                        <span class="2xs:pl-1"></span>
                        <Field 
                          name="attending"
                          type="radio"
                          value="Yes"
                          onClick={() => handleAttendanceRequiredFields(true)}
                        />
                        <span class="2xs:pl-2"></span>
                        <InputLabel>Yes</InputLabel>
                      </div>
                      
                      <div class="flex justify-left items-center" >
                        <span class="2xs:pl-1"></span>
                        <Field 
                          name="attending"
                          type="radio"
                          value="No"
                          color="red"
                          onClick={() => handleAttendanceRequiredFields(false)}
                        />
                        <span class="2xs:pl-2"></span>
                        <InputLabel>No</InputLabel>
                      </div>

                      {formik.touched.attending && Boolean(formik.errors.attending) && <p className="text-left text-red-600 text-xs 2xs:ml-2 2xs:mt-2">*Please indicate your attendance</p>}
                    </div>

                    {formik.values.attending === "Yes" &&
                      <>
                        {/* 2 - First Name Text Field */}
                        <div class="2xs:pl-3 sm:pl-5 2xs:pt-6 2xs:pb-1 flex 2xs:space-x-4 sm:space-x-6">
                          <Typography>Guest Details</Typography>
                        </div>

                        <div class="2xs:px-3 sm:px-5 2xs:py-2 flex 2xs:space-x-4 sm:space-x-6">
                          <Field
                              fullWidth
                              label="First Name"
                              name="firstname"
                              type="input"
                              onBlur={e => {
                                  formik.handleBlur(e)
                                  formik.values.firstname = capitalizeFirstLetter(e)
                              }}
                              as={TextField}
                              error={formik.touched.firstname && Boolean(formik.errors.firstname)}
                              helperText={formik.touched.firstname && formik.errors.firstname}
                              variant="outlined"
                              sx={{
                                '& .MuiFormLabel-root': {fontFamily: "Inter", fontSize: 15},
                                '& label.Mui-focused': {color: SIGNGUESTBOOK_PWLABEL_TEXTCOLOR},
                                '& .MuiInput-underline:after': {borderBottomColor: SIGNGUESTBOOK_PWLABEL_TEXTCOLOR},
                                '& .MuiOutlinedInput-root': {
                                  '& fieldset': {
                                    borderColor: SIGNGUESTBOOK_PWBOX_BORDERCOLOR,
                                  },
                                  '&:hover fieldset': {
                                    borderColor: SIGNGUESTBOOK_PWBOX_BORDERCOLOR_HOVER,
                                  },
                                  '&.Mui-focused fieldset': {
                                    borderColor: SIGNGUESTBOOK_PWBOX_BORDERCOLOR_FOCUSED,
                                  },
                                },
                                '& .MuiInputBase-input': {
                                  color: SIGNGUESTBOOK_TEXTCOLOR,
                                  fontSize: 14,
                                  fontFamily: "Inter"
                                },
                                marginBottom: 0,
                                
                              }}
                          />
                        </div>

                        {/* 3 - Last Name Text Field */}
                        <div class="2xs:px-3 sm:px-5 2xs:py-2 2xs:mb-0 flex 2xs:space-x-4 sm:space-x-6">
                          <Field
                              fullWidth
                              label="Last Name"
                              name="lastname"
                              type="input"
                              onBlur={e => {
                                  formik.handleBlur(e)
                                  formik.values.lastname = capitalizeFirstLetter(e)
                              }}
                              as={TextField}
                              error={formik.touched.lastname && Boolean(formik.errors.lastname)}
                              helperText={formik.touched.lastname && formik.errors.lastname}
                              variant="outlined"
                              sx={{
                                '& .MuiFormLabel-root': {fontFamily: "Inter", fontSize: 15},
                                '& label.Mui-focused': {color: SIGNGUESTBOOK_PWLABEL_TEXTCOLOR},
                                '& .MuiInput-underline:after': {borderBottomColor: SIGNGUESTBOOK_PWLABEL_TEXTCOLOR},
                                '& .MuiOutlinedInput-root': {
                                  '& fieldset': {
                                    borderColor: SIGNGUESTBOOK_PWBOX_BORDERCOLOR,
                                  },
                                  '&:hover fieldset': {
                                    borderColor: SIGNGUESTBOOK_PWBOX_BORDERCOLOR_HOVER,
                                  },
                                  '&.Mui-focused fieldset': {
                                    borderColor: SIGNGUESTBOOK_PWBOX_BORDERCOLOR_FOCUSED,
                                  },
                                },
                                '& .MuiInputBase-input': {
                                  color: SIGNGUESTBOOK_TEXTCOLOR,
                                  fontSize: 14,
                                  fontFamily: "Inter"
                                },
                                marginBottom: 0,
                                
                              }}
                          />
                        </div>

                        {/* 4 - Entree */}
                        <div class="2xs:pl-3 sm:pl-5 2xs:pt-6 2xs:pb-2 flex 2xs:space-x-4 sm:space-x-6">
                          <Typography>Choose an entrée:</Typography>
                        </div>

                        <div class="2xs:px-3 sm:px-5">
                          <div class="flex justify-left items-center 2xs:mb-1" >
                            <span class="2xs:pl-1"></span>
                            <Field 
                              name="entree"
                              type="radio"
                              value="Wild Mushroom Carnaroli Risotto"
                            />
                            <span class="2xs:pl-2"></span>
                            <InputLabel>Wild Mushroom Carnaroli Risotto (Vegetarian)</InputLabel>
                          </div>
                          <div class="flex justify-left items-center 2xs:mb-1" >
                            <span class="2xs:pl-1"></span>
                            <Field 
                              name="entree"
                              type="radio"
                              value="Miso Glazed Black Cod"
                            />
                            <span class="2xs:pl-2"></span>
                            <InputLabel>Miso Glazed Black Cod</InputLabel>
                          </div>
                          <div class="flex justify-left items-center 2xs:mb-1" >
                            <span class="2xs:pl-1"></span>
                            <Field 
                              name="entree"
                              type="radio"
                              value="Pistachio Crusted Ontario Lamb Chops"
                            />
                            <span class="2xs:pl-2"></span>
                            <InputLabel>Pistachio Crusted Ontario Lamb Chops</InputLabel>
                          </div>

                          {(formik.errors.entree) && <p className="text-left text-red-600 text-xs 2xs:ml-2 2xs:mt-2">*An entrée selection is required</p>}

                        </div>

                        {/* 5 - Allergies and Dietary Restrictions */}
                        <div class="2xs:pl-3 sm:pl-5 2xs:pt-6 2xs:pb-3 flex 2xs:space-x-4 sm:space-x-6">
                          {(formik.values.firstname !== initialNameSearch.firstname && formik.values.lastname !== initialNameSearch.lastname)
                            ? <Typography>Does your guest have any allergies or dietary restrictions?</Typography>
                            : <Typography>Do you have any allergies or dietary restrictions?</Typography>
                          }
                        </div>

                        <div class="2xs:px-3 sm:px-5 flex 2xs:space-x-4 sm:space-x-6">
                          <Field
                              fullWidth
                              // label="Allergies and Dietary Restrictions"
                              placeholder="Vegan, Peanut Allergy, Etc."
                              name="allergyandrestriction"
                              type="input"
                              onBlur={e => {
                                  formik.handleBlur(e)
                                  formik.values.allergyandrestriction = capitalizeFirstLetter(e)
                              }}
                              as={TextField}
                              error={formik.touched.allergyandrestriction && Boolean(formik.errors.allergyandrestriction)}
                              helperText={formik.touched.allergyandrestriction && formik.errors.allergyandrestriction}
                              // inputProps={{ className: classes.form_input }}
                              variant="outlined"
                              sx={{
                                '& .MuiFormLabel-root': {fontFamily: "Inter", fontSize: 15},
                                '& label.Mui-focused': {color: SIGNGUESTBOOK_PWLABEL_TEXTCOLOR},
                                '& .MuiInput-underline:after': {borderBottomColor: SIGNGUESTBOOK_PWLABEL_TEXTCOLOR},
                                '& .MuiOutlinedInput-root': {
                                  '& fieldset': {
                                    borderColor: SIGNGUESTBOOK_PWBOX_BORDERCOLOR,
                                  },
                                  '&:hover fieldset': {
                                    borderColor: SIGNGUESTBOOK_PWBOX_BORDERCOLOR_HOVER,
                                  },
                                  '&.Mui-focused fieldset': {
                                    borderColor: SIGNGUESTBOOK_PWBOX_BORDERCOLOR_FOCUSED,
                                  },
                                },
                                '& .MuiInputBase-input': {
                                  color: SIGNGUESTBOOK_TEXTCOLOR,
                                  fontSize: 14,
                                  fontFamily: "Inter"
                                },
                                marginBottom: 0,
                              }}
                          />
                        </div>

                        {/* 6 - Song Requests */}
                        <div class="2xs:pl-3 sm:pl-5 2xs:pt-6 2xs:pb-3 flex 2xs:space-x-4 sm:space-x-6">
                          {(formik.values.firstname !== initialNameSearch.firstname && formik.values.lastname !== initialNameSearch.lastname)
                            ? <Typography>Does your guest have any song requests for the dance floor?</Typography>
                            : <Typography>Do you have any song requests for the dance floor?</Typography>
                          }
                        </div>

                        <div class="2xs:px-3 sm:px-5 flex 2xs:space-x-4 sm:space-x-6">
                          <Field
                              fullWidth
                              // label="Song Request"
                              // placeholder="Taylor Swift"
                              name="songrequest"
                              type="input"
                              onBlur={e => {
                                  formik.handleBlur(e)
                                  formik.values.songrequest = capitalizeFirstLetter(e)
                              }}
                              as={TextField}
                              error={formik.touched.songrequest && Boolean(formik.errors.songrequest)}
                              helperText={formik.touched.songrequest && formik.errors.songrequest}
                              // inputProps={{ className: classes.form_input }}
                              variant="outlined"
                              sx={{
                                '& .MuiFormLabel-root': {fontFamily: "Inter", fontSize: 15},
                                '& label.Mui-focused': {color: SIGNGUESTBOOK_PWLABEL_TEXTCOLOR},
                                '& .MuiInput-underline:after': {borderBottomColor: SIGNGUESTBOOK_PWLABEL_TEXTCOLOR},
                                '& .MuiOutlinedInput-root': {
                                  '& fieldset': {
                                    borderColor: SIGNGUESTBOOK_PWBOX_BORDERCOLOR,
                                  },
                                  '&:hover fieldset': {
                                    borderColor: SIGNGUESTBOOK_PWBOX_BORDERCOLOR_HOVER,
                                  },
                                  '&.Mui-focused fieldset': {
                                    borderColor: SIGNGUESTBOOK_PWBOX_BORDERCOLOR_FOCUSED,
                                  },
                                },
                                '& .MuiInputBase-input': {
                                  color: SIGNGUESTBOOK_TEXTCOLOR,
                                  fontSize: 14,
                                  fontFamily: "Inter"
                                },
                                marginBottom: 0,
                              }}
                          />
                        </div>

                        {/* 7 - Questions or Comments */}
                        <div class="2xs:pl-3 sm:pl-5 2xs:pt-6 2xs:pb-3 flex 2xs:space-x-4 sm:space-x-6">
                          {(formik.values.firstname !== initialNameSearch.firstname && formik.values.lastname !== initialNameSearch.lastname)
                            ? <Typography>Does your guest have any questions or comments for us?</Typography>
                            : <Typography>Do you have any questions or comments for us?</Typography>
                          }
                        </div>
                        
                        <div class="2xs:px-3 sm:px-5">
                          <Field
                              fullWidth
                              // label="Message"
                              // placeholder="content"
                              name="questionorcomment"
                              type="input"
                              onBlur={e => {
                                formik.handleBlur(e)
                                formik.values.questionorcomment = trimSpaces(e)
                              }}
                              as={TextField}
                              error={formik.touched.questionorcomment && Boolean(formik.errors.questionorcomment)}
                              helperText={formik.touched.questionorcomment && formik.errors.questionorcomment}
                              variant="outlined"
                              multiline
                              rows={6}
                              sx={{
                                '& .MuiFormLabel-root': {fontFamily: "Inter", fontSize: 15},
                                '& label.Mui-focused': {color: SIGNGUESTBOOK_PWLABEL_TEXTCOLOR},
                                '& .MuiInput-underline:after': {borderBottomColor: SIGNGUESTBOOK_PWLABEL_TEXTCOLOR},
                                '& .MuiOutlinedInput-root': {
                                  '& fieldset': {
                                    borderColor: SIGNGUESTBOOK_PWBOX_BORDERCOLOR,
                                  },
                                  '&:hover fieldset': {
                                    borderColor: SIGNGUESTBOOK_PWBOX_BORDERCOLOR_HOVER,
                                  },
                                  '&.Mui-focused fieldset': {
                                    borderColor: SIGNGUESTBOOK_PWBOX_BORDERCOLOR_FOCUSED,
                                  },
                                },
                                '& .MuiInputBase-input': {
                                  color: SIGNGUESTBOOK_TEXTCOLOR,
                                  fontSize: 14,
                                  fontFamily: "Inter"
                                },

                                marginBottom: 0,
                              }}
                          />
                        </div>

                        {/* 7 - Mobile Message Field */}
                        {/* <div class="p-3 sm:hidden">
                          <Typography >Any questions or comments?</Typography>
                          <Field
                              fullWidth
                              label="Message"
                              // placeholder="content"
                              name="questionorcomment"
                              type="input"
                              onBlur={e => {
                                formik.handleBlur(e)
                                formik.values.questionorcomment = trimSpaces(e)
                              }}
                              as={TextField}
                              error={formik.touched.questionorcomment && Boolean(formik.errors.questionorcomment)}
                              helperText={formik.touched.questionorcomment && formik.errors.questionorcomment}
                              // inputProps={{ className: classes.form_input }}
                              variant="outlined"
                              multiline
                              rows={5}
                              sx={{
                                '& .MuiFormLabel-root': {fontFamily: "Inter", fontSize: 14},
                                '& label.Mui-focused': {color: SIGNGUESTBOOK_PWLABEL_TEXTCOLOR},
                                '& .MuiInput-underline:after': {borderBottomColor: SIGNGUESTBOOK_PWLABEL_TEXTCOLOR},
                                '& .MuiOutlinedInput-root': {
                                  '& fieldset': {
                                    borderColor: SIGNGUESTBOOK_PWBOX_BORDERCOLOR,
                                  },
                                  '&:hover fieldset': {
                                    borderColor: SIGNGUESTBOOK_PWBOX_BORDERCOLOR_HOVER,
                                  },
                                  '&.Mui-focused fieldset': {
                                    borderColor: SIGNGUESTBOOK_PWBOX_BORDERCOLOR_FOCUSED,
                                  },
                                },
                                '& .MuiInputBase-input': {
                                  color: SIGNGUESTBOOK_TEXTCOLOR,
                                  fontSize: 14,
                                },
                                marginBottom: 0,
                                
                              }}
                          />
                        </div> */}

                      </>
                    }

                    <br/>
                    
                    {/* 8 - RSVP Button */}
                    <div class="2xs:mt-2 flex justify-center items-center -mt-2">
                      <Button startIcon={<InsertInvitation />} sx={{ fontSize: 13, fontFamily:'Inter', color: SIGNGUESTBOOK_BTN_TEXTCOLOR, bgcolor: SIGNGUESTBOOK_BTN_BGCOLOR, '&:hover': {bgcolor: SIGNGUESTBOOK_BTN_BGCOLOR_HOVER, scale: '1.01'}, transition: '400ms', padding: 1.5, width: '96%' }} color="primary" variant="contained" fullWidth type="submit" >
                        {currentGuest?.attending !== "" ? "Update RSVP": "Confirm RSVP"}
                      </Button>
                    </div>

                    {/* 9 - Back Button */}
                    <div class="2xs:mt-2 flex justify-center items-center -mt-2">
                      <Button startIcon={<KeyboardReturn />} sx={{ fontSize: 13, fontFamily:'Inter', color: SIGNGUESTBOOK_BTN_TEXTCOLOR, bgcolor: RSVP_BTNBACK_BGCOLOR, '&:hover': {bgcolor: RSVP_BTNBACK_BGCOLOR_HOVER, scale: '1.01'}, transition: '400ms', padding: 1.5, width: '96%' }} color="primary" variant="contained" fullWidth onClick={handlePageBack} >
                        Back
                      </Button>
                    </div>

                    {/* 10 - Form Error Message */}
                    <div class="2xs:mt-2 flex justify-center items-center -mt-2">
                      {(!formik.isValid) && <p className="text-left text-red-600 text-xs 2xs:ml-2 2xs:mt-2">*You must complete the necessary fields to confirm your RSVP.</p>}
                    </div>

                  </RsvpPaperContainer>

                  {/* {(!formik.isValid) && <Typography className={classes.submitButtonDescriptionBelow}>You must complete the entire form to post a message</Typography>} */}
              </Form>
            )}
          </Formik>
        </CSSTransition>
      </RsvpDivContainer>
    }
    

    {/***** PAGE 4 *****/}
    { rsvpPage === 4 &&
      <>
      <RsvpDivContainer>
         {/* <CSSTransition timeout={0}> */}
         <RsvpPaperContainer>

            {/* 0 - Close Button Mobile*/}
            {/* <div class="flex justify-end items-center 2xs:-mt-4 sm:-mt-1 2xs:-mr-0 sm:-mr-1">
              <div class="2xs:-mr-2 sm:mr-3">
                <IconButton onClick={() => renderPage(1)} sx={{ padding: 0.75 }}>
                  <Close sx={{ fontSize: 20 }} />
                </IconButton>
              </div>
            </div> */}

            <div class='flex flex-col justify-center w-full text-center font-[300]'>
              {/* 1 - Confirmation Heading */}
              <p class="font-crimsonText 2xs:mx-0 sm:mx-4 mt-0 2xs:text-3xl">
                RSVP Confirmed!
              </p>

              {/* 2- Confirmation Body Text 1 */}
              <p class="text-gray-800 2xs:mx-0 sm:mx-4 mt-7 2xs:text-[1.05rem]">
                Thank you for your RSVP.
              </p>

              {/* 3 - Confirmation Body Text 2 */}
              <p class="text-gray-800 2xs:mx-0 sm:mx-4 mt-3 2xs:text-[1.05rem]">
                {currentGuest?.attending==="Yes" 
                  ? <>
                      <span>For more details on our reception, please read the </span>
                      <RouterLink to="/#QandA"><span class="text-[0.95rem] text-gray-500 hover:text-gray-300 duration-300">Q&amp;A</span></RouterLink>
                      <span> section on our website or feel free to email us at </span>
                      <a class="text-[0.95rem] text-gray-500 hover:text-gray-300 duration-300" href={`mailto:erinandnorris@gmail.com`}>erinandnorris@gmail.com</a>
                      <span> if you have any questions.</span>
                    </>
                  : `We're sad that you can't make it, but hopefully we'll see you soon.`}
              </p>

              {/* 4 - Confirmation Body Text 3 */}
              <p class="text-gray-400 2xs:mx-0 sm:mx-4 mt-7 mb-2 2xs:text-[0.75rem]">
                A confirmation email has been sent to {rsvp?.email}
              </p>
            </div>

            {/* 4 - RSVP for another guest button*/}
            { (rsvp.numberOfGuests > 1) &&
              <div class="2xs:mt-2 flex justify-center items-center -mt-2">
                <div class="2xs:w-5/6 sm:w-2/4">
                  <Button sx={{ fontSize: 12, fontFamily:'Inter', color: SIGNGUESTBOOK_BTN_TEXTCOLOR, bgcolor: SIGNGUESTBOOK_BTN_BGCOLOR, '&:hover': {bgcolor: SIGNGUESTBOOK_BTN_BGCOLOR_HOVER, scale: '1.00'}, transition: '400ms', padding: 1.2 }} color="primary" variant="contained" fullWidth onClick={handleRsvpForAnotherGuest}>
                    RSVP for another guest
                  </Button>
                </div>
              </div>
            }
          
            {/* <div class="flex justify-center items-center my-4">
              <Button sx={{ fontSize: 13, fontFamily:'Inter', color: SIGNGUESTBOOK_BTN_TEXTCOLOR, bgcolor: SIGNGUESTBOOK_BTN_BGCOLOR, '&:hover': {bgcolor: SIGNGUESTBOOK_BTN_BGCOLOR_HOVER, scale: '1.01'}, transition: '400ms', padding: 1.5, width: '92%' }} color="primary" variant="contained" fullWidth onClick={() => handleSelectGuest(guest)} >
                {guest.firstname} {guest?.lastname} - {guest?.attending==="" ? "No Answer" : guest.attending}
              </Button>
            </div> */}

          </RsvpPaperContainer>
        {/* </CSSTransition> */}
        </RsvpDivContainer>
      </>
    }

    {/* Find Invitation Success Alert */}
    {/* <Snackbar 
        open={successAlert} 
        onClose={handleSuccessAlertClose} 
        autoHideDuration={5000} 
        anchorOrigin={{vertical: 'bottom', horizontal: 'center'}}
    >
        <div class="mb-6">
          <Alert severity='success'>
            <span class="">Found your invitation!</span>
          </Alert>
        </div>
    </Snackbar> */}

    {/* Find Invitation Error Alert */}
    <Snackbar 
        open={errorAlert} 
        onClose={handleErrorAlertClose} 
        autoHideDuration={5000} 
        anchorOrigin={{vertical: 'bottom', horizontal: 'center'}}
    >
        <div class="mb-6">
          <Alert severity='error'>
            <span class="">Sorry, your invitation was not found.</span>
          </Alert>
        </div>
    </Snackbar>
    </>
  )
}
