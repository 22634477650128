import React, { useState } from 'react'
import MainSection from '../Subcomponents/MainSection'
import CustomParagraph from '../Subcomponents/CustomParagraph'
import { Link as RouterLink } from "react-router-dom";
import { RSVP_DEADLINE } from '../constants';
import { Snackbar, Alert } from '@mui/material';


export const QandA = () => {
    const [copyAlert, setCopyAlert] = useState(false);

    const handleCopyAlertClose = () => { setCopyAlert(false) };

    const handleEmailClick = () => {
        setCopyAlert(true);
        navigator.clipboard.writeText('erinandnorris@gmail.com')
    }

    // const qa = [
    //     {
    //         Q: "When is the wedding ceremony happening?",
    //         A: "The wedding ceremony is happening on October 30th, 2022."
    //     },
    //     {
    //         Q: "When and where is the wedding reception happening?",
    //         A: "The wedding reception is happening on April 30th, 2022 at Ricarda's Restaurant."
    //     },
    //     {
    //         Q: "When and how do I tune into the replay of the wedding ceremony?",
    //         A: "The recording of the ceremony will air on ________ at ______. The 'watch' page will be available after the ceremony date for you to tune in."
    //     },
    //     {
    //         Q: "Can I attend the wedding ceremony in person?",
    //         A: "Unfortunately, our wedding ceremony will be limited to immediate family only. However, we would love if you could attend virtually and watch it with us!"
    //     },
    //     {
    //         Q: "Can I share some words with Erin & Norris after the ceremony?",
    //         A: "Yes, we’d love to hear from you! Feel free to sign our virtual guestbook on the 'watch' page."
    //     },
    //     {
    //         Q: "When will I receive more details on the wedding reception?",
    //         A: "We will share more information in the coming weeks via email. We can’t wait to celebrate with you all!"
    //     },
    //     {
    //         Q: "Is there a gifts registry?",
    //         A: "If you would like to support us with a gift, we would be very thankful and will be accepting gifts closer to the reception date. There will be more information provided about the gifts registry in 2023."
    //     }
    // ]


    // const qaList = qa.map(list => (
    //     <>
    //         <b>{list.Q}</b>
    //         <p class='mb-6'>{list.A}</p>
    //     </>
    // ))

    
  return (
    <div id="QandA">
        <MainSection name="QandA" title="Q &amp; A" titleHeader lineAbove>
            {/* Q and A text */}
            {/* <div class="2xs:text-sm sm:text-lg 2xs:mx-2 sm:mx-0">
                {qaList}
            </div> */}

            {/* Q and A reception - hardcoded text */}
            <div class="text-gray-800 2xs:text-[16px] 2xs:mx-2 sm:mx-[4rem] lg:mx-[8rem]">
                <b class="font-[500]">When is the wedding reception and what time does it start?</b>
                <p class='font-[300] mt-1 mb-14'>Our wedding reception is happening on April 30, 2023 at Ricarda's Restaurant. The event will start with a cocktail hour at 5:30pm, followed by dinner at 6:30pm.</p>

                <b class="font-[500]">When should I RSVP?</b>
                <p class='font-[300] mt-1 mb-14'>Please RSVP by {RSVP_DEADLINE}, 2023 on the <RouterLink to="/rsvp"><span class="text-[0.95rem] text-gray-500 hover:text-gray-300 duration-300">RSVP</span></RouterLink> page.</p>

                <b class="font-[500]">Can I bring a guest to the event?</b>
                <p class='font-[300] mt-1 mb-14'>We have a limited number of plus ones due to limited seating at the restaurant. If your personal invitation mentions a plus one, please reference the email for more details.</p>

                <b class="font-[500]">Can I bring my kids?</b>
                <p class='font-[300] mt-1 mb-14'>Due to limited space at the venue, this event will be adult-only.</p>

                <b class="font-[500]">Is there parking at the restaurant?</b>
                <p class='font-[300] mt-1 mb-4'>There is no parking on-site, however there are a few paid public parking lots that are close by:</p>
                
                <p class='font-[300] mt-1 mb-0 ml-4'>• <a href="https://goo.gl/maps/K8nU5QS8bP8orbYJ8"><span class=" text-[1rem] hover:text-gray-300 duration-300 hover:cursor-pointer underline">RioCan Hall</span></a> - 263 Richmond St W, Toronto, ON M5V 3M6 ~$10</p>
                <p class='font-[300] mt-1 mb-0 ml-4'>• <a href="https://en.parkopedia.ca/parking/underground/388_richmond_st_w/m5v/toronto/?country=ca&arriving=202304301730&leaving=202305010100"><span class=" text-[1rem] hover:text-gray-300 duration-300 hover:cursor-pointer underline">Pro Park</span></a> - 388 Richmond St W, Toronto, ON M5V 3P1 ~$10</p>
                <p class='font-[300] mt-1 mb-14 ml-4'>• <a href="https://goo.gl/maps/4FDiFXYBdMB3wekq5"><span class=" text-[1rem] hover:text-gray-300 duration-300 hover:cursor-pointer underline">Green P</span></a> - 10 Soho St, Toronto, ON M5T 1Z6 ~$10</p>

                <b class="font-[500]">What is the dress code?</b>
                <p class='font-[300] mt-1 mb-14'>The dress code for the reception is semi-formal attire.</p>

                <b class="font-[500]">Is there a wedding registry?</b>
                <p class='font-[300] mt-1 mb-14'>We do not have a wedding registry. However, if you would like to support us with a gift, a monetary contribution would be greatly appreciated and would go towards furnishing our home and our honeymoon to Japan. We will be accepting cash or cheques at the reception, or e-transfers sent to <span class="text-[0.95rem] text-gray-500 hover:text-gray-300 duration-300 hover:cursor-pointer" onClick={handleEmailClick}>erinandnorris@gmail.com</span>.</p>
                
                <b class="font-[500]">Will the event be following any COVID-19 protocols?</b>
                <p class='font-[300] mt-1 mb-14'>We are not requiring our guests to wear masks or to take a COVID test. However, we kindly ask that you self-evaluate and refrain from attending if you are symptomatic on the day of the event. If you are no longer able to attend, please contact our wedding coordinator Carmen at 647-544-5203.</p>
            </div>

            {/* Q and A ceremony - hardcoded text */}
            {/* <div class="text-gray-800 2xs:text-[16px] 2xs:mx-2 sm:mx-[4rem] lg:mx-[8rem]"> */}
                {/* <b class="font-[500]">When is the wedding ceremony and reception?</b> */}
                {/* <p class='font-[300] mt-1 mb-14'>The wedding ceremony happened on October 30, 2022. The wedding reception will be happening on April 30, 2023.</p> */}

                {/* <b class="font-[500]">Will I be able to attend the wedding ceremony in person?</b> */}
                {/* <p class='font-[300] mt-1 mb-14'>Our in-person wedding ceremony was limited to just our immediate family, but we would love for you to join our premiere virtually and watch it with us.</p> */}

                {/* <b class="font-[500]">How do I tune into the wedding ceremony premiere?</b> */}
                {/* <p class='font-[300] mt-1 mb-14'>The recording of our wedding ceremony will air on November 11, 2022 at 8:00pm EST. Please visit the <span class="text-[0.9rem]">WATCH</span> page on the day of the premiere.</p> */}
                {/* <p class='font-[300] mt-1 mb-14'>Please visit the <RouterLink to="/watch"><span class="hover:text-gray-500 text-[0.9rem]">WATCH</span></RouterLink> page on the day of the premiere. The recording of our wedding ceremony will air on November 11, 2022 at 8:00pm EST.</p> */}

                {/* <b class="font-[500]">Can I share some words with Erin & Norris after the ceremony?</b> */}
                {/* <p class='font-[300] mt-1 mb-14'>Yes, we would love to hear from you! Feel free to sign our virtual guestbook on the <span class="text-[0.9rem]">WATCH</span> page.</p> */}
                {/* <p class='font-[300] mt-1 mb-14'>Yes, we would love to hear from you! Feel free to sign our virtual guestbook on the <RouterLink to="/watch"><span class="hover:text-gray-500 text-[0.9rem]">WATCH</span></RouterLink> page.</p> */}

                {/* <b class="font-[500]">I can't make it to the wedding ceremony premiere. Can I watch the video another time?</b> */}
                {/* <p class='font-[300] mt-1 mb-14'>The video will be available to replay after the premiere ends. You can visit the <span class="text-[0.9rem]">WATCH</span> page at any time to rewatch it.</p> */}
                {/* <p class='font-[300] mt-1 mb-14'>Yes! The video will be available to replay after the premiere ends. You can visit the <RouterLink to="/watch"><span class="hover:text-gray-500 text-[0.9rem]">WATCH</span></RouterLink> page at any time to rewatch it.</p> */}

                {/* <b class="font-[500]">When will I receive more details about the wedding reception?</b> */}
                {/* <p class='font-[300] mt-1 mb-14'>We will share more information in the coming weeks via email. We can't wait to celebrate with you all!</p> */}
                {/*  */}
                {/* <b class="font-[500]">Is there a wedding registry?</b> */}
                {/* <p class='font-[300] mt-1 mb-14'>If you would like to support us with a gift, we will be accepting them closer to the reception. Thank you!</p> */}
            {/* </div> */}

            <Snackbar 
                open={copyAlert} 
                onClose={handleCopyAlertClose} 
                autoHideDuration={5000} 
                anchorOrigin={{vertical: 'bottom', horizontal: 'center'}}
            >
                <Alert severity='info'>Copied to clipboard</Alert>
            </Snackbar>
        </MainSection>
    </div>
  )
}
