import { Input } from '@mui/icons-material';
import axios from 'axios';
const axiosInstance = axios.create({baseURL: process.env.REACT_APP_API_URL});

/******************** GET REQUESTS ********************/
export const getPremiereStarted = async () => {
    try {
        let res = {};
        res = await axiosInstance.get('/stats/premiere-started');
        return res.data;
    } catch (err) {
        console.log(err);
        return [];
    }
}

/******************** POST REQUESTS ********************/


/******************** PUT REQUESTS ********************/
export const updatePremiereStarted = async (booleanInput) => {
    try {
        await axiosInstance.put(`/stats/update/premiere-started`, {
            premiereStarted: booleanInput,
        });
    } catch (err) {
        console.log(err);
        return [];
    }
};
