import React from 'react'
import { Link as ScrollLink } from "react-scroll";
import { MenuItem } from '@mui/material';


const NavBarLinkScroll = ({ children, to, onClick, disabled, handleClose, changeUrlTo }) => {

  const handleClick = () => {
    handleClose()
    changeUrlTo && window.history.replaceState(null, document.title, changeUrlTo)
  }

  return (
    <ScrollLink 
      to={to} spy={true} smooth={true} offset={0} delay={0.8} duration={350} onClick={handleClick}
    >
      {
        disabled
          ? <button disabled class="text-gray-800 font-[300] py-2 px-8 rounded-md cursor-pointer min-w-max w-full">{children}</button>
          : <button class="duration-500 hover:text-nav_hovertext hover:bg-nav_hoverbg text-darkgraytext font-[300] py-2 px-8 rounded-md cursor-pointer min-w-max w-full">{children}</button> 
      }
    </ScrollLink>
  )
}

export default NavBarLinkScroll