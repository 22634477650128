import React from 'react'

export const RsvpTitle = () => {
  return (
    <>
      <div class="select-none flex justify-center items-center 2xs:-mt-5 2xs:mb-0">
      {/* <span class="2xs:mr-1 sm:mr-1"></span> */}

      <p class="2xs:text-[3.15rem] 2xs-280:text-[2.75rem] 2xs-360:text-[3.75rem] md:text-[4.25rem] lg:text-[4.60rem] font-rainbowBeauty">
        <span class="font-rainbowBeauty 2xs:text-[3.49rem] 2xs-360:text-[3.99rem] md:text-[4.49rem] lg:text-[4.84rem]">
          R
        </span>eception
        <span class="font-rainbowBeauty 2xs:ml-0 sm:ml-3">
          <span class="font-rainbowBeauty 2xs:text-[3.49rem] 2xs-360:text-[3.99rem] md:text-[4.49rem] lg:text-[4.84rem]">
             R
          </span>SVP
        </span>
      </p>

      {/* <div class="2xs:mr-0 sm:mr-3">
        <div class="2xs:hidden sm:inline-flex">
          <IconButton onClick={handleClose} sx={{ padding: 0.75 }}>
            <Close sx={{ fontSize: 20 }} />
          </IconButton>
        </div>
      </div> */}

    </div>
    </>
  )
}