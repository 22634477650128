import React, { useState, useEffect } from 'react'
import { Field, Form, Formik } from 'formik';
import * as yup from 'yup';
import { addPost, getPosts } from '../Services/PostService';
import { Paper, Typography, TextField, Button, Avatar, IconButton, duration } from '@mui/material';
// import { Guestbook_AddButton } from '../Subcomponents/Guestbook_AddButton';
import { TransitionGroup, CSSTransition } from "react-transition-group";
import { SIGNGUESTBOOK_BTN_TEXTCOLOR, SIGNGUESTBOOK_BTN_BGCOLOR, SIGNGUESTBOOK_BTN_BGCOLOR_HOVER, SIGNGUESTBOOK_BGCOLOR, SIGNGUESTBOOK_PWLABEL_TEXTCOLOR, SIGNGUESTBOOK_PWBOX_BORDERCOLOR, SIGNGUESTBOOK_PWBOX_BORDERCOLOR_HOVER, SIGNGUESTBOOK_PWBOX_BORDERCOLOR_FOCUSED, SIGNGUESTBOOK_TEXTCOLOR, SIGNGUESTBOOK_ICONCOLOR, SIGNGUESTBOOK_ICONBGCOLOR } from '../constants';
import { Close } from '@mui/icons-material';
import useSound from 'use-sound';
import upBeat from '../Sounds/upBeat.mp3'

export const Guestbook_Form = ({ setPosts, setRequestsLoaded, handleClose, postState, setPostState, handleThanksOpen }) => {
  const [playUpBeat] = useSound(upBeat, {volume: 0.1})

  // useEffect(() => { 
  //   getPosts().then(p => {
  //     setRequestsLoaded(true);
  //     setPosts(p)
  //   });
  // }, [])

  const _initialValues = {
    author: '',
    content: ''
  };

  const _validationSchema = yup.object({
    author: yup
        .string('Enter your name')
        .required(''),
    content: yup
        .string('Enter your message')
        .required(''),
  });

  const trimSpaces = (e) => {
    let str = e.currentTarget.value.trim();
    return str;
  }

  const capitalizeFirstLetter = (e) => {
    let str = e.currentTarget.value.toLowerCase().trim();
    let strArr = str.split(' ')
    let name;
    if(strArr.length === 1) {
        name = strArr[0].charAt(0).toUpperCase() + strArr[0].slice(1)
    } else if(strArr.length === 2) {
        name = strArr[0].charAt(0).toUpperCase() + strArr[0].slice(1) + ' ' + strArr[1].charAt(0).toUpperCase() + strArr[1].slice(1)
    } else if(strArr.length === 3) {
        name = strArr[0].charAt(0).toUpperCase() + strArr[0].slice(1) + ' ' + strArr[1] + ' ' + strArr[2].charAt(0).toUpperCase() + strArr[2].slice(1)
    } else {
      name = str[0].toUpperCase() + str.slice(1, str.length);
    }

    return name;
}

  return (
    <TransitionGroup class="animate-fadeIn">
      <CSSTransition timeout={0}>
          <Formik
            initialValues = {_initialValues}
            validationSchema = {_validationSchema}
            onSubmit = {(values, onSubmit) => {
                  addPost(values).then(() => {
                    getPosts().then(p => setPosts(p));
                  });
                  handleClose();
                  setPostState(!postState);
                  onSubmit.resetForm();
                  handleThanksOpen();
                  playUpBeat()

                  // window.scrollTo(0,0);
                  // setBookingAlert(true);
            }}
          >
            {formik => (
              <Form>
                  {/********************* SIGN GUESTBOOK *********************/}
                  <Paper elevation={8} sx={{ paddingY: 2, paddingX: 1, bgcolor: SIGNGUESTBOOK_BGCOLOR }}>

                    {/* Row 0 - Close Button Mobile*/}
                    <div class="flex justify-end items-center 2xs:-mt-4 sm:-mt-1 2xs:-mr-0 sm:-mr-1">
                      <div class="2xs:-mr-2 sm:mr-3">
                        <IconButton onClick={handleClose} sx={{ padding: 0.75 }}>
                          <Close sx={{ fontSize: 20 }} />
                        </IconButton>
                      </div>
                    </div>

                    {/* Row 1 */}
                    <div class="flex justify-center items-center 2xs:-mt-5 2xs:mb-0 sm:mb-8">
                      {/* <span class="2xs:mr-1 sm:mr-1"></span> */}

                      <p class="2xs:text-[3.25rem] 2xs-360:text-[3.75rem] md:text-[4.25rem] lg:text-[4.60rem] font-rainbowBeauty">
                        <span class="font-rainbowBeauty 2xs:text-[3.49rem] 2xs-360:text-[3.99rem] md:text-[4.49rem] lg:text-[4.84rem]">E</span>rin +
                        <span class="font-rainbowBeauty 2xs:text-[3.49rem] 2xs-360:text-[3.99rem] md:text-[4.49rem] lg:text-[4.84rem] 2xs:ml-1 lg:ml-2">N</span>orris'
                        <span class="font-rainbowBeauty 2xs:hidden sm:inline-block 2xs:ml-0 sm:ml-3">
                          <span class="font-rainbowBeauty 2xs:text-[3.49rem] 2xs-360:text-[3.99rem] md:text-[4.49rem] lg:text-[4.84rem]"> G</span>uestbook
                        </span>
                      </p>

                      {/* <div class="2xs:mr-0 sm:mr-3">
                        <div class="2xs:hidden sm:inline-flex">
                          <IconButton onClick={handleClose} sx={{ padding: 0.75 }}>
                            <Close sx={{ fontSize: 20 }} />
                          </IconButton>
                        </div>
                      </div> */}

                    </div>

                    {/* Row 2 */}
                    <div class="flex justify-center items-center 2xs:-mt-9 2xs:mb-0 sm:-mb-2">
                      <span class="2xs:-ml-2"></span>

                      <p class="2xs:text-[3.75rem] md:text-[3.75rem] lg:text-[3.75rem] font-rainbowBeauty sm:hidden">
                        <span class="font-rainbowBeauty 2xs:text-[3.99rem]"></span>
                      </p>
                    </div>
                    
                    
                    {/* Name Text Field */}
                    <div class="2xs:p-3 sm:p-5 flex 2xs:space-x-4 sm:space-x-6">
                      <Avatar sx={{ color:SIGNGUESTBOOK_ICONCOLOR, bgcolor: SIGNGUESTBOOK_ICONBGCOLOR, height: 54, width: 54, margin: 0.1 }}>{formik.values.author[0]}</Avatar>
                      <Field
                          fullWidth
                          label="Name(s)"
                          placeholder="Michael &amp; Jan"
                          name="author"
                          type="input"
                          onBlur={e => {
                              formik.handleBlur(e)
                              formik.values.author = capitalizeFirstLetter(e)
                          }}
                          as={TextField}
                          error={formik.touched.author && Boolean(formik.errors.author)}
                          helperText={formik.touched.author && formik.errors.author}
                          // inputProps={{ className: classes.form_input }}
                          variant="outlined"
                          sx={{
                            '& .MuiFormLabel-root': {fontFamily: "Inter", fontSize: 15},
                            '& label.Mui-focused': {color: SIGNGUESTBOOK_PWLABEL_TEXTCOLOR},
                            '& .MuiInput-underline:after': {borderBottomColor: SIGNGUESTBOOK_PWLABEL_TEXTCOLOR},
                            '& .MuiOutlinedInput-root': {
                              '& fieldset': {
                                borderColor: SIGNGUESTBOOK_PWBOX_BORDERCOLOR,
                              },
                              '&:hover fieldset': {
                                borderColor: SIGNGUESTBOOK_PWBOX_BORDERCOLOR_HOVER,
                              },
                              '&.Mui-focused fieldset': {
                                borderColor: SIGNGUESTBOOK_PWBOX_BORDERCOLOR_FOCUSED,
                              },
                            },
                            '& .MuiInputBase-input': {
                              color: SIGNGUESTBOOK_TEXTCOLOR,
                              fontSize: 14,
                              fontFamily: "Inter"
                            },
                            marginBottom: 0,
                            
                          }}
                      />
                    </div>

                    {/* Browser Message Field */}
                    <div class="py-5 px-5 2xs:hidden sm:block">
                      <Field
                          fullWidth
                          label="Message"
                          // placeholder="content"
                          name="content"
                          type="input"
                          onBlur={e => {
                            formik.handleBlur(e)
                            formik.values.content = trimSpaces(e)
                          }}
                          as={TextField}
                          error={formik.touched.content && Boolean(formik.errors.content)}
                          helperText={formik.touched.content && formik.errors.content}
                          // inputProps={{ className: classes.form_input }}
                          variant="outlined"
                          multiline
                          rows={6}
                          sx={{
                            '& .MuiFormLabel-root': {fontFamily: "Inter", fontSize: 15},
                            '& label.Mui-focused': {color: SIGNGUESTBOOK_PWLABEL_TEXTCOLOR},
                            '& .MuiInput-underline:after': {borderBottomColor: SIGNGUESTBOOK_PWLABEL_TEXTCOLOR},
                            '& .MuiOutlinedInput-root': {
                              '& fieldset': {
                                borderColor: SIGNGUESTBOOK_PWBOX_BORDERCOLOR,
                              },
                              '&:hover fieldset': {
                                borderColor: SIGNGUESTBOOK_PWBOX_BORDERCOLOR_HOVER,
                              },
                              '&.Mui-focused fieldset': {
                                borderColor: SIGNGUESTBOOK_PWBOX_BORDERCOLOR_FOCUSED,
                              },
                            },
                            '& .MuiInputBase-input': {
                              color: SIGNGUESTBOOK_TEXTCOLOR,
                              fontSize: 14,
                              fontFamily: "Inter"
                            },

                            marginBottom: 0,
                          }}
                      />
                    </div>

                    {/* Mobile Message Field */}
                    <div class="p-3 sm:hidden">
                      <Field
                          fullWidth
                          label="Message"
                          // placeholder="content"
                          name="content"
                          type="input"
                          onBlur={e => {
                            formik.handleBlur(e)
                            formik.values.content = trimSpaces(e)
                          }}
                          as={TextField}
                          error={formik.touched.content && Boolean(formik.errors.content)}
                          helperText={formik.touched.content && formik.errors.content}
                          // inputProps={{ className: classes.form_input }}
                          variant="outlined"
                          multiline
                          rows={5}
                          sx={{
                            '& .MuiFormLabel-root': {fontFamily: "Inter", fontSize: 14},
                            '& label.Mui-focused': {color: SIGNGUESTBOOK_PWLABEL_TEXTCOLOR},
                            '& .MuiInput-underline:after': {borderBottomColor: SIGNGUESTBOOK_PWLABEL_TEXTCOLOR},
                            '& .MuiOutlinedInput-root': {
                              '& fieldset': {
                                borderColor: SIGNGUESTBOOK_PWBOX_BORDERCOLOR,
                              },
                              '&:hover fieldset': {
                                borderColor: SIGNGUESTBOOK_PWBOX_BORDERCOLOR_HOVER,
                              },
                              '&.Mui-focused fieldset': {
                                borderColor: SIGNGUESTBOOK_PWBOX_BORDERCOLOR_FOCUSED,
                              },
                            },
                            '& .MuiInputBase-input': {
                              color: SIGNGUESTBOOK_TEXTCOLOR,
                              fontSize: 14,
                            },
                            marginBottom: 0,
                            
                          }}
                      />
                    </div>

                    <br/>
                    
                    {/* Sign Guestbook Button */}
                    <div class="flex justify-center items-center -mt-2">
                      <Button sx={{ fontSize: 13, fontFamily:'Inter', color: SIGNGUESTBOOK_BTN_TEXTCOLOR, bgcolor: SIGNGUESTBOOK_BTN_BGCOLOR, '&:hover': {bgcolor: SIGNGUESTBOOK_BTN_BGCOLOR_HOVER, scale: '1.01'}, transition: '400ms', padding: 1.5, width: '92%' }} disabled={!formik.isValid || !formik.dirty} color="primary" variant="contained" fullWidth type="submit" >
                          Sign Guestbook
                      </Button>
                    </div>

                  </Paper>

                  {/* {(!formik.isValid) && <Typography className={classes.submitButtonDescriptionBelow}>You must complete the entire form to post a message</Typography>} */}
              </Form>
            )}
          </Formik>
      </CSSTransition>
    </TransitionGroup>
  )
}
