import React, { useState, useContext, useEffect } from 'react'
import { Menu, MenuItem, Button, Avatar, IconButton } from '@mui/material';
import NavBarLinkScroll from '../Subcomponents/NavBarLinkScroll';
import Hamburger from 'hamburger-react'
import NavBarLinkSwitch from '../Subcomponents/NavBarLinkSwitch';
import NavBarLinkSwitchSpecial from '../Subcomponents/NavBarLinkSwitchSpecial';
import { AuthContext } from "../Context/AuthProvider";
import { THM_ALMOSTGRAY } from '../constants';


export const Navbar = ({ browser, mobile, homePage, watchPage, rsvpPage, adminPage }) => {
  const { auth, setAuth } = useContext(AuthContext);
  const [isOpen, setOpen] = useState(false)
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

    // function HideOnScroll(props) {
    //     const { children, window } = props;
    //     const trigger = useScrollTrigger({ target: window ? window() : undefined });
      
    //     return (
    //       <Slide appear={false} direction="down" in={!trigger} timeout={75}>
    //         {children}
    //       </Slide>
    //     );
    // }

  const handleClick = (event) => {
      setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
      setAnchorEl(null);
      setOpen(false);
      // console.log(window.history)
      // window.history.replaceState(null, document.title, changeUrlTo)
  };

  return (
    <div class="2xs:mx-2 sm:mx-10 md:mx-20 lg:mx-52 xl:mx-[20rem] 2xl:mx-[28rem] 3xl:mx-[26rem] 4xl:mx-[40rem] 4k:mx-[52rem] my-0 justify-center items-center animate-fadeInSlow">
      { (auth.level >= 2) && 
        <div id='main-nav' class='ml-0 2xs:mr-0 sm:mr-0 2xs:mt-4 sm:mt-4 2xs:mb-1 sm:mb-4 xl:mb-6 flex 2xs:justify-end sm:justify-center items-center'>
          
          {/* BROWSER navigation bar */}
          { browser &&
              <div id='right-nav' class='flex 2xs:gap-x-4 sm:gap-x-4 md:gap-x-10 lg:gap-x-16 xl:gap-x-20 2xl:gap-x-24 2xs:hidden sm:inline-flex 2xs:text-sm lg:text-md xl:text-lg font-[300]'>
                  { homePage && <NavBarLinkScroll to="QandA" changeUrlTo="/#QandA" handleClose={handleClose}>Q&amp;A</NavBarLinkScroll> }
                  { homePage && <NavBarLinkScroll to="AboutUs" changeUrlTo="/#AboutUs" handleClose={handleClose}>ABOUT US</NavBarLinkScroll> }
                  { homePage && <NavBarLinkScroll to="Gallery" changeUrlTo="/#Gallery" handleClose={handleClose}>GALLERY</NavBarLinkScroll> }
                  { homePage && <NavBarLinkSwitch to="/watch">WATCH</NavBarLinkSwitch> }
                  { homePage && <NavBarLinkSwitchSpecial to="/rsvp">RSVP</NavBarLinkSwitchSpecial> }
              
                  { watchPage && <NavBarLinkSwitch to="/home">HOME</NavBarLinkSwitch> }
                  { watchPage && <NavBarLinkSwitch to="/#QandA">Q&amp;A</NavBarLinkSwitch> }
                  { watchPage && <NavBarLinkSwitch to="/#AboutUs">ABOUT US</NavBarLinkSwitch> }
                  { watchPage && <NavBarLinkSwitch to="/#Gallery">GALLERY</NavBarLinkSwitch> }
                  { watchPage && <NavBarLinkSwitchSpecial to="/rsvp">RSVP</NavBarLinkSwitchSpecial> }
                  {/* { watchPage && <NavBarLinkSwitch disabled to="/watch">WATCH</NavBarLinkSwitch> } */}
                  {/* { watchPage && <NavBarLinkSwitch to="/home">Home</NavBarLinkSwitch> } */}

                  { rsvpPage && <NavBarLinkSwitch to="/home">HOME</NavBarLinkSwitch> }
                  { rsvpPage && <NavBarLinkSwitch to="/#QandA">Q&amp;A</NavBarLinkSwitch> }
                  { rsvpPage && <NavBarLinkSwitch to="/#AboutUs">ABOUT US</NavBarLinkSwitch> }
                  { rsvpPage && <NavBarLinkSwitch to="/#Gallery">GALLERY</NavBarLinkSwitch> }
                  { rsvpPage && <NavBarLinkSwitch to="/watch">WATCH</NavBarLinkSwitch> }

                  { adminPage && <NavBarLinkSwitch to="/home">HOME</NavBarLinkSwitch> }
                  { adminPage && <NavBarLinkSwitch to="/#QandA">Q&amp;A</NavBarLinkSwitch> }
                  { adminPage && <NavBarLinkSwitch to="/#AboutUs">ABOUT US</NavBarLinkSwitch> }
                  { adminPage && <NavBarLinkSwitch to="/#Gallery">GALLERY</NavBarLinkSwitch> }
                  { adminPage && <NavBarLinkSwitch to="/watch">WATCH</NavBarLinkSwitch> }
                  { adminPage && <NavBarLinkSwitchSpecial to="/rsvp">RSVP</NavBarLinkSwitchSpecial> }

              </div>
          }

          {/* MOBILE navigation bar */}
          { mobile &&
              <div id='right-nav' class='2xs:inline-flex sm:hidden animate-fadeInSlow 2xs:mb-[0.50rem] 2xs-360:mb-[0.25rem] 2xs-375:mb-[0.75rem] 2xs-390:mb-[0.75rem] 2xs-412:mb-[1rem]'>

                {/* Hamburger Menu */}
                <div>
                  <Button
                    id="basic-button"
                    aria-controls={open ? 'basic-menu' : undefined}
                    aria-haspopup="true"
                    aria-expanded={open ? 'true' : undefined}
                    // fullWidth
                    onClick={handleClick}
                    style={{ backgroundColor: 'transparent', color: THM_ALMOSTGRAY, padding: '0px 1px' }}
                    disableRipple
                  >
                    <Hamburger toggled={isOpen} toggle={setOpen} />
                  </Button>

                  <Menu
                    id="basic-menu"
                    anchorEl={anchorEl}
                    open={open}
                    onClose={handleClose}
                    MenuListProps={{
                      'aria-labelledby': 'basic-button',
                    }}
                    // sx={{'& .MuiMenuItem-root': {padding: 0}}}
                  >
                    { homePage && <MenuItem class='px-0'><NavBarLinkScroll to="QandA" changeUrlTo="/#QandA" handleClose={handleClose}>Q&amp;A</NavBarLinkScroll></MenuItem> }
                    { homePage && <MenuItem class='px-0'><NavBarLinkScroll to="AboutUs" changeUrlTo="/#AboutUs" handleClose={handleClose}>ABOUT US</NavBarLinkScroll></MenuItem> }
                    { homePage && <MenuItem class='px-0'><NavBarLinkScroll to="Gallery" changeUrlTo="/#Gallery" handleClose={handleClose}>GALLERY</NavBarLinkScroll></MenuItem> }
                    { homePage && <MenuItem class='px-0'><NavBarLinkSwitch to="/watch">WATCH</NavBarLinkSwitch></MenuItem> }
                    { homePage && <MenuItem class='px-0'><NavBarLinkSwitch to="/rsvp">RSVP</NavBarLinkSwitch></MenuItem> }
                    
                    { watchPage && <MenuItem class='px-0'><NavBarLinkSwitch to="/home">HOME</NavBarLinkSwitch></MenuItem> }
                    { watchPage && <MenuItem class='px-0'><NavBarLinkSwitch to="/#QandA">Q&amp;A</NavBarLinkSwitch></MenuItem> }
                    { watchPage && <MenuItem class='px-0'><NavBarLinkSwitch to="/#AboutUs">ABOUT US</NavBarLinkSwitch></MenuItem> }
                    { watchPage && <MenuItem class='px-0'><NavBarLinkSwitch to="/#Gallery">GALLERY</NavBarLinkSwitch></MenuItem> }
                    { watchPage && <MenuItem class='px-0'><NavBarLinkSwitch to="/rsvp">RSVP</NavBarLinkSwitch></MenuItem> }
                    {/* { watchPage && <MenuItem><NavBarLinkSwitch to="/watch" disabled>Watch</NavBarLinkSwitch></MenuItem> } */}

                    { rsvpPage && <MenuItem class='px-0'><NavBarLinkSwitch to="/home">HOME</NavBarLinkSwitch></MenuItem> }
                    { rsvpPage && <MenuItem class='px-0'><NavBarLinkSwitch to="/#QandA">Q&amp;A</NavBarLinkSwitch></MenuItem> }
                    { rsvpPage && <MenuItem class='px-0'><NavBarLinkSwitch to="/#AboutUs">ABOUT US</NavBarLinkSwitch></MenuItem> }
                    { rsvpPage && <MenuItem class='px-0'><NavBarLinkSwitch to="/#Gallery">GALLERY</NavBarLinkSwitch></MenuItem> }
                    { rsvpPage && <MenuItem class='px-0'><NavBarLinkSwitch to="/watch">WATCH</NavBarLinkSwitch></MenuItem> }
                  </Menu>
                </div>
              </div>
          }

        </div>
      }
    </div>
  )
}
