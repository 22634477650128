/* eslint-disable react/jsx-pascal-case */
import React, { useState, useEffect } from 'react'
import MainSection from '../Subcomponents/MainSection'
import { Button, IconButton, Paper } from '@mui/material';
import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material';
import { ChevronLeft, ChevronRight, Close, KeyboardArrowLeft, KeyboardArrowRight, Cached } from '@mui/icons-material';
import PostCard from '../Subcomponents/PostCard';
import { samplePosts } from '../samplePosts'
import { getPosts, addPost } from '../Services/PostService';
import '../index.css';
import Button_SignGuestbook from '../Subcomponents/Button_SignGuestbook';
import { TransitionGroup, CSSTransition } from "react-transition-group";
import { Guestbook_Form } from '../Subcomponents/Guestbook_Form';


export const Guestbook = ({ posts, handleRefreshPosts, setPosts, requestsLoaded, admin, skeleton }) => {
  const postsPerPage = 10;
  const [postsToShow, setPostsToShow]  = useState([]);
  const [currINDEX, setCurrINDEX] = useState(0);
  const [open, setOpen] = useState(false);
  const [thanksOpen, setThanksOpen] = useState(false);
  const [postState, setPostState] = useState(false);
  
  useEffect(() => {
    getPosts().then(p => setPosts(p));
  }, [postState])

  const handleOpen = (i) => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleThanksOpen = () => {
    setThanksOpen(true);
  }

  const handleThanksClose = () => {
    setThanksOpen(false);
  }


  // const handleShowMorePosts = () => {
  //   let toShow = posts.slice(postsToShow.length, postsToShow.length + postsPerPage);
  //   setPostsToShow([...postsToShow, ...toShow]);
  // }


  return (
    <MainSection name="Guestbook" title="" lineAbove={false}>

      {/***** Popups *****/}
      {/* Sign Guestbook Popup */}
      <Dialog
        fullWidth
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <Guestbook_Form handleClose={handleClose} handleThanksOpen={handleThanksOpen} setPosts={setPosts} postState={postState} setPostState={setPostState}/>
      </Dialog>

      {/* Thanks Popup */}
      <Dialog
        fullWidth
        open={thanksOpen}
        onClose={handleThanksClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <Paper class="flex flex-col justify-center items-center shadow-2xl border-1 border-gray-200 2xs:-mt-3 2xs:pb-2 xs:pb-3 md:pb-1 2xs:h-[7rem] sm:h-[7.5rem] 2xs:text-sm xs:text-base md:text-lg font-[300] bg-[#FAFAFA] text-[#1F2937] animate-fadeIn">
          <div class="flex justify-end items-center h-1/6 w-full mr-3">
            <IconButton onClick={handleThanksClose} sx={{ padding: 0.5 }}>
              <Close sx={{ fontSize: 18 }} />
            </IconButton>
          </div>

          <div class="2xs:ml-4 sm:ml-0 mr-2 2xs:mt-3 md:mt-0 2xs:px-8 sm:px-18 md:px-36">Thanks for signing our guestbook, that really means a lot to us!!! 🙂</div>
        </Paper>
      </Dialog>

        {/***** Guestbook Section *****/}
        <section name="Guestbook Section" class="p-0 my-0 2xs:mx-0 lg:mx-4 2xl:mx-16 text-gray-700 animate-fadeInSlow">
            <div class="flex flex-wrap xs:m-2">

              <div class="flex flex-row justify-center items-center w-full">
                {/* Sign Guestbook Button */}
                <Button_SignGuestbook handleOpen={handleOpen} handleClose={handleClose}/>

                {/* Refresh Button */}
                { admin && <IconButton onClick={() => getPosts().then(p => {setPosts(p);})}><Cached /></IconButton> }
              </div>

              {/* Guestbook Posts */}
              <div class="w-full overflow-y-auto overflow-x-hidden max-h-[48rem] px-1 mt-1">
                <div class="2xs:-my-1 lg:-my-2 2xs:text-sm sm:text-base">
                  { requestsLoaded && posts.map((p, index) => (
                    <PostCard key={index} posts={p} handleRefreshPosts={handleRefreshPosts} author={p.author} content={p.content} postState={postState} admin={admin} skeleton />)) 
                  }
                </div>
              </div>



              {/* Show More, does not update when adding */}
              {/* { requestsLoaded && postsToShow.map((p, index) => (
              <PostCard key={index} posts={p} author={p.author} content={p.content} />)) }

              {(postsToShow.length !== posts.length) && <Button onClick={handleShowMorePosts}>Show More</Button>} */}
            </div>
        </section>

    </MainSection>
  )
}
