import React from 'react'
import { TransitionGroup } from "react-transition-group";

export const RsvpDivContainer = ({ children }) => {
  return (
    <>
        <TransitionGroup 
            class=" animate-fadeIn 
                    2xs:mx-[1rem] xs:mx-[2.5rem] sm:mx-[5rem] md:mx-[7rem] lg:mx-[12rem] xl:mx-[15rem] 2xl:mx-[22rem] 3xl:mx-[28rem] 
                    2xs:mt-[4.25rem] 2xs-375:mt-[5rem] 2xs-390:mt-[6.5rem] 2xs-412:mt-[8rem] xs:mt-16 md:mt-20
                    2xs:mb-8 2xs-390:mb-8 xs:mb-16 md:mb-20
                    ">
            <div class="">
            { children }
            </div>
        </TransitionGroup>
    </>
  )
}