import React from 'react'

const GalleryImage = ({ src, alt, onClick }) => {
  return (
    <div class="flex flex-wrap 2xs:w-1/2 xs:w-1/4 opacity-100 hover:opacity-60 hover:scale-[1.02] transition duration-300 ease-in-out cursor-pointer" onClick={onClick} >
      <div class="w-full p-1 md:p-2">
        <img alt={alt} class="block object-cover object-center w-full h-full rounded-sm shadow-lg"
          src={`${process.env.PUBLIC_URL}/assets/images/${src}`} referrerPolicy="no-referrer"/>
      </div>
    </div>
  )
}

export default GalleryImage