import React from 'react'
import Countdown from 'react-countdown';
import { Link as RouterLink } from "react-router-dom";
import { animateScroll as scroll } from "react-scroll";
import { updatePremiereStarted } from '../Services/StatService';


export const CountdownTimer = ({ fromWatchPage, premiereStarted, setPremiereStarted }) => {
  let d = new Date();

  // Countdown to premiere //
  d.setFullYear(2022, (11 - 1), 11); //starts months at 0 instead of 1
  d.setHours(20);
  d.setMinutes(0);
  d.setSeconds(0);

  // Test Time //
  // d.setFullYear(2022, (11 - 1), 6); //starts months at 0 instead of 1
  // d.setHours(18);
  // d.setMinutes(50);
  // d.setSeconds(0);

  // const scrollToTheTop = () => {
  //   scroll.scrollToTop({
  //     delay: 60,
  //     duration: 160,
  //   })
  // }
  const scrollTo2 = () => {
    scroll.scrollTo(120)
  }

  const reload = () => {
    window.location.reload()
  }

  const scrollToTop = (callback) => {
    // scrollToTheTop();
    scrollTo2();
    setTimeout(() => {
      callback();
    }, 300)
  }

  const renderJoinCeremonyButton = (
    fromWatchPage
    ?
    <div class="2xs:w-full sm:w-4/6 2xs:p-1 md:p-2 2xs:mb-1 lg:mb-2 flex flex-row justify-center">
      <button type="button" onClick={() => scrollToTop(reload)} class="flex flex-row justify-center items-center px-6 2xs:pt-2.5 sm:pt-3 pb-2 border-1 border-gray-200 bg-[#707070] text-[#ECEDEF] font-[400] 2xs:text-sm lg:text-md leading-normal uppercase rounded-sm shadow-lg 2xs:w-5/6 lg:w-4/6 2xl:w-3/6 2xs:h-[110%] lg:h-[120%] opacity-100 hover:opacity-80 hover:scale-[1.01] hover:bg-[#B0B0AF] hover:shadow-lg focus:bg-[#808080] focus:shadow-lg focus:outline-none focus:ring-0 transition duration-400 ease-in-out">
      WATCH THE CEREMONY
      </button>
    </div>
    :
    <div class="w-full 2xs:p-1 md:p-2 2xs:mb-1 lg:mb-2 flex flex-row justify-center">
      <RouterLink to="/watch" class="flex flex-row justify-center items-center px-6 2xs:pt-2.5 sm:pt-3 pb-2 border-1 border-gray-200 bg-[#707070] text-[#ECEDEF] font-[400] 2xs:text-sm lg:text-md leading-normal uppercase rounded-sm shadow-lg 2xs:w-5/6 lg:w-4/6 2xl:w-3/6 2xs:h-[110%] lg:h-[120%] opacity-100 hover:opacity-80 hover:scale-[1.01] hover:bg-[#B0B0AF] hover:shadow-lg focus:bg-[#808080] focus:shadow-lg focus:outline-none focus:ring-0 transition duration-400 ease-in-out">
        <button type="button" class="">
          WATCH THE CEREMONY
        </button>
      </RouterLink>
    </div>
  )

  const renderer = ({ days, hours, minutes, seconds, completed }) => {

    if (completed) {
      // Render a completed state
      // fromWatchPage && !premiereStarted && setPremiereStarted(true)
      // fromWatchPage && !premiereStarted && updatePremiereStarted(true)
      return renderJoinCeremonyButton;
    } else {
      // Render a countdown
      return (
        <div>
          <span class="2xs:text-lg sm:text-xl">{days} {(days===1)?"day":"days"}</span>
          <span class="2xs:text-lg sm:text-xl 2xs:pl-2 sm:pl-3">{hours} {(hours===1)?"hour":"hours"}</span>
          <span class="2xs:text-lg sm:text-xl 2xs:pl-2 sm:pl-3">{minutes} {(minutes===1)?"minute":"minutes"}</span>
          <span class="2xs:text-lg sm:text-xl 2xs:pl-2 sm:pl-3">{seconds} {(seconds===1)?"second":"seconds"}</span>
        </div>
      );
    }
  };

  return (
    <>
        <Countdown date={d} renderer={renderer} />
    </>
  )
}
