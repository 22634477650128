import React from 'react'
import { introductionImages } from '../galleryImages'
import { CountdownTimer } from '../Subcomponents/CountdownTimer'

export const Title = () => {

  return (
    <div name="Introduction" class="2xs:mx-2 sm:mx-10 md:mx-20 lg:mx-52 xl:mx-[20rem] 2xl:mx-[28rem] 3xl:mx-[26rem] 4xl:mx-[40rem] 4k:mx-[52rem] my-0 justify-center items-center text-lg animate-fadeInSlow">
        <div class='flex flex-col'>

            {/* Banner Title */}
            <div class='font-rainbowBeauty select-none flex w-full justify-center 2xs:mt-4 2xs-390:mt-5 sm:mt-12 2xl:mt-28 2xs:mb-2 sm:mb-3 2xs:text-[4.0rem] 2xs-280:text-[3.75rem] 2xs-360:text-[4.8rem] 2xs-375:text-[4.85rem] 2xs-390:text-[5.0rem] 2xs-412:text-[5.25rem] sm:text-[5.5rem] md:text-8xl xl:text-[7rem]'>
                Erin + Norris
            </div>

            {/* Banner Description */}
            {/* <div class='flex w-full justify-center 2xs:mb-0 sm:mb-3 2xs:text-[0.80rem] 2xs-390:text-[0.90rem] sm:text-[1.10rem] md:text-[1.35rem] lg:text-[1.45rem] xl:text-[1.65rem]'>
                October 30th, 2022 • Cranberry Creek Gardens
            </div> */}

            {/* Countdown Timer */}
            {/* <div class='flex w-full justify-center mb-3 2xs:text-sm md:text-xl'>
                <CountdownTimer/>
            </div> */}

            {/* Spacer */}
            <div class='2xs:-mb-[0.25rem] 2xs-360:mb-[0.25rem] 2xs-375:mb-[0.25rem] md:-mb-5 lg:-mb-4 xl:-mb-2'></div>
            

            {/* Introduction Text */}
            {/* <div class='flex flex-col justify-center w-2/3'>
                <CustomParagraph>
                    Dear family &amp; friends,
                </CustomParagraph>

                <CustomParagraph>
                    We are so excited to share that we are getting married on October 30, 2022! 
                </CustomParagraph>

                <CustomParagraph>
                    Although we are having a small and intimate ceremony with our immediate family, we would love for you to join us virtually on _______ for a replay of the wedding (we’ll be watching it live with you too!). We hope to see you there ❤️
                </CustomParagraph>

                <CustomParagraph>
                    We are also excited to share that we are hosting our reception on April 30th, 2023. Stay tuned for all of the details, and looking forward to celebrating with you all in person in the near future! 
                </CustomParagraph>

                <CustomParagraph>
                    Sincerely,
                </CustomParagraph>

                <CustomParagraph>
                    Erin &amp; Norris
                </CustomParagraph>
            </div> */}

        </div>
    </div>
  )
}
