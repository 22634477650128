import React, { useState, useEffect } from 'react'
import { getPosts } from '../Services/PostService';
import { Navbar } from '../Components/Navbar'
import MainSection from '../Subcomponents/MainSection'
import { Guestbook } from '../Components/Guestbook';
import { Footer } from '../Components/Footer';
import { Title } from '../Components/Title';
import ReactGA from 'react-ga';
import { CountdownTimer } from '../Subcomponents/CountdownTimer';
import { getPremiereStarted } from '../Services/StatService';
import { galleryImages } from '../galleryImages';



/********** EMBED PREMIERE VIDEO HERE: **********/ 
const EMBED_CODE = "https://www.youtube.com/embed/PhPMq7pkHOU"
// const EMBED_CODE_FILLER = "https://www.youtube.com/embed/REfG2MmTbgw"
// const EMBED_CODE2 = "https://www.youtube.com/embed/B1H-VejFeOs";



export const WatchPage = ({ admin, phTime }) => {
  const [posts, setPosts] = useState({})
  const [requestsLoaded, setRequestsLoaded] = useState(false);
  const [darkMode, setDarkMode] = useState(false);
  const [premiereStarted, setPremiereStarted] = useState(false);

  useEffect(() => { 
    getPremiereStarted().then(x => setPremiereStarted(x))
    
    window.scrollTo(0, 0);

    ReactGA.pageview(window.location.pathname);

    if (localStorage.theme === 'dark') {
      document.documentElement.classList.remove('light');
      document.documentElement.classList.add('dark');
      setDarkMode(true);
    } else {
      document.documentElement.classList.remove('dark');
      document.documentElement.classList.add('light');
    }

    getPosts().then(p => {
      setRequestsLoaded(true);
      setPosts(p)
    });
  }, [])

  // useEffect(() => {
  //   if (localStorage.theme === 'dark' || (!('theme' in localStorage) && window.matchMedia('(prefers-color-scheme: dark)').matches )) {
  //     document.documentElement.classList.add('dark')
  //   } else {
  //     document.documentElement.classList.remove('dark')
  //   }
  // }, [darkMode])

  const handleRefreshPosts = async () => {
    getPosts().then(p => {
      setPosts(p);
    })
  }

  const toggleTheme = () => {
    setDarkMode(!darkMode)
    setTheme(!darkMode);
    console.log('in toggleTheme' + !darkMode)
  }

  const setTheme = (darkMode) => {
    let theme;
    if(darkMode) {
      document.documentElement.classList.remove('light');
      document.documentElement.classList.add('dark');
      theme = 'dark';
    } else {
      document.documentElement.classList.remove('dark');
      document.documentElement.classList.add('light');
      theme = 'light';
    }
    localStorage.theme = theme;
    console.log('in toggleTheme' + darkMode)
    // window.location.reload()
  }



  return (
    <div class="animate-fadeIn dark:bg-gray-200 h-full">
      {/* <button onClick={toggleTheme}>TOGGLE THEME</button> */}

      {/* Navbar and Title */}
      <Navbar mobile watchPage />
      {phTime && <div class="2xs:mt-12 sm:hidden"></div>}
      <Title />
      {phTime && <div class="2xs:mb-4 sm:hidden"></div>}
      <Navbar browser watchPage />

      {/* Default !premiereStarted */}
      {!premiereStarted
        ?
        <MainSection>
          <div class='flex flex-col'>
            {/* Browser Image */}
            <div class='2xs:hidden sm:inline-flex flex w-full 2xs:h-[24rem] sm:h-[22rem] md:h-[30rem] lg:h-[30rem] xl:h-[30rem] 2xl:h-[30rem] justify-center mb-8 animate-fadeInSlow'>
                <img name='browser-image' class='object-cover shadow-lg rounded-sm my-1 xl:my-0 filter saturate-[.9]'
                    src={`${process.env.PUBLIC_URL}/assets/images/${galleryImages[14].src}`}
                    alt={`${process.env.PUBLIC_URL}/assets/images/${galleryImages[14].alt}`}
                    referrerPolicy="no-referrer" />
            </div>

            {/* Mobile Image */}
            <div class='sm:hidden flex w-full 2xs:h-[24rem] 2xs-360:h-[26rem] 2xs-375:h-[26rem] 2xs-390:h-[34.5rem] 2xs-412:h-[36rem] sm:h-[21rem] justify-center mb-6 animate-fadeInSlow'>
                <img name='mobile-image' class='object-cover shadow-lg rounded-sm my-1 xl:my-0'
                    src={`${process.env.PUBLIC_URL}/assets/images/${galleryImages[14].src}`}
                    alt={`${process.env.PUBLIC_URL}/assets/images/${galleryImages[14].alt}`}
                    referrerPolicy="no-referrer" />
            </div>

            {/* Introduction Text */}
            <div class='flex flex-col justify-center w-full text-center font-[300] animate-fadeInSlow'>
              <p class="2xs:mx-0 sm:mx-4 mt-4 font-crimsonText 2xs:text-3xl">
                  Join our wedding virtually!
              </p>

              {phTime
                ?
                <>
                  <p class="text-gray-800 2xs:mx-0 sm:mx-4 mt-3 2xs:text-[1.00rem]">
                    Friday, November 11, 2022 at 8:00pm EST
                  </p>
                  <p class="text-gray-800 2xs:mx-0 sm:mx-4 mb-6 2xs:text-[1.00rem]">
                    Saturday, November 12, 2022 at 9:00am PHT
                  </p>
                </>
                :
                <>
                  <p class="text-gray-800 2xs:mx-0 sm:mx-4 mt-3 2xs:text-[1.05rem]">
                    Friday, November 11, 2022
                  </p>
                  <p class="text-gray-800 2xs:mx-0 sm:mx-4 mb-6 2xs:text-[1.05rem]">
                    Begins at 8:00pm EST
                  </p>
                </>

              }

              {/* Countdown */}
              <div class='text-gray-800 flex justify-center gap-x-2 2xs:text-xl text-center 2xs:mb-6 sm:mb-0'>
                  <CountdownTimer fromWatchPage premiereStarted={premiereStarted} setPremiereStarted={setPremiereStarted}/>
              </div>
            </div>
          </div>
        </MainSection>

        :
        <>
          {/* Premiere Video Section */}
          <MainSection watchPage name="Watch" lineAbove={false} titleHeader>
            <div name='Watch' class='my-2 2xs:mx-0 md:mx-4 2xl:mx-20 p-2 flex justify-center items-center'>
              <div class="w-full aspect-w-16 aspect-h-9 animate-fadeInSlow">
                  <iframe
                  src={EMBED_CODE}
                  frameBorder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowFullScreen
                  title="Erin & Norris' Wedding Ceremony"
                  />
              </div>
            </div>

            {/* Refresh disclaimer */}
            {/* <div class='-mt-2 mb-2 2xs:mx-0 md:mx-4 2xl:mx-20 p-2 flex justify-center items-center'>
              <p class="text-xs text-[#979797]"><i>If the premiere is not loading, please refresh the page.</i></p>
            </div> */}

            <hr class="mt-16"/>
          </MainSection>

          {/* Guestbook Section */}
          <Guestbook posts={posts} handleRefreshPosts={handleRefreshPosts} setPosts={setPosts} requestsLoaded={requestsLoaded} admin={admin}/>
        </>
        }

      {/* Footer Section */}
      <Footer />

    </div>
  )
}
