///// Text Values /////
export const RSVP_DEADLINE = "March 18";
// export const RSVP_DEADLINE = "March 3";


///// Colours /////
export const THM_BEIGE_LIGHTEST = "#FBFBF8";
export const THM_BEIGE_LIGHTER = "#FAF9F3";
export const THM_BEIGE = "#F7F6EA";
export const THM_BEIGEGRAY = "#b2b1b0";
export const THM_BEIGE_DARKER = "#F3F2EB";
export const THM_BEIGE_DARKEST = "#EFEBBB";
export const THM_GOLD_LIGHTER = "#D5CE79";
export const THM_GOLD = "#B0A954";

export const THM_BASICALLYWHITE = "#FAFAFA"
export const THM_ALMOSTWHITE = "#F5F5F5"
export const THM_GRAYISH = "#1F2937" //home page text colours
export const THM_LIGHTERGRAY = "#CDCDCD"
export const THM_LIGHTGRAY = "#BBBBBB"
export const THM_ALMOSTGRAY = "#7B7B7B"
export const THM_CLOSETOGRAY = "#AAAAAA"
export const THM_GRAY = "#999999"
export const THM_DARKGRAY = "#707070"
export const THM_DARKERGRAY = "#383838"


///// Components /////
// LOGIN
export const LOGIN_BGCOLOR = THM_BASICALLYWHITE;

export const LOGIN_PWLABEL_TEXTCOLOR = THM_GRAYISH;
export const LOGIN_PWBOX_BORDERCOLOR = THM_GRAYISH;
export const LOGIN_PWBOX_BORDERCOLOR_HOVER = THM_DARKERGRAY;
export const LOGIN_PWBOX_BORDERCOLOR_FOCUSED = THM_DARKERGRAY;
export const LOGIN_PWLABEL_FONTFAMILY = "Inter";

export const LOGIN_BTN_FONTFAMILY = "Inter";
export const LOGIN_BTN_TEXTCOLOR = THM_ALMOSTWHITE;
export const LOGIN_BTN_BGCOLOR = THM_DARKGRAY;
export const LOGIN_BTN_TEXTCOLOR_HOVER = THM_BEIGE;
export const LOGIN_BTN_BGCOLOR_HOVER = THM_LIGHTGRAY;


// SIGN GUESTBOOK
export const SIGNGUESTBOOK_FONTFAMILY = "Inter";
export const SIGNGUESTBOOK_TEXTCOLOR = THM_DARKERGRAY;
export const SIGNGUESTBOOK_BGCOLOR = THM_BASICALLYWHITE;

export const SIGNGUESTBOOK_ICONCOLOR = THM_BEIGE_LIGHTEST;
export const SIGNGUESTBOOK_ICONBGCOLOR = THM_DARKGRAY;

export const SIGNGUESTBOOK_BTN_TEXTCOLOR = THM_ALMOSTWHITE;
export const SIGNGUESTBOOK_BTN_BGCOLOR = THM_DARKGRAY;
export const SIGNGUESTBOOK_BTN_BGCOLOR_HOVER = THM_LIGHTGRAY;

export const SIGNGUESTBOOK_PWLABEL_TEXTCOLOR = THM_DARKERGRAY;
export const SIGNGUESTBOOK_PWBOX_BORDERCOLOR = THM_DARKERGRAY;
export const SIGNGUESTBOOK_PWBOX_BORDERCOLOR_HOVER = THM_DARKERGRAY;
export const SIGNGUESTBOOK_PWBOX_BORDERCOLOR_FOCUSED = THM_DARKERGRAY;


// GUESTBOOK COMMENTS
export const GUESTBOOKCOMMENTS_BGCOLOR = "white";



// RSVP
export const RSVP_BGCOLOR = THM_BASICALLYWHITE;

export const RSVP_BTNBACK_WIDTH_PG2 = "88%";
export const RSVP_BTNBACK_BGCOLOR = THM_CLOSETOGRAY;
export const RSVP_BTNBACK_BGCOLOR_HOVER = THM_LIGHTGRAY;

export const RSVP_GUESTLIST_BTN_WIDTH = "88%";
export const RSVP_GUESTLIST_BTN_BGCOLOR = THM_DARKGRAY;
// export const RSVP_GUESTLIST_BTN_BGCOLOR = THM_GRAY;
export const RSVP_GUESTLIST_BGCOLOR_HOVER = THM_LIGHTGRAY;
export const RSVP_GUESTLISTEMAILSENT_BTN_BGCOLOR = THM_GRAY;
