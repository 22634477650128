import React from 'react'
import CustomParagraph from '../Subcomponents/CustomParagraph'
import { introductionImages } from '../galleryImages'
import { galleryImages } from '../galleryImages'
import MainSection from '../Subcomponents/MainSection'
import { CountdownTimer } from '../Subcomponents/CountdownTimer'
import { CountdownTimerReception } from '../Subcomponents/CountdownTimerReception'

export const Introduction = () => {

  return (
    <MainSection name="Introduction">
        <div class='flex flex-col'>

            {/* Browser Image */}
            <div class='2xs:hidden sm:inline-flex flex w-full 2xs:h-[32rem] sm:h-[30rem] md:h-[38rem] lg:h-[38rem] xl:h-[38rem] 2xl:h-[38rem] justify-center mb-10 animate-fadeInSlow'>
                <img name='browser-image' class='object-cover shadow-lg rounded-sm my-1 xl:my-0 filter saturate-[.9]'
                    src={`${process.env.PUBLIC_URL}/assets/images/${introductionImages[0].src}`}
                    alt={`${process.env.PUBLIC_URL}/assets/images/${introductionImages[0].alt}`}
                    referrerPolicy="no-referrer" />
            </div>

            {/* Mobile Image */}
            <div class='sm:hidden flex w-full 2xs:h-[29rem] 2xs-360:h-[31rem] 2xs-375:h-[31rem] 2xs-390:h-[39.5rem] 2xs-412:h-[41rem] sm:h-[26rem] justify-center mb-10 animate-fadeInSlow'>
                <img name='mobile-image' class='object-cover shadow-lg rounded-sm my-1 xl:my-0'
                    src={`${process.env.PUBLIC_URL}/assets/images/${introductionImages[1].src}`}
                    alt={`${process.env.PUBLIC_URL}/assets/images/${introductionImages[1].alt}`}
                    referrerPolicy="no-referrer" />
            </div>


            {/* Introduction Text */}
            <div class='flex flex-col justify-center w-full text-center font-[300]'>

                {/* Countdown */}
                <div class='text-gray-800 flex justify-center gap-x-2 2xs:text-xl text-center 2xs:mb-6 sm:mb-7'>
                    {/* <CountdownTimer /> */}
                    <CountdownTimerReception />
                </div>

                <p class="font-crimsonText text-gray-700 font-normal 2xs:mx-0 sm:mx-4 mt-4 2xs:text-[1.45rem]">
                    Sunday, April 30, 2023 at 5:30pm
                </p>

                <p class="font-crimsonText text-gray-700 font-medium 2xs:mx-0 sm:mx-4 mt-0 2xs:text-[1.45rem]">
                    Ricarda's Restaurant
                </p>
                
                <p class="font-crimsonText text-gray-700 font-normal 2xs:mx-0 sm:mx-4 mt-0 2xs:text-[1.45rem]">
                    134 Peter St, Toronto, ON M5V 2H2
                </p>

                
                
            </div>

        </div>
    </MainSection>
  )
}
