import React, { useContext, useEffect, useState } from 'react'
import { Link as RouterLink } from "react-router-dom";
import { AuthContext } from "../Context/AuthProvider";

const _404NotFound = () => {
    const { auth } = useContext(AuthContext);
    const [isHome, setIsHome] = useState(false);

    useEffect(() => {
        if(auth.authenticated===true) {
            setIsHome(true);
        }
    }, [auth.authenticated])

    return (
        <div class="h-screen">
            <div class="flex flex-col justify-center items-center h-full">
                <p class="2xs:text-[0.8rem] sm:text-md md:text-xl 2xs:mx-2 md:mx-10">Sorry the page you are looking for doesn't exist...</p>
                <RouterLink to="/login">
                    <p class="2xs:text-xs md:text-s text-gray-500 underline mt-6">
                        take me to the {!isHome ? <>login page</> : <>home page</>}
                    </p>
                </RouterLink>
            </div>
        </div>
    )
}

export default _404NotFound