import React from 'react'
import { TransitionGroup, CSSTransition } from "react-transition-group";
import { Card, CardHeader, CardContent, CardActions, Avatar, Button, Typography, CardMedia, IconButton, Tooltip } from '@mui/material';
import { Favorite, ThumbUp, EmojiEmotions, InsertEmoticon, FavoriteBorderOutlined, ThumbUpOutlined, EmojiEmotionsOutlined } from '@mui/icons-material';
import '../index.css';
import { GUESTBOOKCOMMENTS_BGCOLOR, SIGNGUESTBOOK_ICONBGCOLOR, SIGNGUESTBOOK_ICONCOLOR, SIGNGUESTBOOK_FONTFAMILY } from '../constants';
import { updatePostLike, updatePostHeart, updatePostHappy, getPosts } from '../Services/PostService';

const PostCard = ({ posts, handleRefreshPosts, author, content, onClick, postState, admin }) => {

  return (
    <TransitionGroup class="flex flex-wrap w-full opacity-100 hover:opacity-90 hover:scale-[1.01] transition duration-200 ease-in-out" onClick={onClick}>
        <CSSTransition in={true} unmountOnExit timeout={500} classNames="menu-primary">
            <div class="w-full p-1 md:p-2">
              {/* <div class="flex-col px-2 py-4 object-cover object-center w-full h-full rounded-sm shadow-lg"> */}

                  {/* <p>{content}</p> */}
                  {/* <br/> */}
                  {/* <p>- {author}</p> */}

                  <Card variant="outlined" sx={{ bgcolor: GUESTBOOKCOMMENTS_BGCOLOR }}>
                    {/* <CardMedia component="img" height="10" image={cardImg} /> */}
                    {/* <div class="h-[0.1rem] bg-darkgray"></div> */}

                    <CardHeader
                      avatar={<Avatar sx={{ color:SIGNGUESTBOOK_ICONCOLOR, bgcolor: SIGNGUESTBOOK_ICONBGCOLOR, fontFamily: SIGNGUESTBOOK_FONTFAMILY, fontWeight: 300 }}>{author[0]}</Avatar>}
                      title={author}
                      subheader={posts.createdOn ? new Date(posts.createdOn).toLocaleString([], {timeZone:'UTC', hour12:true, month:'short', day:'2-digit', year:'numeric', hour: '2-digit', minute:'2-digit', }) : ""}
                      sx={{ 
                        '& .MuiTypography-root': {
                          fontFamily: SIGNGUESTBOOK_FONTFAMILY,
                          fontWeight: 300
                        }
                      }}
                      action={
                        admin ?
                              <>
                                {posts?.like
                                  ?
                                  <IconButton onClick={() => updatePostLike(posts._id, false).then(handleRefreshPosts)}>
                                    <Tooltip title="Erin and Norris appreciate this!" placement="bottom" arrow>
                                      <ThumbUp sx={{ color:"#D6EFFF" }}/>
                                    </Tooltip>
                                  </IconButton>
                                  :
                                  <IconButton onClick={() => updatePostLike(posts._id, true).then(handleRefreshPosts)}>
                                    <Tooltip title="Erin and Norris appreciate this!" placement="bottom" arrow>
                                      <ThumbUpOutlined sx={{ color:"#D6EFFF" }}/>
                                    </Tooltip>
                                  </IconButton>
                                }
                                {posts?.heart
                                  ?
                                  <IconButton onClick={() => updatePostHeart(posts._id, false).then(handleRefreshPosts)}>
                                    <Tooltip title="Erin and Norris love this!" placement="bottom" arrow>
                                      <Favorite sx={{ color:"#EAC5CA" }}/>
                                    </Tooltip>
                                  </IconButton>
                                  :
                                  <IconButton onClick={() => updatePostHeart(posts._id, true).then(handleRefreshPosts)}>
                                    <Tooltip title="Erin and Norris love this!" placement="bottom" arrow>
                                      <FavoriteBorderOutlined sx={{ color:"#EAC5CA" }}/>
                                    </Tooltip>
                                  </IconButton>
                                }
                                {posts?.happy
                                  ?
                                  <IconButton onClick={() => updatePostHappy(posts._id, false).then(handleRefreshPosts)}>
                                    <Tooltip title="Erin and Norris are happy to hear this!" placement="bottom" arrow>
                                      <EmojiEmotions sx={{ color:"#F5EBAB" }}/>
                                    </Tooltip>
                                  </IconButton>
                                  :
                                  <IconButton onClick={() => updatePostHappy(posts._id, true).then(handleRefreshPosts)}>
                                    <Tooltip title="Erin and Norris are happy to hear this!" placement="bottom" arrow>
                                      <EmojiEmotionsOutlined sx={{ color:"#F5EBAB" }}/>
                                    </Tooltip>
                                  </IconButton>
                                }
                              </>

                              :
                              <>
                                {posts?.like &&
                                <Tooltip title="Erin and Norris appreciate this!" placement="bottom" arrow>
                                  <ThumbUp sx={{ margin: '8px 8px 0 0', color:"#D6EFFF" }}/>
                                </Tooltip>
                                }
                                {posts?.heart &&
                                <Tooltip title="Erin and Norris love this!" placement="bottom" arrow>
                                  <Favorite sx={{ margin: '8px 8px 0 0', color:"#EAC5CA" }}/>
                                </Tooltip>
                                }
                                {posts?.happy &&
                                <Tooltip title="Erin and Norris are happy to hear this!" placement="bottom" arrow>
                                  <EmojiEmotions sx={{ margin: '8px 8px 0 0', color:"#F5EBAB" }}/>
                                </Tooltip>
                                }
                              </>
                      }
                    >
                      
                    </CardHeader>

                    <CardContent>
                      <span class="font-[300]">{content}</span>
                    </CardContent>

                    {/* <CardActions>
                      - Norris
                    </CardActions> */}
                  </Card>

              {/* </div> */}
            </div>
      </CSSTransition>
    </TransitionGroup>
  )
}

export default PostCard