import React from 'react'
import Countdown from 'react-countdown';
import { Link as RouterLink } from "react-router-dom";
import { animateScroll as scroll } from "react-scroll";
import { updatePremiereStarted } from '../Services/StatService';


export const CountdownTimerReception = ({ fromWatchPage, premiereStarted, setPremiereStarted }) => {
  let d = new Date();

  // Countdown to premiere //
  d.setFullYear(2023, (4 - 1), 30); //starts months at 0 instead of 1
  d.setHours(17);
  d.setMinutes(30);
  d.setSeconds(0);

  // const scrollToTheTop = () => {
  //   scroll.scrollToTop({
  //     delay: 60,
  //     duration: 160,
  //   })
  // }

  const scrollTo2 = () => {
    scroll.scrollTo(120)
  }

  const reload = () => {
    window.location.reload()
  }

  const scrollToTop = (callback) => {
    // scrollToTheTop();
    scrollTo2();
    setTimeout(() => {
      callback();
    }, 300)
  }

  const renderButton = (
    fromWatchPage
    ?
    <div class="2xs:w-full sm:w-4/6 2xs:p-1 md:p-2 2xs:mb-1 lg:mb-2 flex flex-row justify-center">
      <button type="button" onClick={() => scrollToTop(reload)} class="flex flex-row justify-center items-center px-6 2xs:pt-2.5 sm:pt-3 pb-2 border-1 border-gray-200 bg-[#707070] text-[#ECEDEF] font-[400] 2xs:text-sm lg:text-md leading-normal uppercase rounded-sm shadow-lg 2xs:w-5/6 lg:w-4/6 2xl:w-3/6 2xs:h-[110%] lg:h-[120%] opacity-100 hover:opacity-80 hover:scale-[1.01] hover:bg-[#B0B0AF] hover:shadow-lg focus:bg-[#808080] focus:shadow-lg focus:outline-none focus:ring-0 transition duration-400 ease-in-out">
        RSVP HERE
      </button>
    </div>
    :
    <div class="w-full 2xs:p-1 md:p-2 2xs:mb-1 lg:mb-2 flex flex-row justify-center">
      <RouterLink to="/watch" class="flex flex-row justify-center items-center px-6 2xs:pt-2.5 sm:pt-3 pb-2 border-1 border-gray-200 bg-[#707070] text-[#ECEDEF] font-[400] 2xs:text-sm lg:text-md leading-normal uppercase rounded-sm shadow-lg 2xs:w-5/6 lg:w-4/6 2xl:w-3/6 2xs:h-[110%] lg:h-[120%] opacity-100 hover:opacity-80 hover:scale-[1.01] hover:bg-[#B0B0AF] hover:shadow-lg focus:bg-[#808080] focus:shadow-lg focus:outline-none focus:ring-0 transition duration-400 ease-in-out">
        <button type="button" class="">
          RSVP HERE
        </button>
      </RouterLink>
    </div>
  )

  const renderer = ({ days, hours, minutes, seconds, completed }) => {

    if (completed) {
      return (
        <div class="flex-col">
          <p class="text-gray-800 2xs:mx-0 sm:mx-4 mt-4 mb-1 font-crimsonText 2xs:[1.875rem]">
              Wedding Reception started
          </p>
          <div>
            <span class="2xs:text-lg sm:text-xl">{days} {(days===1)?"day":"days"}</span>
            <span class="2xs:text-lg sm:text-xl 2xs:pl-2 sm:pl-3">{hours} {(hours===1)?"hour":"hours"}</span>
            <span class="2xs:text-lg sm:text-xl 2xs:pl-2 sm:pl-3">{minutes} {(minutes===1)?"minute":"minutes"}</span>
            <span class="2xs:text-lg sm:text-xl 2xs:pl-2 sm:pl-3">{seconds} {(seconds===1)?"second":"seconds"}</span>
            <span class="2xs:pl-2"> ago</span>
          </div>
        </div>
      )
    } else {
      // Render a countdown
      return (
        <div class="flex-col">
          <p class="text-gray-800 2xs:mx-0 sm:mx-4 mt-4 mb-1 font-crimsonText 2xs:text-[1.875rem]">
              Wedding Reception begins in
          </p>
          <div>
            <span class="2xs:text-lg sm:text-xl">{days} {(days===1)?"day":"days"}</span>
            <span class="2xs:text-lg sm:text-xl 2xs:pl-2 sm:pl-3">{hours} {(hours===1)?"hour":"hours"}</span>
            <span class="2xs:text-lg sm:text-xl 2xs:pl-2 sm:pl-3">{minutes} {(minutes===1)?"minute":"minutes"}</span>
            <span class="2xs:text-lg sm:text-xl 2xs:pl-2 sm:pl-3">{seconds} {(seconds===1)?"second":"seconds"}</span>
          </div>
        </div>
      );
    }
  };

  return (
    <>
        <Countdown date={d} renderer={renderer} overtime />
    </>
  )
}
