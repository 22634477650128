export const galleryImages = [
    // public folder images
    {
        src: "1-hugfrombehind.jpg", 
        alt: "Hug from behind"
    },
    {
        src: "2-pretendwalking.jpg", 
        alt: "Pretend walking"
    },
    {
        src: "3-smilingandpointingatring.jpg", 
        alt: "Smiling and pointing at ring"
    },
    {
        src: "4-hugfrombehindlaughing.jpg", 
        alt: "Hug from behind laughing"
    },
    {
        src: "5-norrisspinningerin.jpg", 
        alt: "Norris spinning Erin"
    },
    {
        src: "6-norriscarryingerin.jpg", 
        alt: "Norris carrying Erin"
    },
    {
        src: "7-backshothugging.jpg", 
        alt: "Back shot hugging"
    },
    {
        src: "8-norrisliftingerin.jpg", 
        alt: "Norris lifting Erin"
    },
    {
        src: "9-ringshotnorrissurprised.jpg", 
        alt: "Ring shot and Norris acts surprised"
    },
    {
        src: "10-lightbox.jpg", 
        alt: "Lightbox"
    },
    {
        src: "11-smilephoto.jpg", 
        alt: "Smiling"
    },
    {
        src: "12-ringshotjusthands.jpg", 
        alt: "Ring shot with just hands"
    },
    {
        src: "13-norriskissingerinshead.jpg", 
        alt: "Norris kisses Erin's head"
    },
    {
        src: "14-erinkissingnorrischeekfrombehind.jpg", 
        alt: "Erin kissing Norris' cheek from behind"
    },
    {
        src: "15-laughingwhilepretendwalking.jpg", 
        alt: "Laughing while pretend walking"
    },
    {
        src: "16-walkingandlookingateachother.jpg", 
        alt: "Walking and looking at each other"
    }



    // google photos images
    // {
    //     src: "https://lh3.googleusercontent.com/UCyga7MeEXJ1q8Bph9QaLeCt-jU0drcsPBOmIhX-0zq3DLsl7FmOR3cRD55hKgqyRX50jQ8hnUrDKYToG1SmCCjWQ79cHL38OIxTF6SlNCzJG0z0-EqIuP0ckhSE81FYZw413HQaQIwGBJ6Y5RVFiQFC4aKt1brgmviND182UTh0VpSFFMJBA76fzQomD_W00I_7n8SwcZtZeQGzcaTx-HT5bQqQBm6GZ635o1UT1ultwBkzL96fCevWsUHrgY6SOQ2E739cNSM7s-7tfGh46scvZqoLvh8khTEcuEGBSOZKiEC1Umit6sQ7zj-YoyuRbZMnAAFtE3j_zS6Opa37wrIh_GjD9azrJIqqhavsPYK6tjRoxgLJdpcoosFWU1dvUeK_OasRGN5SzTk0TJ4IhfRYNbRSi3mH_-aK1wQ8JtShe2c5g_3yK8AruVR3nbcewzgcv7RU7GqK-3VvI2ZTdJ4Ektgzh4n6mSwg8s768A4kjPYqhYIN8Vf7qb6DpINiQcOvvGS7n3ysJ_oQ3_s_OUXeSzo_ESRBgP5yWtEeBFJ50sek1M6Jf9_PwI_NlPtW1eVt4OTXwnwsoih-S1QeGNv_UTOelhMmHp-zYgYRywCDSIrpj0z_hqX6DlPbQtWepC1-lRCDZo1J0H64puciiPDNDChU7tvVhl6TaVf567BqsdulztTgGbljNeJv34T9wtu892Fwjprq9uVowJKMeBozS5jQ5-ov4w1mfgh89AissnbbUGRbVf7nYuVUAkh2aePoLCyaI7s8boGMzUh6tTnvEv7T0vAFfnti6Y8p8Je8meDFEaZMlVpw9uB7DTti9hQ=w603-h903-no?authuser=0", 
    //     alt: "1-hugfrombehind"
    // },
    // {
    //     src: "https://lh3.googleusercontent.com/hpqTgbzW5yU572LysPlwnFVv0ZaBLfOJN3PO0bT1RudzP4ijAFASHZ6aYyedRAYJEWq9rsI690W1yH4gG9RunFZr8zHCVsh4dfsWRJcAbAQ-CC14XSQ6R3AmOJCzy_qXDpYo-LYFlIZ_ARZRUOwkDlE8yl5Bl5yX4XtKwjCA9yChT1mVALfbOPuiY5NKfbH-75FsY4irT0sv4Av9OUow-kk0aOCISH1fVKTUjDEiPDU_ApfM_x4Pk_4j45zGAt0emgkcyoKaKxFtUR-QaP1lb_9hTjjVogRDfSwqWA6dEZrRYhfUjoT9P_eif59jieZHvTRlDPf2oWxeXG763SoNuPiJk48xbvSK1zNd5RGpVvoLTiRFr0hYajriSEWgwZdCjVf_6WL43CnBOfQpFlRUVPEioq4Nvg1nwhVBoxTP9L6mJyA8ozOBD0HALBE8QRPnFrsfyERbDefqo0NriD3H3F0zoBOt590lZBocSekH07SzVV4OGORRaYacC6SJDvb8VAXdrQ1SdhPwdxZ6p5wSVNweXi8UVRQZj6IeD6GyLYQEEErj0SfgSaDbd2g1ikypkEYXUAZu9Vk5k4VP1VqesCwvwzA0StNAGKVCjrEc9ED0iUheZ-zdc8an8oR3lVd7mTGIEs0bkTGVLkBTO9__O_5ToxmiwRU2SHELm4yC0jt724n3X_4ZJgChQB1AzH1yerdi1FHGJC7Ztbe1-rxobvZ117JxrqVDfR-AG6EWmrApE1XQ-XVQ8rjAL2ygxj6M7VMujAy-baw61VnTXmCx-Yjl_4qnxe5guo5iztKZB_8_wYap3JSSp25QA-QfvRQvGLc=w603-h903-no?authuser=0", 
    //     alt: "2-pretendwalking"
    // },
    // {
    //     src: "https://lh3.googleusercontent.com/ECWb0Z0J_emvCjOtZDBIz4xKM3e8iw_c-YSk2icdlvO9mPfrRaSNfurQif-ojrAjjHXQFvl5mGPUxwoIoLH0UNedzHW_r4-tpWuxGVhKeWkNt8qTY-H62VKkKfx5khqRl5TZ5yfVGesneA7CwATkSFstbJZ-bnbdqLBxoiDpwF2vhxfeYdCWNHu7bRxpeVWImOwxFDNEmVM7cLOGjV3Bew9fe0vYL8zDReho20GSLdTqhKFOBH7PRmOkAKVZh5eJ51zCMLblT1pbECGXJRTlfMs_80fFzKAqBZg_OO-8cGfqwvu8vy5fFCJUDyd1AGC-y4pWDoFNM282QCS3QW2_LHPklqzo_vMSjx0vX2qNu7DW79pkhPmG-lA1nGnBOXkDv656D6koG5f7zUpRwFwUiE-gQ2h8XhtOe8sCipwEZLhK4TzHNg9JSmaDwqL66qeuCLHgDZUSIjkDlHi1AabZPyjNByfZJ_4X-XNGsAFOagR9FTlkSbv3gzE7eolNv0CyfwLwOL6MNwliiuBNlFVR3vTtD13hYjlV0Arz6VEfdWQe-zWVX1_LKMpFepMWBEZblRYhhjipa5-bO1Im8KJ9xTBlTsD9RNsxBLsQbQ63ZCxRdfeHv4MBPTguVUKcntxCS_89g47SZYf52w_dtBRZNZ1uUXw9Xthmbl7CkG9_V7eniGujCI52m_qE6ttRhMZevSEvngiYzfq7rZ6S6DaoIqTQJtxKdLaViOSxGMCYOMYt2504cN6DYhS1-rMtyiy5i6fF2bhM753ljvcVynjaHu-ESmkxA60ryreFasVuT-zmjXjQtlWbtWxk1xoPCuTYOxU=w603-h903-no?authuser=0", 
    //     alt: "3-smilingandpointingatring"
    // },
    // {
    //     src: "https://lh3.googleusercontent.com/HsXHoofke0wB4S9sQLBFZ94Ql62ZaoNJmbjzSZeQLJVtBpju0IgkbM5vi3Ez2xwU2m8cwqUOSl0YTCygBXu60maMjdAfHCMbKhsMHw-Huxp5qSB5U4Mnw4xPP-YxYXhOcvEypSuqR0ne71CWoR1OOZnqXcsGJtEJR1FUumzHC61gp7RJrIeRHxJopvvUieJ_OrZFT6UO3NRmq0VfZT06jrzCQCgrNOUg5d-K5z3ha7DrxqInylTEEQBdFu6ulVpEfwTfceJG1iI2Jzke41N5-0tZ35W0yT0Qon4Iswb7ZUVTB9rxDBP5DT3j7Z6XET52bATNhFBat2qfbau65LpC_j3gNApl_11fyNJ4x2yB4J2DT0t6K_GGKyP0hY3pffkmN172wTfamVjviBaodHz5ZkxKXJOtkR9vCTFl0UJ6yIoTaL7Y0rWFsJKLmsd5XsxoQrQoFf7pwBuWvxwuJXRhNTsVvRkDRXLQjFvYRTwQy-U8Vap8g8td0pTY0Td7BOMXgLrBenj6erNhFqHHwYJOin0n8vJ_bF-aTop3VjC2N_FPev2JbFnBaF3kd7abYH2f19YABVRwo2NqIEq0m4xH5HxCzkXYjfjkriA7_4B9Ro2bZtlW9HgAlujjrzdxDaJ2PApd8_vEAtvwrIHSkoQ29DzJoJb4mobFsY3K7fZXZyGmctHjIASxa5utjYQlrF_aL_Q1SmdEfIgyfQWrQI6_fWpKNrQMG1Z1rHNbcWOX6HjWX-KIk8Y9n1ss4U7tFlgkItipk2ZVRNKF9PkxmlYIJv5cf8qQxLToO2zpYmt465TRJJtNt2Wou7lTKhQe6qLcMeI=w603-h903-no?authuser=0", 
    //     alt: "4-hugfrombehindlaughing"
    // },
    // {
    //     src: "https://lh3.googleusercontent.com/lr5PMS0KUycyJw7BwSl28wrRiOVMLOhCcTBDJwgxLBVIP0hHpKnuPdZMZg9J4n-5LRH2ESoQQ0I-6fB2JrGxLtaGVu_icOJAeVxc44Y6dyrc8nhAxyeirLS_LUT6EoA_vDEMjd8K5c3C4jnP1NXhW0DhAm0e4GuxtQh9CT2B3xnHMTdh_dISZ19fvRmnGlKFhbyKM5SPlIk0dyAed-itZ5wzL49L9akrB51dnD5PniZ4YXieJlKPzNEh3mZddcdvktC6aiPL7pF_hZh_W_XSjzSiDtklvwylpDAQscBmJBBe4cEYF6gqzCgFvG9Pg7A1tDLS9rn6fHpwctxE22jvunyJubKJcdI_QqoQqzTn9K1drhj0duFJWCkeQ0izXPhyj3iN_TaIseqJEBScSgjqs3dwy8q4nt1SL52n5xOLdyAL8t-b-BGyF1SGTBX1rv1HVe0QxdTyDIc28NCtdS3I0LvDjUsnL6YkrokZrG8dY2_IL10m_FpCqVCTTH4n9-6TR6lNppqirURNiTNYRh2WQE2_a-3XkWD8Tp3xx_JBWXfFFhOgXPdJKbhIGK5_b3Iq1VppZa3W2tiYns35qOBfgDqU38BAyWRh6LfCr7HtQ4izo8dbv_96ai976UEq9n9xBPoIp74SQs_kCbTmGf8FEO_ThNWjvyM5TXiLUc6n6EchIdqpK8yoAIT2wQ9tjnkPiSlLM85if09UbFn2r7WlKubal1V6I8MMOooXr81_YeZ5rrdVHMMUN76xYMuEpB8pyrYc23XuP0f9yu-bzBIeJKI32pkCoTMXEaPr25gUwMJ_S3SSKpnCh6j1HX3bP5WOe3g=w603-h903-no?authuser=0", 
    //     alt: "5-norrisspinningerin"
    // },
    // {
    //     src: "https://lh3.googleusercontent.com/gR3qTbtHCyhDobs_EVbuXLQeMkEVUlUK43rPKBEyRNI2a-RZB0QaYaK9_MhsUIgO6tQkqR4wgefUJcprm7BxX_6iyiDfdcky8R-NxtXY-9omXA-67vqMsvrbAj1xR0UBUrGhQ0BA4bJRHFHlztCCZoT9UXbY-JdWvPOIX6rQTTfPFODRhEkqko3uNNVUSPMgSFi-sOYf9VMbWV-0RPBSuqIQbj3O0V4wA2oWLDD7cJkgpwpxLERCXUk17uNY7Rb0dm-eVn2EnhSqycVceb9bTDmQq93rCepkQc_EwgMAzkqgFfUUIO-jNknGW3eEYChrGSKCcepjjU5GHpS9wG-Q5AUOXizkRxGU7RfBB2R4ZbNP4W4EpjCOhNItbWxJPtIS_7fsa7EfAJcrmUnbGn17bk-e2run7JlcRRZxlBDvBVo_LMof296Muy6Qgc-6mhlreIEQEON8MO9Q3w1t63H0dY-S59Y7CSeAf7QHW6wD0X8c_sQ7yVdlfPRSVv_oOQ8oL7ilWtLbSC8Pxh8ktD0LBWkURkpLAlm8qLKDp_3WvMhyATVLH6qWPfY67QzgD4xiQ34cZs_WpvNJ43EasHO1wptp9769-b3fxI-_rSjDrOXkL5-bBx_UO1QOAOf9rSoSxFRlpyKUFDww-bJVjEISuz1TQOzcwANdlTZ2LLMXIDYBAHhGAn62PIeEX64TRaNPu2xj6nRH6Mu5W7X9hOJjw6VrJ2Vd5TNgkZLrnxZc-ZS1EqBdB69dGNZ9Gi94D9tPS8kpxwY8gv_-S4RIRigEf_epFL1zsmJwmfQwA1pUWzLFsh7L2FErAp7HGyUB4LBI_Fg=w603-h903-no?authuser=0", 
    //     alt: "6-norriscarryingerin"
    // },
    // {
    //     src: "https://lh3.googleusercontent.com/IEshxWXCtCdvVVXEdsIXlF1Q2rpfluT2_x14loYeGDaqHdUlWlp-vuCjDp32ICLT054uUP66jp9sV8qVGTjgFsFtzmrhfXdirwVFfXxGfgLvsZ1VpNPWCpEt44mW6gaN95nv2R7HWZejunYrPYSSOm29dEbXpvE18A1kmsFnYiBfAq3lTxHIXgvSpndbCbhohlYsC-fN8Rr44NehKNEkRph2TZg58x8lFcrJGGyxK3mD2FLTmvpXbMMYJGtV2e9Ii_RIzHPw1X7YuW_yWBqJIzp0na0ia3yDGhfBO2UVUWyCap0z7y0o6V3Z3JeJp-aLGFqk8PCwjaCsoJD76CzK2_LNALogSG2hbwfEYHSh0x-rgAtDCDSLn6kzVisYlxKP8AtkKx-9i7CfQYXnN9gPyUXODvE-AwZUezs71swWSShIU_yXsaREF5prwIakhsZpQyofn0pVRfT5QNahLxP0fgEuDLaHP33gC54C30pFAn_P6rLvnzzv5MHWi8TzEBmx271mssRnLOoP2zZKUD7MkXfpV-PwHQ_er0ZllPWpRgxUX86eY3DLSvNGSY1cMgmOJ0576EaFOu-2oUIaXWVVRzTSACZmyiRgAxzLvexRc6GrK3-Gqhf5yNOA3KUr2J1K3IYBCUiG4oxWHbUb8dKwm8H4_TXwzEYrZbxV8B55rsOEIqNVXKvQBq_KPV-OymkB-jqgy-MO7DLd8RGW7BlzU7R2uoiuWcNg9gOjkfSq2Nr7-SWEAOtONvr3BBTDQouGcIuANPpjWhOfUWkbVqOdNhP3MYjcM3X1k_ExCvIVh8dcKXe_iDVsVa_06QksZMQTuKc=w603-h903-no?authuser=0", 
    //     alt: "7-backshothugging"
    // },
    // {
    //     src: "https://lh3.googleusercontent.com/SkFEQ1qCYvGHazFfg5Ij7WmFcbG87oHzLUvulaF_WfBFqHBBUgidRsczt12dLcA-SzIdtQZpbu-DyBF9bJrlxoH9Yw3pzbrf5_i_fbS_LXaUucNsddQ1xWIt8gS8cy2BgfxAnukUw8umabc_s50jjiZqH1fwhoPkkJbYxGXfF8Faw1aCsPzoZXlPaZP09POWQ9KnMs5ZAN_SgtCAkRlvlGCb1hqHirLrEcqtca7IJiLnB4WKTmkgeGZZvk9PsZDHjviFKBARQy77n2r8wchoc-W_hNN9lRAwkFDhFwTRffsO1v2Gc8H3h5F9zH_44lomFxpNVScjghzPZdJcugJkk9NPOZ6mQRqAq2pmS0A9Z1npI-kOwOWU3Tls3aQanvaCJVDFij7IHFFQWhjv9z_wD5uOesvxbhbga26yArYW7VnT9T2mwTfu-uJ-dR2Dh0z02kgQTaO8XPF0zHR3aiVguHsdmbJ2fNsrsimpkgkGMGYVvuNhTvHSMvqmTHco8mE4-JF7WeA2JXIkfOE64yKGDrp_qLCH9GhF3TaTk3k7SguZoTj0mYVYqJyeq1Sk4ym4cJw1c5yqN8KgnA1R6o77w1n3tdzL24Xi3BgOc0Ewp-jWqC3AuKwXWeMMS8Mjzvsxevx-ZQfxx0VtACYQLb7GJTiuEoxCA6nm3_qlxDewZZCM0WdeTZ7h3H2MHOY0dLelWaO6JqWT2CxMTfIzHr5v269lnSATGI3XDCy9oha8Jxxvt0jZSdvkZe9r27FmkeXW68ODUnOT4y3M53ekM9NTYgsRvSmLZJlngZCcv6bKsvT4Syq_wZONN7daIvIMAlWQOFs=w603-h903-no?authuser=0", 
    //     alt: "8-norrisliftingerin"
    // },
    // {
    //     src: "https://lh3.googleusercontent.com/3SXrF4GzzN3q03qwgzuzjKPI9qXKtvAPZx1qrAe9yb2wd2ll4NPBnBMLNJgFP8KrNjJDB18rb0YwuynZa8qviBikNTZVZ0slbdzAjOc-PCu7ToUgJxaGGPMRfaTFxRCDhbFQmvDMZihr90zb0w4Yr6shC8BRUIl5Zbjp2p7ysu9U6h062oZQ8Qkzoo5XgP_U0YYH55UbeN-PHAwDylQSX_jtWDRyz1ZXbc1DQY2uxDmL1CWPtpjrOWAuHpq6WgxiS2D4QOBM565k1RgNyXrYfpJZECHjVGUx0Ln3b_mOIL03RWr8ARIVih3bD2C_baRlCT-uMuGFIF0EXO3rfkY9T7kXwrvBFQL1saVbWt2oFG0PK6VCVGejznqKeSZr1JXjkx_tnoUCDoDjX3401zIPyy5A3VvyFTnfYJ-rXct21y1MlUMDRAAF6zxf0pRVUy28oGBFerT96XI9q-DsjgAp4Eos9qNFsSom83rTwJ5snzxlqeSv9anHYtYS1JLGJYXMoS_yHDJqgPDnGZAYKR5-wEn0htsECtJlp1Z2td3Vo77wp_4I8J7IIZoPY4ACbL6G95fefBkGcZjM5GMysYv5rK40uJWuOleJfsV1_vXdFIP4a0tfadwHzLYBGLimJHyYoZeqHFSRvfsx3GTJuxvkURWor8wDJhzyBcSiO3whN3JFUF1ihRUgMPk5BEUIpEmdWRwY5MjNojaGLW0675nOd3YA8f1IfpeS-OatLFJS0PajOr9f51Li6jpgLsnd6oketVhcQAdrlwK6qJOgC5yghIClawYxxx_uOBISqmQDn_gtC6fCVUNdEdTTmfsxw_XjPeU=w603-h903-no?authuser=0", 
    //     alt: "10-ringshotnorrissurprised"
    // },
    // {
    //     src: "https://lh3.googleusercontent.com/BIOkctCg4S1EBxaQeSQezU_AK-fLteMaXqlofHCGuRF0g9KrVrrbusR27fTGLGfkiQjmHtHCVJgOEZ1xoDars7Z9po-XAIipgqV8_hjozz7a_It10-iwjyVItgwVhkkZUFUWHX_yeqsSwAvPTcjMqYsDdz7HEL_tt0MFN4xmduON59nwvXOzQF5tH60om64hYhC14JfVsTiVqBGfnhO6gdhVwU8gIK6aQIdb_upV-QOmVZxPsJ4tQi_wRp0LJFIRrgS62Fgw9Y6iJiXytBSKBH_jOCTS31n2dttyOnwF8TrvfDbeMarmzchp2xZk0BZo_TmYOh9ffspUnOvyGCygQVVok9ekKajigiDiBSA4lZ4TvhhoHKK2etZJbSUoEXcALEfg6yZv3dgPxudGRw4QZNviT_6z_rcMp-0tFzYEpjRjEw555_z2L1AF7m1eUBmrstqzaREegIN3dYc3uNzlBVWd4IYAdGKxLJdIefdLEfaPWQ5tp-vUx3mUtMfjVmsWHAFyI6VYBb7AtxO_x6a6DqVSQ3dH1ggOaFqASAgW7L0O92wuxXw2X5j3ipHo54x1snETB-Q0m1Ps14BbXXA0NXu7BrgZzK8a-MtNwxlNXh6Pi7gpTf8l7SmbY4z1qfu427bUXR1T9napa2MgojJyQ04YgJo1mx5lGhXieZab_U-2DLfqSU-dIrAro3CtzUSocsPkVubynBakIJS58ncxAKXdbNP6_MfNGKrhkmmQXSlv6_wszTsN2gQmldBjx47Fm1IGsf-NYqGX9pyn2YRkXLKMYfrmfZ-8o3lz_IxNXMdIYj3PT7dZmATxZBAgcj4c5-Q=w603-h903-no?authuser=0", 
    //     alt: "11-lightbox"
    // },
    // {
    //     src: "https://lh3.googleusercontent.com/z27b0NvY3lFotHOdXlGJBiMSqW_jcVXSq7U0TstkH8hnLJzl3TfnnmDWtwaemm7IBNWfmQJP6UBYD0u8Js058C-Ye7dF7XJrbSnzMCp4swBOuCNOn4AhaGedwZaQSquswdDaT7VRnNOMSK63rQ0mf5e-TAd5sFBzbCh1WHGfqJMFbyESxUV3yA6omRz5Qjz0NA3y24xOV_jNCQxN4MZ0kvKdqWc1TkIBN3TWOVzvUyzVjvw50lhYjkVhX-JpOBPFsvwMP7FBvU2eoaEiVGRC8MsgDfXMBuX2-HK188gjXBC1LR_zAHub42d2YH1eazxR85pIz1PO2N2QsQDHv4CVztSrlxf6WF7R1ngb2k-80CNKm6YyeUwF0So8fUe0aCllpysz_i_cFinxBgBJEpxQUYk9rX6ThQlk7makQTl4cSBsCspI7yRsIw8u-1eyNuq8TKIm-NLwopcHLlw-SkEKZsJI9s_RqLgsDPTbMQYx0OaDq596JQ9qvt6wQxR8BNlAvt--_Ek7kkxePm03yaZVzHmDPdo8fZ6NraqvZl77rtD0XFKbyy71zSQuQ4YwnRx0-J_oqTSmQuraKFBv2UeXYS67laeLNQlrrKMkEkNSQGzQhPnRr777NhWaglFSKmgpJ9Co47EyJM91Flujy3GDa7QR5fjOPoatjGxnKNf8nDfr-RKsoCwCt4_f8T6T7sJMkewj1-_DrD9BomnmY0B0u0Y5IMPguOkfky54L0wEzc812wp9PxXkzBh1i2-TZjLKgYu06ZCKeijKM4-SaCk-6bkwtia4ujp-81K2QZF1AxFbugit6u2TD5N403rMa_e5tx0=w603-h903-no?authuser=0", 
    //     alt: "12-smilephoto"
    // },
    // {
    //     src: "https://lh3.googleusercontent.com/yY48mgqlDq7bMwf5AuRqgmUFH84jO4p8zCvpTCEZo_gZ_W1pr-Fo9FhteuxCouK8rypNR3wZwkfr9sbfZ3YGE2lc1imYuXrNoQh51RvVHb2ZMHEv1go_OvfnnaDEn7tHRWxZ3IPUlAI8Sj0Up5e6XSVCniNu0GrP6s8-J661Ayg_YD9yTqSQR1REy6k3HHEXITK3r1FLOGNJmRLstTZHbRxEil825R_6e77DiEKg0VPw7DMpvH52N5qtyVTB5ieK0moVhGI1BdL_A7eZOsZF3BsmJVWzkODIabAhBfJ7K0HpETlYmICcknF94TC-3VAoMnnm-lCXvjNsp4v7UuVcI2XzSdKyCITcxXneva5nApjY9RJOQNw3JXLE--4AqsfGLk-zvVV9_F7I1ZR6ASCKUvCG3aE6Ieys8w2QG64FuKQBX6HENTtxAeakjbLKJ-05Tbjg4Mir-uNYiwPsfphPxozEppJTYNxnFhuC0IdlbqvF-k7FiLyiJO0udywH1LOlFzioNw-eYgUjzVRZxJS6oQ2ETirmhx38Lsyp6aNUeYvRvvNZkjzi-ADJX-6ll8STzUXEb7_ouxV2jm-cvtcBdhx_WD1AB_SzfZbPgolY5egm-MN_LxGAnuieeNhXEeKcRASK4cVMo8gDi96IVMz-namdMvz72qkm8g7tF-pLPdyJFzBpKqW-Q39tTd_wZtiT-1au31Sz-oYZp3WDeIy1PRNRqIF42bmcutJKxbF8G1ZoYBPFsHB0w_HQsitHKhqKKx8rgBWJnVYtIb8m1jNMgmpbiDy-jaKhu0ePIntiTYiSIUA3MV_7303g44jrwa79-xI=w603-h903-no?authuser=0", 
    //     alt: "13-ringshotjusthands"
    // },
    // {
    //     src: "https://lh3.googleusercontent.com/jyXFCVHtPF2T2D4TEGIre-6Xapet6BuF0k83sczbD2_bYbmxjZJgs2g1SbH6uwbOxNv5hStGDUgcC3Yq6FOdh3ZWif9vTiwxBggj1AdyqFMcFUkV5QJa1nxBEhSC5vlvI29XwHYupRPb2-iUdPkuGRyHiJR_kug1eYQ-YoVQWIocwUhgfp3seXj2SUigVAdvdpqWMzqVJWUr5-EPLkNJSAmjyWLEWYvX4bJdhOwFQ94btpizB11QmZ4C_-veCpkhNECLK02GgViX54BY8cflJqjh_rn1AfVivrp1BSBQbUwkSZcanTYLF4Axd6F67AEEARmr8CyOk6QlVC4LZfkxz1C7zPzuJ2tobv5yVbQRjOuwdRf_Lpkh3HUBMZeSYj3WgQfl3kcO44s6hgHnr99DA5aEfSJT0sQUv31G24IswHS5zuxWOIv7slZYAxfgDpWK5tpsq9Ua9xGLcTD06AmXr1e1q44Dcg6b7VPHJitKy3_gZrr2Rx7_Fttp8CCrPhcsW0TT3fSj6DuUgm6KxMwaWxmfzH_r3qVhEQmaZ6IJkx1Qc6nXd34Y6ytVKaGBkz12kbftQEthoH5Hclu_LzCIhGSPwD1d3yKMceZ2rpkbJEgAZzm4TjNDYBNxyCU8s9ZA8P2vzlOmH6ggsrHRP6MVv2a7S4WpMuBKXnCZ8JCqKp2gSpjODEcP121BEomPc1yTLZ3s1a4JzU9DeWOUyqdhvNVSyapYodWyFlTEGI8JlO20E-jb3LHBiru_DOBEX7L-QOFCanFNKp0x8J-dI2Rb6TkjzWCM3CI694AXMVigRGMPTwSOpAh8sALpuiUnYiS6O14=w603-h903-no?authuser=0", 
    //     alt: "14-norriskisseserinshead"
    // },
    // {
    //     src: "https://lh3.googleusercontent.com/uji_YrYgAO8jAlAJt_-bKxzXjmUfDXqpzrxYTT-s6-sXIYXSo8s4LKpy32PYhsvM2YycfXMzKIeg8Uq6aWqhzOetMN68tPTNoe_8umYWxQzmLBRPVezITNIuHRJCNfRKQA5F0pkKYXtR1kgtVEz4sE2KmIckXH2Vg8e1w0bFsPfnLhKN8IZnrdVu7FGEPeX_UEbbA46oiPUbjQbbQxy2OdwRoq6TE3KWacQxMhrXw4MThhkhG_rURlNVnSAoXZQElQAkadNgk_p_Xruxxzvv4Qti1XCtXPBJRf9D8pMh95-MYfuse0wfONgV4BHsJkyhIzYNXNiDGC_UU0arsGDkruj0ayhDJN7O_Vn4-WRRnjHvppMbZrBiCfC8auCfcG_CdHHQfb13HUkPySalLZieWlKm4CzIHLwIrBslX0KUmgervoLp_484u8HwMFM_YvlEgP8lcpZTkYSd1z6UU1o2RF_dgStNVIZrY5HLMi_kAdSB1Jlc8Xc7yqJBHFCJo5MpcV32NG5xXuKaNnmXi1b4SuGFKTiwyW40I-RuqhD4OXf3UnYFQts056t_EvItq8hPR-zcfo1iFDhvUMUJk6-MCV_JmS1E5COCtVTIG6HR1TTFtYkjfNwOPqbTCzqASIGFLsGjCbggFxwcvrS8pfSGdZmlbAfn4igzT6XOXEeSThazwbnDZa3QUN-C9igVIcYIDcbj4c-0ZRY3jgpcKUcgJ1phlC0TSI_1aBXFkYX7RWZUKqDujFcIlg5RzMfIEjHpepv_u_5Q9pIxETTAP0dPm0D4znSl0pDQ8hqJGwG-JpIu2Q1gokFljrDj-gxAXSLeYM0=w603-h903-no?authuser=0", 
    //     alt: "15-erinkissingnorrischeekfrombehind"
    // },
    // {
    //     src: "https://lh3.googleusercontent.com/A6gV2GJ8xSzVoY_rwqJzU4mDljnj_Pn66sZZQwDcydSliA4jlDY5gO2yvdQqh3egKqw6qqeSGa5UZmAtlGBlEBJKFD5w7r1LRTba8Tc9idjCMFLQj2XoSMsZcuIWajhzFaTZC3v_ArQW5EF6KseV5_PowJtSX-HoAFhRQCaLiLiZAc15r7sSTP6TOydOawUl2mtFPcZ8JByr025ZfNpT4d43txXqbcy_5O0eTZI4kJ-scOqzXJvS0d7nflpo4qMYQnUL0LEWZ17Hg335abB7cpI1Y4sGRE9XmXAUnL8UK5paJWAvIjKLtYqz1hzVKh5-FWThHYzIU9baXdTCIWSRvBFW4X-WYA5usZq0gbnzpE9M7ylCuy2OWhUZ347K-mLelzJ6pewF-WSca9baG7m4pA-f8LMDQPHoP7JewmB2pp7xHX9IaXydcK-UugLv4nBN-ITnY9lSEJZr51Z13cfeX4JBhUtLLyqiudUyMEBFmbb8Edd0OfFTbizeDJBcJwSF4wBHp1Mssakjund_v56sSKkJ5QA7btJ3TT8AbG2ZeB_BMnKDm-lx0OeP7-M1v399CzCWafWXIUA5YqZ0L4PrCnTwCrkeCRdS70WI_SCAfWK8EuQxFGAAdxYIrqjsEfcxa9e7Fe6im9WA4QZ89hgjCZPOldQ7qkU04S1a_hNTT8lUiJTtMI9wQsiZaAQHPg79VMMChh-kvXt0W40wPPMnM67QpU4LlpvN1L6wo3yQkYRpDDHpR0wnr18u2JQELgxDS3JledXpPyId83_I_r4JU7LBmSRWPczHkfce3GDjLRk4kjWSntXv_nOwRtc3KtgEnxo=w603-h903-no?authuser=0", 
    //     alt: "16-laughingwhilepretendwalking"
    // },
    // {
    //     src: "https://lh3.googleusercontent.com/IkzEfxq3V1XOq02YzYOrljDluVSXD29B8GS2ldhaG7TyjKZAF0Tot2Ve6j8MwkG5Jk42eak_-GcG_MICAtw2P2LXgIsZt0tI0CNM7qXmP1zNMMWboU947UsN0VkJiZcaKm1FaRic-TFgS3PNacWn4L-0o-g6gsYJIca0R2U48gWkEU73hW4xy-fHZTmX6i4QDw-P-jLW_NQ-eXWJsQOTIHQdpBw5IY13UmEJ29QVTrU42KnzsvObIGksebpdPTWF93bfYaHW2APt0G5NV1Ja1mJq1ZNrCYdXNyddykzPTbdGHvsG_nuRJumnlaTSmT6DPkJAxhFLu4zaH4mjpci1KsvDXuxQ5KVnx0kKFDeWRDDXsR_EqPfnHbGvdkSAABDxP0ctf_h0uk9CDD4mGg0igPNU4UJtoJ9JmQugsW16lOXUOHxu_eWLPWBSLH41r8X_3xLRl1GP7zQb6WFNUGHNdU0kT_lHj0A5ZV6dDTNB9qFyzENWQSeNYweIU_DF8w-I5NiS6J1OcUwLmpRHVZWZO618_7Y3jMvw6JLtnQcKwEQ1ZMFAxEqWMcSc--0MMJyh49_oB9eNOif4XsP7vRfPZKUNk26yotie1Ps5Hcq0evdSxv2eG1r0B45H785d3Y_Xu17J6XDHTHlZcnG1DwN2FurLbUJmOQhN89NjSKiRvivhiEIULhZibv7d0jVog4Af-Zxpd1nKmsizlnlyn3b-7-rRckkex444fQ5kiS39PD6pkop-QHOIk__ZVJmif3_RkybN68YQK9Pjjb08nQjxKescahYPDWizxGPohl-tOb7bFeu8329CSbiNbpfrlgdfvcM=w603-h903-no?authuser=0", 
    //     alt: "17-walkingandlookingateachother"
    // }


    // Commented Out
    // {
    //     src: "https://lh3.googleusercontent.com/A9BUzt2eGi-GSpuFszERtGLCnLnWsbF2mA6NmRuI0MA2zf5zrI9Vjrt5rSaU0PjWW_5Cpo1pnAzAXiZ-iZJTMxCBI_aInTwx-_JM1kGRjmx-IRaEeCntKAlQGaP4FI3LuRRtywn3N9HK-5AL91eLEKWSO7ORA8XFwa3i0mBzXqx3P4btWP3v6iToaPNNzBga2DM-k0X2BuVMyF5-Sx9abhspArCDSH60kJCOrT1jRkCFabTDUw6nG_-CGH0oQlgdGAXQrqp4EFlpIrasMHYH7in7mIakh34TIxv1wiNm7-XzYWQKiOxq5DpVQYZW7NwnnBVXkyIg6eIJyJtoHCHXUJeefXGvwX68IRnGLP9QZP8BKFdmj2YTJqUC78dFuW3-cGDRSDZv4UObW5BG-OvQk32VpuZITPGinzz5humJAfwcRHf4FE5CQY8eFjrNfNu23X70F_ninKHlh4XrvHAW9TIcda0F8yzIbLJwTYM2VE3dKr0uxUqbdSkdaA3IgSXPSGvn4b4lO4Kv_naAq3WwN2NDVaDdGU23u-d5eqdn9D_HxArTm4n5TT3DdB159PCO5W8ha6V1ImGgLabuxzowwQGntqRF8AJCI-whdpLfyGd5JFL-DASZ3RMYJukqdglG6IOk008z-jY3ARwS7gQQCJhWJX3-thh62-TGVXg9VYCSrQER6Gc1ntngNDnRaEYd6NANeRXxkEvIRK57jBjZjaRFo502ifaXIrkbfQynFlcW5fb_2bX01GKkpFf1kgGnCQOvv8wxy20txDQZPIeXbKfKbFitKgiEe46TsPix-jVlnmQBTuxKAndYu9fyxvRXYsk=w1354-h903-no?authuser=0", 
    //     alt: "9-erinsfacenorrisback"
    // },


    // Filler Images
    // {
    //     src: "https://mdbcdn.b-cdn.net/img/Photos/Horizontal/Nature/4-col/img%20(74).webp", 
    //     alt: "galleryimg"
    // },
    // {
    //     src: "https://lh3.google.com/pw/AM-JKLV8p-WR2vkE_9c-EiSLrLSFExsMO8rqP-jbAVn7hSRYq420QMkIA4gulbzQSJe3A8TiKgdwX4m1ypp3ZthYCJyrrseFFA=w603-h903-no?authuser=0", 
    //     alt: "engagement-pic-google-photos"
    // },
    // {
    //     src: "https://lh3.googleusercontent.com/pw/AM-JKLXJrjG9v9iKhJmzwoP7qTTvMpifUlbc5Au9FFlI0Irl5cdkbP4t9zHYCOcRA5F-2PDkKMFY29DdzO9IPQnwuRXt4rQc7HfKnhQD5E2QmXmUbjLtdMx5LuG8oXImx9bJUh3u27MSYq2OaxSERbwcjwrPIQ=w678-h903-no?authuser=0", 
    //     alt: "bopomofo-google-photos"
    // }
]

export const introductionImages = [
    // public folder images
    {
        src: "101-lookingateachother-landscape.jpg", 
        alt: "Erin and Norris looking at each other"
    },
    {
        src: "3-smilingandpointingatring.jpg", 
        alt: "Smiling and pointing at ring"
    },


    // google photos images
    // {
    //     src: "https://lh3.googleusercontent.com/K13du7tGx78JtfZc-JVHujwQPsaCDbzaq67rTi2RAjas0MtOSWUvrHl6CS_rRv9UQE2aAUAC0_Z7LegmYXOudv0JbDo5eza-PFobG-Oj-jN0_NhECNoE2tunzIiFtf50Jm9OxWutklZSx0-wMOqkp29m4zPkXQGlPkDIXhHL4a8v9gb55CEmoxZntM0SMD4d-0al1g2Qd6KJqgKbnQp5qVgtfNAhJXK7YL7P_tjX5FEPdDoUTaumzuTodhac3YUpeC2R265wc2z56z28i8OQSP87TnuXeTQaTSMlQYNno4cO3wzN_9gZiKM9A_Xx13Y34rsQb5dawQ0gYWB2eh2N1oFz0Z_Ot0iJN5ZlwhsjFkFyCvvL0aRFpcQOCaXeWT74iVZGeyq9a0dEfpTGqLH0ZBLfCVCUhF76cG8lcu2u-l4PdsY-ZG4hkG3EIsALVlx42CLtjjk2cUt6XInyPyC3QDk5pv5239IoBj1DmG47RAteXvJecXybWP7ugn9zSR5RSLbNFRpiyVk44LLS2Ya-DhIH7gs-vRHl6_cPkzBDjf9KRKgHppj3iYrqtJhBrCHQT-zw79kLkswJFBfifkoLWhHSzpBO-FRQ4iTV4h1yBNx1obnYCWIipyeYGHj5YWqCWrIgIWmLLQAXltMtbytEOo0hh6xnpginSmPP_xpf0fgZbf9L2EEPMqcGoHHsmb4TOIcD0bZVF18zhFO-TLCg-lMljQL728OhwvLyKoOBWiwW-F9IkZz4HZeJm7Uz-CmH0ViLXSxZwxYybIeqoHwp5wayUFxYAH1ApEEupbcqGByjhX0M46apjHt9n6a4anHn7x0=w1444-h963-no?authuser=0", 
    //     alt: "101-lookingateachother-landscape"
    // },
    // {
    //     src: "https://lh3.googleusercontent.com/ECWb0Z0J_emvCjOtZDBIz4xKM3e8iw_c-YSk2icdlvO9mPfrRaSNfurQif-ojrAjjHXQFvl5mGPUxwoIoLH0UNedzHW_r4-tpWuxGVhKeWkNt8qTY-H62VKkKfx5khqRl5TZ5yfVGesneA7CwATkSFstbJZ-bnbdqLBxoiDpwF2vhxfeYdCWNHu7bRxpeVWImOwxFDNEmVM7cLOGjV3Bew9fe0vYL8zDReho20GSLdTqhKFOBH7PRmOkAKVZh5eJ51zCMLblT1pbECGXJRTlfMs_80fFzKAqBZg_OO-8cGfqwvu8vy5fFCJUDyd1AGC-y4pWDoFNM282QCS3QW2_LHPklqzo_vMSjx0vX2qNu7DW79pkhPmG-lA1nGnBOXkDv656D6koG5f7zUpRwFwUiE-gQ2h8XhtOe8sCipwEZLhK4TzHNg9JSmaDwqL66qeuCLHgDZUSIjkDlHi1AabZPyjNByfZJ_4X-XNGsAFOagR9FTlkSbv3gzE7eolNv0CyfwLwOL6MNwliiuBNlFVR3vTtD13hYjlV0Arz6VEfdWQe-zWVX1_LKMpFepMWBEZblRYhhjipa5-bO1Im8KJ9xTBlTsD9RNsxBLsQbQ63ZCxRdfeHv4MBPTguVUKcntxCS_89g47SZYf52w_dtBRZNZ1uUXw9Xthmbl7CkG9_V7eniGujCI52m_qE6ttRhMZevSEvngiYzfq7rZ6S6DaoIqTQJtxKdLaViOSxGMCYOMYt2504cN6DYhS1-rMtyiy5i6fF2bhM753ljvcVynjaHu-ESmkxA60ryreFasVuT-zmjXjQtlWbtWxk1xoPCuTYOxU=w603-h903-no?authuser=0", 
    //     alt: "3-smilingandpointingatring"
    // },
]