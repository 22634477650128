import React from 'react'
import CustomParagraph from '../Subcomponents/CustomParagraph'
import MainSection from '../Subcomponents/MainSection'

export const About = () => {
    
  return (
    <div id="AboutUs">
        <MainSection name="#AboutUs" title="ABOUT US" titleHeader lineAbove>
            {/* About Us Text */}
            <div class="text-gray-800 2xs:text-[16px] 2xs:mx-2 sm:mx-[4rem] lg:mx-[8rem]">
                <p class='mt-1 mb-6 font-[500]'>
                    Some fun facts about us…
                </p>

                <p class='mt-1 mb-6 font-[300]'>
                    ✓ It all started with a bet and a game of Super Smash Bros. Norris lost intentionally so he could give Erin a plushie of her favourite character, Yoshi.
                </p>

                <p class='mt-1 mb-6 font-[300]'>
                    ✓ Our first date consisted of two of our favourite things: Erin's love for Disney movies and Norris' love for McDonald's.
                </p>

                <p class='mt-1 mb-6 font-[300]'>
                    ✓ We had a long distance relationship for almost 3 years, when we both moved away for university and when Norris was recently living in Trenton, Ontario (more than 150km away from the GTA).
                </p>

                <p class='mt-1 mb-6 font-[300]'>
                    ✓ Norris popped the question on May 16, 2022 in New York City during a morning stroll in Central Park.
                </p>

                <p class='mt-1 mb-6 font-[300]'>
                    ✓ Some of our favourite activities together are exploring new restaurants, going on hikes and nature walks, and binge watching our favourite sitcoms.
                </p>
            </div>
        </MainSection>
    </div>
  )
}
