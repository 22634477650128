import React, { useState, useEffect } from 'react'
import { Navbar } from '../Components/Navbar';
import { Footer } from '../Components/Footer';
import { Title } from '../Components/Title';
import GuestList from '../Subcomponents/GuestList';
import { getNotRsvpd, getRsvps } from '../Services/RsvpService';
import AdminStats from '../Subcomponents/AdminStats';


export const AdminPage = () => {
  const [allRsvps, setAllRsvps] = useState([]);
  const [notRsvpd, setNotRsvpd] = useState([]);

  useEffect(() => {
    getRsvps().then(res => {
      if(res.data.length !==0) {
        setAllRsvps(res.data);
        // console.log(res.data)
      }

    getNotRsvpd().then(res => {
      if(res.data.length !==0) {
        setNotRsvpd(res.data);
        // console.log(res.data)
      }
    })
  })


  }, [])

  return (
    <div class="animate-fadeIn">

        <Navbar mobile rsvpPage />

        <Title />

        <Navbar browser adminPage />
        
        <AdminStats />

        <GuestList allRsvps={allRsvps} setAllRsvps={setAllRsvps} notRsvpd={notRsvpd}/>

        <Footer />

    </div>
  )
}