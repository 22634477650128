import axios from 'axios';
const axiosInstance = axios.create({baseURL: process.env.REACT_APP_API_URL});

/******************** GET REQUESTS ********************/
// export const getEmail = async (values) => {
//     try {
//         let res = {};
//         res = await axiosInstance.get(`rsvps/verify/${values.firstname}/${values.lastname}`);
//         return res.data;
//     } catch (err) {
//         console.log(err);
//         return [];
//     }
// }

export const getRsvps = async () => {
    try {
        let res = {};
        res = await axiosInstance.get(`rsvps`);
        return res; //returning res instead of res.data
    } catch (err) {
        console.log(err);
        return [];
    }
}

export const getNotRsvpd = async () => {
    try {
        let res = {};
        res = await axiosInstance.get(`rsvps/notrsvpd`);
        return res; //returning res instead of res.data
    } catch (err) {
        console.log(err);
        return [];
    }
}

export const getRsvpByName = async (values) => {
    try {
        let res = {};
        res = await axiosInstance.get(`rsvps/getrsvp/name/${values.firstname}/${values.lastname}`);
        return res; //returning res instead of res.data
    } catch (err) {
        console.log(err);
        return [];
    }
}

export const getRsvpByID = async (values) => {
    try {
        let res = {};
        res = await axiosInstance.get(`rsvps/getrsvp/id/${values._id}`);
        return res; //returning res instead of res.data
    } catch (err) {
        console.log(err);
        return [];
    }
}

export const getRsvpByEmail = async (values) => {
    try {
        let res = {};
        res = await axiosInstance.get(`rsvps/getrsvp/email/${values.email}`);
        return res; //returning res instead of res.data
    } catch (err) {
        console.log(err);
        return [];
    }
}

/******************** POST REQUESTS ********************/
// export const addPost = async (values) => {
//     let _id = '';

//     try {
//         await axiosInstance.post('posts/add', {
//             author: values.author,
//             content: values.content,
//             like: false,
//             heart: false,
//             happy: false,
//         }).then(res => _id = JSON.stringify(res.data));
//     } catch (err) {
//         console.log(err);
//     }
// } 


/******************** PUT REQUESTS ********************/
// Update RSVP by firstname and lastname
export const updateRsvp = async (currentGuest, values, rsvp) => {
    try {
        // console.log("values: "+JSON.stringify(values));
        // console.log("rsvp: "+JSON.stringify(rsvp));

        await axiosInstance.put(`/rsvps/update/name/${currentGuest.firstname}/${currentGuest.lastname}`, {
            firstname: values.firstname,
            lastname: values.lastname,
            attending: values.attending,
            entree: values.entree,
            allergyandrestriction: values.allergyandrestriction,
            songrequest: values.songrequest,
            questionorcomment: values.questionorcomment,
            email: rsvp.email,
            emailsent: values.emailsent
        });
    } catch (err) {
        console.log(err);
        return [];
    }
};

// Update RSVP by ID
export const updateRsvpByID = async (currentGuest, values, rsvp) => {
    try {
        // console.log("axiosvalues: "+JSON.stringify(values));
        // console.log("axiosrsvp: "+JSON.stringify(rsvp));
        // console.log("axioscurrentGuest: "+JSON.stringify(currentGuest));
        // console.log("axioscurrentGuestID: "+JSON.stringify(currentGuest._id));

        await axiosInstance.put(`/rsvps/update/guestid/${currentGuest._id}`, {
            firstname: values.firstname,
            lastname: values.lastname,
            attending: values.attending,
            entree: values.entree,
            allergyandrestriction: values.allergyandrestriction,
            songrequest: values.songrequest,
            questionorcomment: values.questionorcomment,
            email: rsvp.email
        });
    } catch (err) {
        console.log(err);
        return [];
    }
};

// export const updatePostHeart = async (id, booleanInput) => {
//     try {
//         await axiosInstance.put(`/posts/update/heart/${id}`, {
//             heart: booleanInput,
//         });
//     } catch (err) {
//         console.log(err);
//         return [];
//     }
// };

// export const updatePostHappy = async (id, booleanInput) => {
//     try {
//         await axiosInstance.put(`/posts/update/happy/${id}`, {
//             happy: booleanInput,
//         });
//     } catch (err) {
//         console.log(err);
//         return [];
//     }
// };
