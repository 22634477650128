import React, { useState, useEffect } from 'react'
import { Paper, Container, Table, TableBody, TableHead, TableRow, IconButton, Typography, TextField, Hidden, InputLabel, Select, MenuItem, InputAdornment, TableCell } from '@mui/material';
import { Delete } from '@mui/icons-material';
import { getEntreeCount, getRsvpsAnswerCount, getRsvpsNoAnswerCount } from '../Services/AdminService';

const AdminStats = ({ allRsvps }) => {
    const [rsvpsYesCount, setRsvpsYesCount] = useState(0);
    const [rsvpsNoCount, setRsvpsNoCount] = useState(0);
    const [rsvpsNoAnswerCount, setRsvpsNoAnswerCount] = useState(0);

    const [misoblackcodCount, setMisoblackcodCount] = useState(0);
    const [lambchopCount, setLambchopCount] = useState(0);
    const [mushroomrisottoCount, setMushroomrisottoCount] = useState(0);
    
    useEffect(() => {
        getRsvpsAnswerCount("Yes").then(res => {
            if(res?.length !== 0) { 
                setRsvpsYesCount(res[0]?.count);
                // console.log('RSVP Yes: ' + JSON.stringify(res[0]?.count));
            }
        });

        getRsvpsAnswerCount("No").then(res => {
            if(res?.length !== 0) { 
                setRsvpsNoCount(res[0]?.count);
                // console.log('RSVP No: ' + JSON.stringify(res[0]?.count));
            }
        });

        getRsvpsNoAnswerCount().then(res => {
            if(res?.length !== 0) { 
                setRsvpsNoAnswerCount(res[0]?.count);
                // console.log('RSVP No Answer: ' + JSON.stringify(res[0]?.count));
            }
        });

        getEntreeCount("misoblackcod").then(res => {
            if(res?.length !== 0) { 
                setMisoblackcodCount(res[0]?.count);
                // console.log('misoblackcod: ' + JSON.stringify(res[0]?.count));
            }
        });

        getEntreeCount("lambchop").then(res => {
            if(res?.length !== 0) { 
                setLambchopCount(res[0]?.count);
                // console.log('lambchop: ' + JSON.stringify(res[0]?.count));
            }
        });

        getEntreeCount("mushroomrisotto").then(res => {
            if(res?.length !== 0) { 
                setMushroomrisottoCount(res[0]?.count);
                // console.log('mushroomrisotto: ' + JSON.stringify(res[0]?.count));
            }
        });
    }, [])

    const renderAdminStats = (
        <Paper elevation={8} sx={{ marginX: 8, marginY: 1.5, padding: 1.5}}>
            <div class="text-lg">
                <h1 class="underline">RSVP</h1>
                <p><span class="font-bold text-xl">{rsvpsYesCount}</span> Yes</p>
                <p><span class="font-bold text-xl">{rsvpsNoCount}</span> No</p>
                <p><span class="font-bold text-xl">{rsvpsNoAnswerCount}</span> No Answer</p>
                <div class="my-2" />
                <hr />
                <div class="my-2" />
                <h1 class="underline">Entree Selection</h1>
                <p><span class="font-bold text-xl">{misoblackcodCount}</span> Miso Glazed Black Cod</p>
                <p><span class="font-bold text-xl">{mushroomrisottoCount}</span> Wild Mushroom Carnaroli Risotto</p>
                <p><span class="font-bold text-xl">{lambchopCount}</span> Pistachio Crusted Ontario Lamb Chops</p>
            </div>
        </Paper>
    )

  return (
    <>
        {renderAdminStats}
    </>
  )
}

export default AdminStats