import React, { useState, useEffect } from 'react'
import { Navbar } from '../Components/Navbar';
import { Footer } from '../Components/Footer';
import { Title } from '../Components/Title';
import { Rsvp_Form } from '../Subcomponents/Rsvp_Form';


export const RsvpPage = ({rsvp, setRsvp}) => {
  // const [rsvp, setRsvp] = useState({})

  return (
    <div class="animate-fadeIn">

        <Navbar mobile rsvpPage />

        <Title />

        <Navbar browser rsvpPage />
        
        <Rsvp_Form rsvp={rsvp} setRsvp={setRsvp} />

        {/* <Guestbook_Form handleClose={handleClose} handleThanksOpen={handleThanksOpen} setPosts={setPosts} postState={postState} setPostState={setPostState}/> */}

        <Footer />

    </div>
  )
}