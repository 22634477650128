import React from 'react'

const MainSection = ({ children, name, titleHeader, title, lineAbove, watchPage }) => {
  return (
    <div name={name} class='2xs:mx-[1rem] sm:mx-[4rem] md:mx-[6rem] lg:mx-[8rem] xl:mx-[12rem] 2xl:mx-[14rem] 3xl:mx-[20rem] 4xl:mx-[34rem] 4k:mx-[40rem] mt-2 2xs:mb-10 flex-col justify-center items-center text-lg animate-fadeInSlow'>
        {lineAbove && <hr class='2xs:mt-8 md:mt-16 2xs:mb-14' />}
        {
          watchPage
            ? titleHeader && <h1 class='font-crimsonText 2xs:text-xl md:text-3xl text-center my-11'>{title}</h1>
            : titleHeader && <h1 class='font-crimsonText 2xs:text-3xl md:text-3xl text-center my-11'>{title}</h1>
          
        }
        {children}
    </div>
  )
}

export default MainSection