import React from 'react'
import { Link as RouterLink } from "react-router-dom";

const NavBarLinkSwitchSpecial = ({ children, to, onClick, disabled }) => {

  return (
    <>
      {
        disabled
        ? <RouterLink 
            to={to}
            onClick={onClick}
            class='w-full pointer-events-none'
            tabIndex="-1"
          >
            <button disabled class="text-gray-400 py-2 px-8 rounded-xl min-w-max w-full">{children}</button>
          </RouterLink>
          
        : <RouterLink 
            to={to}
            onClick={onClick}
            class='w-full'
            tabIndex="-1"
          >
            <button class="duration-500 hover:text-[#ECEDEF] hover:bg-[#707070] hover:border-[#ECEDEF] text-darkgraytext bg-transparent py-2 px-8 border border-gray-800 rounded-xl cursor-pointer min-w-max w-full">{children}</button> 
          </RouterLink>
      }
    </>
  )
}

export default NavBarLinkSwitchSpecial

// bg-transparent hover:bg-blue text-blue-dark font-semibold hover:text-white py-2 px-4 border border-blue hover:border-transparent rounded