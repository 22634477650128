// Level 1 - watch page
export const guestPasswordsL1 = [
    "norrisanderin1"
];

// Level 2 - home page & watch page
export const guestPasswordsL2 = [
    "erinandnorris2"
];

// Level 2 - home page & watch page(admin)
export const guestPasswordsL3 = [
    "Norrin1030"
];